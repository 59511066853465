import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { toString, map, get } from 'lodash';
import type { MutationStatus } from '@tanstack/react-query';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import useAPIUrl from 'api';
import { namespaces } from 'i18n/i18n.constants';
import { useAxios } from 'providers/AxiosProvider';
import { useToasts } from 'providers/ToastProvider';
import useUploadFileAttachment from 'api/attachments/useUploadFileAttachment';
import type { UpdateMutationContext } from 'api/helpers';
import {
  doPromiseAPI,
  onErrorUpdate,
  onMutateUpdate,
  onSuccessMutation,
} from 'api/helpers';
import type { Note, UpdateNotebookFileBody } from 'models/Notebook';
import { ToastType } from 'models/Toast';
import type { RootState } from 'store/reducers';
import { findNotesQueryKey } from './useFindNotes';

interface UseAddNotebookProps {
  miLoc: string;
  id: string;
  ctlNo: string;
  nbType?: string;
}

interface UseAddNotebookResponse {
  data?: Note;
  status: MutationStatus;
  onAddNotebook: (body: UpdateNotebookFileBody) => void;
}

const useAddNotebook = ({
  miLoc,
  id,
  ctlNo,
  nbType = 'CM',
}: UseAddNotebookProps): UseAddNotebookResponse => {
  const { axios } = useAxios();
  const { notebooksAPI } = useAPIUrl();
  const queryClient = useQueryClient();
  const { addToast } = useToasts();
  const { t } = useTranslation(namespaces.notes);
  const { userInfo } = useSelector((state: RootState) => state.user);
  const userId = get(userInfo, 'userid');
  const userFullName = get(userInfo, 'cn');

  const { uploadAttachment } = useUploadFileAttachment({
    domain: 'customer',
    miLoc,
    ctlNo: id,
  });

  const doAddNotebook = (body: UpdateNotebookFileBody) => {
    return doPromiseAPI<Note>(async (cancelToken) => {
      const { data } = await axios.post<Note>(
        notebooksAPI(nbType, ctlNo),
        body,
        { cancelToken }
      );
      return data;
    });
  };

  const response = useMutation(doAddNotebook, {
    onMutate: async (vars) =>
      onMutateUpdate<Note>({
        queryClient,
        queryKey: findNotesQueryKey,
        queryKeyParams: { miLoc, id },
        newItems: [
          {
            miLoc,
            customerNo: id,
            text: vars.text,
            itemLineNo: Date.now(),
            updatedTimestamp: new Date().toISOString(),
            updatedUserid: userId,
            updatedUserName: userFullName,
          },
        ],
        isInfiniteQuery: true,
      }),
    onSuccess: async (data, vars) => {
      await onSuccessMutation(queryClient, findNotesQueryKey, {
        miLoc,
        id,
      });
      const { itemLineNo } = data;
      map(vars.filesForUpload, (fileForUpload) => {
        uploadAttachment({
          file: fileForUpload,
          lineNo: toString(itemLineNo),
        });
      });
      addToast({
        text: t('addToast', { title: t('newNotebook') }),
        testid: 'add-notebook-toast',
      });
    },
    onError: (data, vars, context) => {
      addToast({
        type: ToastType.error,
        text: t('addFailureToast'),
        testid: 'add-notebook-error-toast',
      });
      onErrorUpdate<Note>({
        queryClient,
        context: context as UpdateMutationContext<Note>[],
        isInfiniteQuery: true,
      });
    },
  });

  const { data, status, mutate } = response;

  return {
    data,
    status,
    onAddNotebook: (body: UpdateNotebookFileBody) => mutate(body),
  };
};

export default useAddNotebook;
