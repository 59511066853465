import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams, useRouteMatch } from 'react-router-dom';
import { includes, isNil, size, toString } from 'lodash';
import { or, choose } from 'common/utils/logicHelpers';
import { useGetAvpUser } from 'common/utils/userInfo';
import { customerActionRouteURL } from 'navigation';
import { goToSalesPerformanceReport } from 'ReportsApp/navigation/navigationHelpers';
import useAccessControls, { AccessControlType } from 'hooks/useAccessControls';
import type { SnapshotActionRoute } from 'models/Navigation';
import type { SnapshotsURLParams } from 'models/Search';
import type { RootState } from 'store/reducers';
import { concatRoutes } from 'utils/navigations';
import ActionRow from 'components/ActionRow/ActionRow';

interface CustomerMenuOptionProps extends SnapshotActionRoute {
  isCorpAccount?: boolean;
  natlAcctNo?: string;
}

const CustomerMenuOption = ({
  action,
  translate,
  component,
  routes,
  disabled,
  isCorpAccount,
  accessControl,
  natlAcctNo,
}: CustomerMenuOptionProps) => {
  const { isCamUser } = useSelector((state: RootState) => state.user);

  const { isAvpUserWithExecView } = useGetAvpUser();

  const { t } = useTranslation();
  const { url } = useRouteMatch();
  const { miLoc, id } = useParams<SnapshotsURLParams>();

  const reportsAction = includes(['reports', 'corp-reports'], action);
  let accessControlParam = accessControl;
  if (reportsAction) {
    accessControlParam = AccessControlType.viewReports;
    if (or(isCamUser, isAvpUserWithExecView)) {
      accessControlParam = AccessControlType.camUserReports;
    }
  }
  const acMenuOption = useAccessControls(accessControlParam);

  const reportsHref = goToSalesPerformanceReport({
    baseUrl: `${url}/reports`,
    orgType: choose(isCorpAccount, 'NATLACCT', 'CUST'),
    miLoc,
    rowId: choose(isCorpAccount, `${toString(natlAcctNo)}-${miLoc}`, id),
  });

  if (!acMenuOption) {
    return null;
  }

  return (
    <ActionRow
      key={action}
      text={t(translate)}
      href={choose(
        reportsAction,
        reportsHref,
        concatRoutes(url, customerActionRouteURL(action))
      )}
      testid={`${action}-details`}
      disabled={!isNil(disabled) ? disabled : !component && size(routes) === 0}
    />
  );
};

export default CustomerMenuOption;
