import type React from 'react';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { map, type Dictionary, includes, split } from 'lodash';
import { or } from 'common/utils/logicHelpers';
import { useGetAvpUser } from 'common/utils/userInfo';
import type { SalesReportRow } from 'ReportsApp/api/useGetSalesDashboard';
import { useGetTableColumns } from 'ReportsApp/hooks/useGetTableColumns';
import type { ReportField } from 'ReportsApp/models';
import getPick12TableRowMetaData from 'ReportsApp/pages/Pick12Report/pick12ReportHelpers';
import type { SalesPerformanceURLParams } from 'ReportsApp/pages/SalesPerformance/SalesPerformance';
import getSalesTableRowMetaData from 'ReportsApp/pages/SalesPerformance/salesReportHelpers';
import type { RootState } from 'store/reducers';
import type { FilterOption } from 'components/Filter/Filter';

interface UseGetSalesTableDataProps {
  reportType: 'sales' | 'pick12' | 'locationPick12';
  pick12?: boolean;
  namespace: string;
  drilldownData: SalesReportRow[];
  summaryData?: SalesReportRow;
  reportFields: ReportField[];
  selectedGroupBy: FilterOption;
  setCanChangeTab: React.Dispatch<React.SetStateAction<boolean>>;
  setGroupByData: React.Dispatch<
    React.SetStateAction<Dictionary<FilterOption | undefined> | undefined>
  >;
}

export interface ReportRowMetaData {
  title: string;
  href?: string;
  customerLink?: boolean;
}

// eslint-disable-next-line import/prefer-default-export
export const useGetReportTableData = ({
  drilldownData,
  summaryData,
  reportFields,
  selectedGroupBy,
  setCanChangeTab,
  setGroupByData,
  reportType,
  namespace,
}: UseGetSalesTableDataProps) => {
  const { isCamUser } = useSelector((state: RootState) => state.user);
  const { isAvpUserWithExecView } = useGetAvpUser();

  const {
    tab = '',
    orgType = '',
    rowId: routeRowId = '',
  } = useParams<SalesPerformanceURLParams>();

  const [rowId] = split(routeRowId, '-');
  const groupByDataKey = selectedGroupBy.key;

  const getTableData = (row: SalesReportRow, index: number) => {
    const rowName = row.miLocName
      ? `${row.miLoc}: ${row.miLocName}`
      : row.miLoc;

    let metadata = {} as ReportRowMetaData;
    switch (reportType) {
      case 'sales':
        metadata = getSalesTableRowMetaData({
          groupByDataKey,
          row,
          rowName,
          routeRowId,
          rowId,
          tab,
          orgType,
          isCamUser,
          isAvpUserWithExecView,
        }) as ReportRowMetaData;
        break;
      case 'pick12':
      case 'locationPick12':
        metadata = getPick12TableRowMetaData({
          groupByDataKey,
          row,
          rowName,
          routeRowId,
          rowId,
          pick12: reportType === 'pick12',
          orgType,
        });
        break;

      default:
    }

    const { title, href: propHref, customerLink } = metadata;
    let href = propHref;
    if (row.disabled) {
      href = undefined;
    }

    const hideArrow = or(!href, !!customerLink, row.disabled);

    const onClick = () => {
      if (!href) {
        return;
      }
      // Changing tabs doesn't work after clicking on a customer name if canChangeTab is false
      // DOC: ionic fires tab change on route change, we need a flag to stop it
      // TUDU: remove or replace canChangeTab logic
      setCanChangeTab(includes(['CUST'], groupByDataKey));
      setGroupByData((prev) => {
        const newGroupBy = { ...prev };
        delete newGroupBy?.[groupByDataKey];
        return newGroupBy;
      });
    };

    return {
      data: {
        ...row,
        title,
        href,
        customerLink,
        onClick,
        hideArrow,
        testid: `sales-${title}-${index}`,
      },
    };
  };

  const tableData = useMemo(
    () => map(drilldownData, getTableData),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      drilldownData,
      groupByDataKey,
      routeRowId,
      rowId,
      isCamUser,
      isAvpUserWithExecView,
      orgType,
      tab,
    ]
  );

  const { tableColumns, totalsRow } = useGetTableColumns({
    groupByDataKey,
    reportFields,
    selectedGroupBy,
    namespace,
    summaryData,
  });

  return { tableData, tableColumns, totalsRow };
};
