import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { toNumber, toString } from 'lodash';
import { IonRow } from '@ionic/react';
import {
  SalesOpportunityEnum,
  type Task,
} from 'ActivitiesApp/models/SalesOpportunity';
import { goToActivity } from 'ActivitiesApp/navigation/navigationHelpers';
import { isNonBlankEstimatedCloseDate } from 'ActivitiesApp/utils/helpers';
import CorpAccIcon from 'common/components/CorpAccIcon/CorpAccIcon';
import type { BaseComponentProps } from 'common/components/utils/renderHelpers';
import { choose } from 'common/utils/logicHelpers';
import { scaleNumber } from 'common/utils/numberHelper';
import ListItem from 'InventoryApp/components/ListItem/ListItem';
import type { ActionCardActivity } from 'models/ActivityModels';
import { formatCardDate } from 'utils/date';
import { withStringProp } from 'utils/helpers';
import Pick12Icon from 'components/Pick12Icon/Pick12Icon';
import Text from 'components/Text/Text';
import classes from './RelatedOpportunity.module.scss';

interface RelatedOpportunitiesProps extends BaseComponentProps {
  activity?: ActionCardActivity;
}

const RelatedOpportunity = ({
  activity,
  testid,
}: RelatedOpportunitiesProps) => {
  const task = activity?.extendedInfo?.task as Task;
  const { t } = useTranslation('ActivitiesApp-RelatedOpportunity');

  const {
    customerName,
    natlAcctNo,
    customerPick12: isPick12,
  } = activity as ActionCardActivity;

  const isCorpAccount = withStringProp(natlAcctNo);

  const {
    estimatedCloseDate,
    estimatedValue,
    currency,
    miLoc,
    customerNo: customerId,
    historyId,
    creationUserId: userId,
  } = task;

  const estValue = scaleNumber({
    number: toNumber(estimatedValue),
    currencyType: currency,
    scale: 0,
  });

  return (
    <div className={classes.card} data-testid={testid}>
      <Text
        text={t('related')}
        testid="related-header"
        variant="title-screen-section"
      />
      <ListItem
        testid="related-item"
        title={{
          text: t('salesOpportunity'),
          className: classes.title,
          textVariant: 'title-info-card-activity',
        }}
        href={goToActivity({
          miLoc,
          customerId,
          historyId,
          userId,
          activityType: SalesOpportunityEnum.salesOpportunity,
        })}
        className={classes.listWrapper}
        planIcon={{
          icon: ['far', 'hand-holding-dollar'],
          color: 'var(--color-crmOpportunity)',
        }}
        footer={
          <>
            <IonRow className={classes.nameBlock}>
              <CorpAccIcon showIcon={isCorpAccount} testid={testid} />
              {isPick12 && <Pick12Icon />}
              <Text
                text={customerName}
                variant="content-smaller"
                className={classes.name}
              />
            </IonRow>
            <IonRow className={classes.flex}>
              <span>
                <Trans
                  i18nKey={toString(
                    choose(
                      isNonBlankEstimatedCloseDate(estimatedCloseDate),
                      t('estimatedCloseDate', {
                        estimatedCloseDate: formatCardDate(
                          estimatedCloseDate,
                          false,
                          false
                        ),
                      }),
                      ''
                    )
                  )}
                />
              </span>
              <span>
                <Trans
                  i18nKey={t('estimatedValue', {
                    estValue,
                  })}
                />
              </span>
            </IonRow>
          </>
        }
      />
    </div>
  );
};

export default RelatedOpportunity;
