import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { find, get, head, toNumber } from 'lodash';
import { choose, or } from 'common/utils/logicHelpers';
import { useReportsConfig } from 'providers/ReportsProvider';
import type { RootState } from 'store/reducers';
import type { FilterOption } from 'components/Filter/Filter';

interface ReportFilterHelperProps {
  reportKey: string;
  isContractFilter: boolean;
}

const useReportFilterData = ({
  reportKey,
  isContractFilter,
}: ReportFilterHelperProps) => {
  const { t } = useTranslation();
  const { userInfo } = useSelector((state: RootState) => state.user);
  const loggedInUserId = get(userInfo, 'userid', '');

  const custTypeOptions = {
    title: t('reports:custAccountType'),
    options: [
      { key: 'ALL', name: t('reports:all') },
      { key: 'CORP', name: t('reports:corpAccounts') },
      { key: 'NON_CORP', name: t('reports:nonCorpAccounts') },
    ],
    hidden: false,
  };

  const contractTypeOptions = {
    title: t('reports:contract'),
    options: [
      { key: 'ALL', name: t('reports:both') },
      { key: 'YES', name: t('reports:yes') },
      { key: 'NO', name: t('reports:no') },
    ],
    hidden: false,
  };

  const { otherFilters, updateOtherFilters } = useReportsConfig({
    key: reportKey,
  });

  const custTypeFilterOption = useMemo(
    () => {
      let selectedCustAcctType = find(custTypeOptions.options, {
        key: otherFilters?.custAcctTypesToShow?.key,
      });
      selectedCustAcctType ||= head(custTypeOptions.options);

      return selectedCustAcctType;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [otherFilters?.custAcctTypesToShow?.key, isContractFilter, loggedInUserId]
  ) as FilterOption;

  const contractTypeFilterOption = useMemo(() => {
    let selectedContractType = find(contractTypeOptions.options, {
      key: otherFilters?.contractTypesToShow?.key,
    });
    selectedContractType ||= head(contractTypeOptions.options);

    return selectedContractType;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    otherFilters?.contractTypesToShow?.key,
    isContractFilter,
    loggedInUserId,
  ]) as FilterOption;

  const defaultSelectedCustTypeData = isContractFilter
    ? contractTypeOptions.options[0]
    : custTypeOptions.options[0];

  const selectedCustTypeData = or(
    custTypeFilterOption,
    custTypeOptions.options[0]
  ) as FilterOption;

  const selectedContractTypeData = or(
    contractTypeFilterOption,
    contractTypeOptions.options[0]
  ) as FilterOption;

  const defaultFilter = isContractFilter
    ? selectedContractTypeData.key === contractTypeOptions.options[0].key
    : selectedCustTypeData.key === custTypeOptions.options[0].key;

  const custAcctTypesToShowMerged = custTypeFilterOption?.key;
  const contractFlag = contractTypeFilterOption?.key;

  const custTypeCount = choose(
    defaultSelectedCustTypeData?.key !== selectedCustTypeData.key,
    1,
    0
  );

  const contractTypeCount = choose(
    defaultSelectedCustTypeData?.key !== selectedContractTypeData.key,
    1,
    0
  );

  const filterCount = isContractFilter
    ? toNumber(contractTypeCount)
    : toNumber(custTypeCount);

  return {
    custTypeOptions,
    contractTypeOptions,
    selectedTypeData: isContractFilter
      ? selectedContractTypeData
      : selectedCustTypeData,
    defaultFilter,
    custAcctTypesToShow: custAcctTypesToShowMerged,
    contractFlag,
    custTypeCount,
    contractTypeCount,
    filterCount,
    updateOtherFilters,
  };
};

export default useReportFilterData;
