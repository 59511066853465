import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import classNames from 'classnames';
import { isEmpty, toString, some, filter, size, isNil, toLower } from 'lodash';
import { IonContent, IonPage, useIonViewWillEnter } from '@ionic/react';
import { useQueryClient } from '@tanstack/react-query';
import Footer from 'common/components/Footer/Footer';
import CustomerName from 'common/components/Header/CustomerName';
import Header from 'common/components/Header/Header';
import { and, choose, ifRender, or } from 'common/utils/logicHelpers';
import type { FormikErrors } from 'formik';
import { Formik } from 'formik';
import { searchCustomerURL, searchURL } from 'navigation';
import useUpdateHeader from 'ProductSearchApp/api/checkout/useUpdateHeader';
import { findOrderNotesQueryKey } from 'ProductSearchApp/api/orders/useFindOrderNotes';
import useGetOrder from 'ProductSearchApp/api/useGetOrder';
import useSubmitOCN from 'ProductSearchApp/api/useSubmitOCN';
import CheckoutForm from 'ProductSearchApp/components/CheckoutForm/CheckoutForm';
import { OcnBadge } from 'ProductSearchApp/components/OcnBadge/OcnBadge';
import OrderAttachments from 'ProductSearchApp/components/OrderAttachments/OrderAttachments';
import OrderNotes from 'ProductSearchApp/components/OrderNotes/OrderNotes';
import OcnHeader from 'ProductSearchApp/components/ReviewQuotes/OcnHeader';
import OcnLinesReview from 'ProductSearchApp/components/ReviewQuotes/OcnLinesReview';
import { OcnTypeEnum } from 'ProductSearchApp/models/Order';
import type {
  CheckoutOrderBaseForm,
  CheckoutOrderForm,
} from 'ProductSearchApp/models/Order';
import type {
  OrderURLParams,
  SubmitOCNBody,
} from 'ProductSearchApp/models/Products';
import {
  canEditOCN,
  getOcnType,
  hasKoyoMfrItems,
  hasPriceOverride,
  isClosedOrder,
  isGovAcc,
  isUnfinishedOrder,
  mergeEmailRecipients,
} from 'ProductSearchApp/util/ocnHelpers';
import { isCustomerLess } from 'ProductSearchApp/util/productSearchUtil';
import { useToasts } from 'providers/ToastProvider';
import { findAttachmentsQueryKey } from 'api/attachments/useFindAttachments';
import useGetCustomer from 'api/customer/useGetCustomer';
import useAccessControls, { AccessControlType } from 'hooks/useAccessControls';
import useGoBack from 'hooks/useGoBack';
import useShowCostDetailsDispatcher from 'hooks/useToggleCostDetailsDispatcher';
import type { RootState } from 'store/reducers';
import { setCurrentCartCustomer } from 'store/user';
import { getErrorMessage } from 'utils/helpers';
import { concatRoutes } from 'utils/navigations';
import { removeLeadingZeros } from 'utils/number';
import {
  goToOrderCart,
  goToReviewCart,
  goToShareGovernmentKoyoOcn,
  goToTransmissionLog,
} from 'navigation/navigationHelpers';
import Button, { ButtonVariantEnum } from 'components/Button/Button';
import Loader from 'components/Loader/Loader';
import DiscardModal from 'components/Modals/DiscardModal/DiscardModal';
import SheetModal from 'components/Modals/SheetModal/SheetModal';
import Refresher from 'components/Refresher/Refresher';
import Text from 'components/Text/Text';
import WarningMessage, {
  WarningMessageVarianEnum,
} from 'components/WarningMessage/WarningMessage';
import { CheckoutOrderSchema } from './CheckoutOrderForm';
import classes from './ReviewOrder.module.scss';

interface SwitchOrderToastProps {
  ocn: string;
  values: CheckoutOrderForm;
  sameCart?: boolean;
  nextRoute: string;
  orderStayedUM?: boolean;
}

interface ReviewOrderProps {
  ocnType: OcnTypeEnum;
  ocnMode: 'review' | 'checkout';
}

const ReviewOrder = ({
  ocnType: routeOcnType,
  ocnMode,
}: ReviewOrderProps): JSX.Element => {
  const {
    miLoc,
    id: shipToCustNo,
    ocn: orderCtlNo,
  } = useParams<OrderURLParams>();
  const history = useHistory();
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const resetView = params.get('reset');
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const { toggleCostDetailsOption } = useShowCostDetailsDispatcher();
  const { currentCartCustomer } = useSelector((state: RootState) => state.user);
  const canCreateEditOrder = useAccessControls(
    AccessControlType.EditOrdersAccessControls
  );

  const [isKoyo, setIsKoyo] = useState(true);
  const [isGovOrKoyoModalOpen, setIsGovOrKoyoModalOpen] = useState(false);
  const [isOpenDiscardModal, setIsOpenDiscardModal] = useState(false);
  const [abandonCartModalIsOpen, setAbandonCartModalIsOpen] = useState(false);
  const [cartModalIsOpen, setCartModalIsOpen] = useState(false);
  const [isGoToReviewCart, setIsGoToReviewCart] = useState(false);
  const [isReviewAfterCheckout, setIsReviewAfterCheckout] = useState(false);
  const [stayedUM, setStayedUM] = useState(false);
  const [fetchingOrder, setFetchingOrder] = useState(false);
  const [urlAfterUpdate, setUrlAfterUpdate] = useState('');
  const summaryMode = ocnMode === 'review';

  const {
    data: customerData,
    isLoading: customerIsLoading,
    error: customerError,
  } = useGetCustomer({ searchType: 'customer', miLoc, id: shipToCustNo });

  const cartNameSpace = 'productSearch:ocn';
  const reviewNameSpace = 'productSearch:review';

  const { t } = useTranslation();

  useIonViewWillEnter(() => {
    if (resetView && ocnMode === 'checkout') {
      setIsReviewAfterCheckout(false);
      history.replace(
        goToReviewCart({
          miLoc,
          shipToCustNo,
          orderCtlNo,
          ocnType: routeOcnType,
          ocnMode: 'checkout',
        })
      );
    } else if (ocnMode === 'review') {
      setIsReviewAfterCheckout(true);
    }
  }, [ocnMode, resetView, orderCtlNo]);

  const {
    order,
    error: orderError,
    isLoading: orderLoading,
    orderChangedToOP,
    refetch,
  } = useGetOrder({ miLoc, orderCtlNo, refetchOnEnter: true });

  let ocnType = choose(
    routeOcnType === OcnTypeEnum.closed,
    OcnTypeEnum.order,
    routeOcnType
  ) as OcnTypeEnum;

  if (and(summaryMode, !orderLoading)) {
    ocnType = choose(
      isClosedOrder(order),
      OcnTypeEnum.order,
      getOcnType(order)
    ) as OcnTypeEnum;
  }
  const isOrder = ocnType === 'order';
  const isUnfinished = ocnType === 'unfinished';
  const error = or(customerError, orderError);
  let pageTitle = choose(
    !isReviewAfterCheckout,
    t(`${cartNameSpace}:${ocnType}`),
    choose(
      ocnType === 'quote',
      t('productSearch:ocn:quote'),
      t('productSearch:ocn:order')
    )
  ) as string;
  let primaryButtonTestId = choose(
    !isReviewAfterCheckout,
    `${ocnType}-review-button`,
    `${ocnType}-submit-button`
  ) as string;
  let primaryButtonText = choose(
    isReviewAfterCheckout,
    choose(
      isOrder,
      t(`${reviewNameSpace}:submitOrder`),
      t(`${reviewNameSpace}:submitQuote`)
    ),
    choose(
      isOrder,
      t(`${reviewNameSpace}:reviewOrder`),
      t(`${reviewNameSpace}:reviewQuote`)
    )
  ) as string;
  let secondaryButtonTestId = choose(
    !isReviewAfterCheckout,
    `${ocnType}-cancel-button`,
    `${ocnType}-edit-ocn-button`
  ) as string;
  let secondaryButtonText = choose(
    isReviewAfterCheckout,
    t('common:edit'),
    t('common:cancel')
  ) as string;

  if (summaryMode) {
    pageTitle = t(`${cartNameSpace}:${ocnType}`);
    primaryButtonText = t(`${reviewNameSpace}:editOcn`);
    primaryButtonTestId = `${ocnType}-edit-ocn-button`;
    secondaryButtonText = t(`${reviewNameSpace}:convertToOrder`);
    secondaryButtonTestId = 'convert-order-button';
  }

  const { onUpdateHeader, status: updateHeaderStatus } = useUpdateHeader({
    miLoc,
    orderCtlNo,
    shipToCustNo,
    billToCustNo: toString(order?.billToCustNo),
  });

  const { status: saveAsQuoteStatus, onSubmitOCN } = useSubmitOCN(isOrder);

  useEffect(() => {
    if (saveAsQuoteStatus === 'success') {
      history.replace(
        concatRoutes(searchURL(), searchCustomerURL(miLoc, shipToCustNo))
      );
    }
  }, [saveAsQuoteStatus, history, miLoc, shipToCustNo]);

  const priceOverridePending = some(order?.items, (item) =>
    hasPriceOverride(item)
  );
  const priceOverridePendingCount = filter(order?.items, (item) =>
    hasPriceOverride(item)
  );
  const sendUnprocessed = or(order?.processStatus === 'UM', isOrder);
  const orderCustomerEmail = mergeEmailRecipients(order?.custContactEmail);

  let warningMessage = '';
  if (priceOverridePending) {
    const text = choose(
      size(priceOverridePendingCount) > 1,
      'priceOverridePendingForMultiple',
      'priceOverridePendingFor'
    ) as string;

    warningMessage = t(`${reviewNameSpace}:${text}`, {
      priceOverridePendingCount: size(priceOverridePendingCount),
    });
  }

  if (!priceOverridePending && order?.approvedAfterLastTransmission) {
    warningMessage = t(`${cartNameSpace}:approvedPriceOverrideNotEmailed`);
  }

  useEffect(() => {
    if (updateHeaderStatus === 'success') {
      if (urlAfterUpdate) {
        setUrlAfterUpdate('');
        history.push(urlAfterUpdate);
        return;
      }
      setIsReviewAfterCheckout(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, updateHeaderStatus]);

  const isUpdateHeaderLoading = updateHeaderStatus === 'loading';
  const isSaveAsQuoteLoading = saveAsQuoteStatus === 'loading';
  const { goBack } = useGoBack();

  const pageLoading = (customerIsLoading || orderLoading) && !order?.orderCtlNo;
  const isLoading =
    isUpdateHeaderLoading || isSaveAsQuoteLoading || orderLoading;
  const disableOrderStatus = !canEditOCN(order);
  const queryClient = useQueryClient();

  const switchCartToast = ({
    ocn,
    values,
    sameCart = false,
    nextRoute,
    orderStayedUM,
  }: SwitchOrderToastProps) => {
    if (!sameCart) {
      dispatch(
        setCurrentCartCustomer({
          currentCartCustomer: {
            customerName: customerData?.name,
            miLoc,
            shipToCustNo: toString(order?.shipToCustNo),
            billToCustNo: order?.billToCustNo,
            orderCtlNo: ocn,
          },
        })
      );
      addToast({
        variant: 'mipro-toast',
        text: t('productSearch:ocn:switchOcnSuccess', {
          ocn: removeLeadingZeros(ocn),
        }),
        testid: 'switch-cart-success-toast',
      });
    }
    if (orderStayedUM) {
      setStayedUM(false);
      setUrlAfterUpdate(nextRoute);
      onUpdateHeader({ ...values, processStatus: 'OP' });
    } else {
      history.push(nextRoute);
    }
  };

  const shouldShowAbandonCart =
    !isEmpty(currentCartCustomer) &&
    isCustomerLess(currentCartCustomer?.shipToCustNo);

  const sameCart =
    currentCartCustomer?.miLoc === miLoc &&
    currentCartCustomer?.orderCtlNo === orderCtlNo;

  const isGovAccOcn = isGovAcc(order);
  const isKoyoMfr = hasKoyoMfrItems(order);

  const handlePrimaryButtonClick = async (
    values: CheckoutOrderForm,
    handleSubmit: () => void,
    validateForm: () => Promise<FormikErrors<unknown>>,
    checkAccount = true
  ) => {
    if (summaryMode) {
      try {
        setFetchingOrder(true);
        const orderChange = await orderChangedToOP?.(order, false);
        setStayedUM(!!orderChange?.stayedUM);
        if (!orderChange?.changedToOP) {
          if (
            isEmpty(currentCartCustomer) ||
            (currentCartCustomer?.miLoc === miLoc &&
              currentCartCustomer?.orderCtlNo === orderCtlNo)
          ) {
            switchCartToast({
              ocn: orderCtlNo,
              values,
              orderStayedUM: !!orderChange?.stayedUM,
              sameCart,
              nextRoute: goToOrderCart(miLoc, shipToCustNo, orderCtlNo),
            });
          } else if (shouldShowAbandonCart) {
            setAbandonCartModalIsOpen(true);
          } else {
            setCartModalIsOpen(true);
          }
        }
      } catch (e) {
        // DOC: error handled in util
      }
      setFetchingOrder(false);
      return;
    }

    const isFormValid = await validateForm();

    if (and(checkAccount, isEmpty(isFormValid))) {
      setIsGovOrKoyoModalOpen(isGovAccOcn);
      setIsKoyo(isKoyoMfr);
      return;
    }

    handleSubmit();
  };

  const customBackButtonClick = async (
    values: CheckoutOrderForm,
    dirty: boolean,
    backButton = false
  ) => {
    if (summaryMode) {
      if (backButton) {
        goBack();
        return;
      }
      try {
        setFetchingOrder(true);
        const orderChange = await orderChangedToOP?.(order, false);
        setStayedUM(!!orderChange?.stayedUM);
        if (orderChange?.changedToOP) {
          return;
        }
        if (shouldShowAbandonCart) {
          setIsGoToReviewCart(true);
          setFetchingOrder(false);
          setAbandonCartModalIsOpen(true);
          return;
        }
        if (or(isEmpty(currentCartCustomer), sameCart)) {
          switchCartToast({
            ocn: orderCtlNo,
            values,
            orderStayedUM: !!orderChange?.stayedUM,
            sameCart,
            nextRoute: goToReviewCart({
              miLoc,
              shipToCustNo,
              orderCtlNo,
              ocnType: 'order',
              ocnMode: 'checkout',
              reset: true,
            }),
          });
        } else {
          setIsGoToReviewCart(true);
          setCartModalIsOpen(true);
        }
      } catch (e) {
        // DOC: error handled in util
      }
      setFetchingOrder(false);
      return;
    }
    if (and(dirty, !isReviewAfterCheckout)) {
      setIsOpenDiscardModal(true);
      return;
    }
    if (isReviewAfterCheckout) {
      setIsReviewAfterCheckout(false);
      return;
    }
    goBack();
  };

  const headerOptions: React.ComponentProps<typeof Button>[] = [];
  if (summaryMode) {
    headerOptions.push({
      text: t('productSearch:ocn:viewTransmissionLog'),
      href: goToTransmissionLog({
        miLoc,
        shipToCustNo,
        orderCtlNo,
        ocnType,
        ocnMode,
      }),
      testid: 'view-transmission-log-button',
    });
  }
  if (summaryMode || isReviewAfterCheckout) {
    headerOptions.push(toggleCostDetailsOption(false));
  }

  const ocnNotesLoading = !!queryClient.isFetching([findOrderNotesQueryKey]);
  const ocnAttachmentLoading = !!queryClient.isFetching([
    findAttachmentsQueryKey,
  ]);

  return (
    <Formik<CheckoutOrderBaseForm>
      initialValues={{
        emailCustomer: false,
        typedRecipients: '',
        emailMsg: '',
        includeAttachments: false,
        emailAckType: 'CO',
      }}
      key={`review-${miLoc}-${shipToCustNo}-${orderCtlNo}`}
      enableReinitialize
      onSubmit={() => {}}
    >
      {({ values: baseValues, setValues: baseSetValues }) => (
        <Formik<CheckoutOrderForm>
          initialValues={{
            customerContact: {
              key: toString(order?.custContactName),
              title: toString(order?.custContactName),
            },
            contactName: order?.custContactName,
            contactNumber: order?.custContactPhone,
            contactEmail: orderCustomerEmail,
            excludeFromWeb: order?.excludeFromWeb,
            poNumber: order?.custPoNo,
            custReleaseNo: order?.custReleaseNo,
            dueDate: order?.dueDate,
            shipComplete: order?.shipComplete,
            shipChargesCd: {
              codeValue: toString(order?.shipChargesCd),
              codeDesc: toString(order?.shipChargesDesc),
            },
            carrierCd: {
              codeValue: toString(order?.carrierCd),
              codeDesc: toString(order?.carrierDesc),
            },
            shipMethodCd: {
              codeValue: toString(order?.shipMethodCd),
              codeDesc: toString(order?.shipMethodDesc),
            },
            carrierAccount: order?.carrierAccount,
            handlingInst: order?.handlingInst,
            orderSourceDesc: order?.orderSourceDesc,
            priceOverridePending,
            sendUnprocessed,
            ...baseValues,
            typedRecipients: or(baseValues.typedRecipients, orderCustomerEmail),
          }}
          enableReinitialize
          validationSchema={CheckoutOrderSchema(isOrder)}
          onSubmit={(values) => {
            baseSetValues(values);
            if (!isReviewAfterCheckout) {
              onUpdateHeader({ ...values });
              return;
            }
            const saveAsQuoteOrOrder: SubmitOCNBody = {
              miLoc,
              orderCtlNo,
              shipToCustNo,
              storeroom: toString(order?.storeroomNo),
              emailToCustomer: or(values.emailCustomer, false),
              emailAddress: choose(
                values.emailCustomer,
                toString(values.typedRecipients),
                ''
              ) as string,
              emailSubject: toString(values.emailMsg),
              includeAttachments: or(values.includeAttachments, false),
              sendUnprocessed: or(values.sendUnprocessed, false),
            };
            if (isOrder) {
              saveAsQuoteOrOrder.emailAckType = values.emailAckType;
            }
            onSubmitOCN(saveAsQuoteOrOrder);
          }}
        >
          {({ dirty, values, handleSubmit, validateForm }) => {
            const disableButtons = or(
              isLoading,
              disableOrderStatus,
              fetchingOrder,
              !isNil(error)
            );
            const footerButtons = [
              {
                testid: primaryButtonTestId,
                text: primaryButtonText,
                variant: ButtonVariantEnum['mipro-action'],
                disabled: !!disableButtons,
                onClick: () =>
                  handlePrimaryButtonClick(
                    values,
                    handleSubmit,
                    validateForm,
                    and(!isReviewAfterCheckout, or(isGovAccOcn, isKoyoMfr))
                  ),
              },
            ];

            if (or(!summaryMode, and(summaryMode, !isOrder, !isUnfinished))) {
              footerButtons.unshift({
                testid: secondaryButtonTestId,
                text: secondaryButtonText,
                variant: ButtonVariantEnum.secondary,
                disabled: !!disableButtons,
                onClick: () => customBackButtonClick(values, dirty),
              });
            }

            return (
              <IonPage
                className={classes.reviewOrderPage}
                data-testid="review-order-page"
              >
                <Header
                  testid="quote-cart-header"
                  hideMenuButton
                  title={`${pageTitle}: ${removeLeadingZeros(orderCtlNo)}`}
                  subTitle={customerData?.name}
                  headerActions={{
                    title: t('productSearch:ocn:manageOcn'),
                    initialBreakpoint: 0.3,
                    disabled: false,
                    options: headerOptions,
                  }}
                  backButton={{
                    onClick: () => customBackButtonClick(values, dirty, true),
                  }}
                />
                <IonContent className={classes.content}>
                  <Refresher
                    slot="fixed"
                    onRefresh={async () => {
                      await Promise.all([
                        queryClient.invalidateQueries([findOrderNotesQueryKey]),
                        queryClient.invalidateQueries([
                          findAttachmentsQueryKey,
                        ]),
                        refetch?.(),
                      ]);
                    }}
                    disabled={
                      orderLoading ||
                      !summaryMode ||
                      ocnNotesLoading ||
                      ocnAttachmentLoading
                    }
                    hidden
                    testid="review-order-refresher"
                  />
                  <Header
                    collapse="condense"
                    customTitle={
                      <>
                        <div
                          className={classNames(
                            classes.flex,
                            classes.headerRow
                          )}
                        >
                          <Text
                            text={`${pageTitle}: ${removeLeadingZeros(
                              orderCtlNo
                            )}`}
                            variant="mipro-h1-headline"
                            testid="shopping-cart-cust-title"
                          />
                          {order?.processStatus && (
                            <div className={classes.badgeWrapper}>
                              <OcnBadge
                                className={classes.badge}
                                statusId={order?.processStatus}
                              />
                            </div>
                          )}
                        </div>
                        <CustomerName customerData={customerData} />
                        {summaryMode && !isEmpty(order?.creationUsername) && (
                          <Text
                            text={t(
                              `productSearch:orderList:OCNListSecondary`,
                              {
                                username: order?.creationUsername,
                              }
                            )}
                            className={classNames(
                              classes.flex,
                              classes.headerRow,
                              classes.createdBy
                            )}
                            variant="mipro-h6-headline"
                          />
                        )}
                      </>
                    }
                    testid="quote-cart-header"
                  />
                  {!isEmpty(warningMessage) &&
                    isReviewAfterCheckout &&
                    !orderLoading &&
                    !isUnfinishedOrder(order?.orderTypeCd) &&
                    !isClosedOrder(order) && (
                      <WarningMessage
                        className={classes.importantNotices}
                        title={t(`${reviewNameSpace}:importantNotices`)}
                        variant={WarningMessageVarianEnum.warning}
                        hideIcon
                        body={warningMessage}
                      />
                    )}
                  {ifRender(
                    pageLoading,
                    <Loader
                      className={classes.loader}
                      text={t('common:loading')}
                      isOpen={pageLoading}
                    />
                  )}
                  {ifRender(
                    and(!pageLoading, !isNil(error)),
                    <WarningMessage
                      className={classes.warningMessage}
                      title={t(`${cartNameSpace}:errorOcn`, {
                        ocn: removeLeadingZeros(orderCtlNo),
                      })}
                      body={getErrorMessage(error)}
                      testid="error-holder"
                    />
                  )}
                  {ifRender(
                    and(!pageLoading, isNil(error)),
                    <>
                      {!isReviewAfterCheckout && !summaryMode && (
                        <CheckoutForm
                          order={order}
                          miLoc={miLoc}
                          customerNo={shipToCustNo}
                          disabled={isUpdateHeaderLoading}
                          ocnType={ocnType}
                          ocnMode={ocnMode}
                          ocn={orderCtlNo}
                        />
                      )}
                      {isReviewAfterCheckout && (
                        <div className={classes.reviewWrapper}>
                          <OcnHeader
                            summaryMode={summaryMode}
                            ocnType={ocnType}
                            order={order}
                          />
                          <OcnLinesReview order={order} />
                          <OrderNotes
                            customerNo={shipToCustNo}
                            ocnType={ocnType}
                            ocnMode={ocnMode}
                            miLoc={miLoc}
                            orderCtlNo={orderCtlNo}
                            viewMode
                          />
                          <OrderAttachments
                            miLoc={miLoc}
                            orderCtlNo={orderCtlNo}
                            summaryMode={summaryMode}
                            viewMode
                          />
                        </div>
                      )}
                    </>
                  )}
                  <DiscardModal
                    isOpen={isOpenDiscardModal}
                    setIsOpen={setIsOpenDiscardModal}
                    title={t('notes:cancelNotesTitle')}
                    discardMsg={t('common:discardMsg')}
                    testid="edit--order--note--discard--modal"
                    discardButtonTitle={t('notes:cancelNotesYes')}
                    goBackButtonTitle={t('notes:cancelNotesNo')}
                    initialBreakpoint={0.4}
                    withRightCloseButton
                    onDiscardClick={() => goBack()}
                  />
                  <SheetModal
                    isOpen={isGovOrKoyoModalOpen}
                    setIsOpen={setIsGovOrKoyoModalOpen}
                    testid="government-account-modal"
                    title={choose(
                      isKoyo,
                      t('ProductSearchApp-Search:koyoRequirement'),
                      t('ProductSearchApp-Search:governmentAccount')
                    )}
                    className={classes.govAccModal}
                    withRightCloseButton
                  >
                    <div className={classes.modalContent}>
                      <Text
                        className={classes.modalText}
                        variant="mipro-body-copy"
                        text={`${
                          choose(
                            isKoyo,
                            t('ProductSearchApp-Search:koyoMsg'),
                            t(
                              'ProductSearchApp-Search:governmentAccountMessageBefore',
                              {
                                type: toLower(pageTitle),
                              }
                            )
                          ) as string
                        } `}
                      />
                      <Button
                        className={classes.modalTextButton}
                        variant="link"
                        text="govsales@motion.com"
                        onClick={() => {
                          setIsGovOrKoyoModalOpen(false);
                          history.push(
                            goToShareGovernmentKoyoOcn({
                              miLoc,
                              shipToCustNo,
                              orderCtlNo,
                              ocnType,
                              ocnMode,
                              koyo: isKoyo,
                            })
                          );
                        }}
                        testid="government-account-modal-email"
                      />
                      {ifRender(
                        !isKoyo,
                        <Text
                          className={classes.modalText}
                          variant="mipro-body-copy"
                          text={` ${t(
                            'ProductSearchApp-Search:governmentAccountMessageAfter'
                          )}`}
                        />
                      )}
                    </div>
                    <Button
                      className={classes.modalButton}
                      variant="action"
                      text={t('ProductSearchApp-Search:acknowledge')}
                      onClick={() => {
                        setIsGovOrKoyoModalOpen(false);
                        void handlePrimaryButtonClick(
                          values,
                          handleSubmit,
                          validateForm,
                          false
                        );
                      }}
                      testid="gov-primary-button"
                    />
                    <Button
                      className={classes.modalButton}
                      variant="secondary"
                      text={t('common:cancel')}
                      onClick={() => setIsGovOrKoyoModalOpen(false)}
                      testid="gov-secondary-button"
                    />
                  </SheetModal>
                  {order && summaryMode && (
                    <>
                      <DiscardModal
                        title={t('productSearch:ocn:switchOcnTitle')}
                        discardMsg={t('productSearch:ocn:switchOcnBody', {
                          customerName: currentCartCustomer?.customerName,
                          ocn: removeLeadingZeros(
                            currentCartCustomer?.orderCtlNo
                          ),
                        })}
                        isOpen={cartModalIsOpen}
                        setIsOpen={setCartModalIsOpen}
                        initialBreakpoint={0.4}
                        backdropDismiss={false}
                        withRightCloseButton
                        discardButtonTitle={t('common:cancel')}
                        goBackButtonTitle={t('productSearch:ocn:switchOcnYes')}
                        onGoBackClick={() => {
                          switchCartToast({
                            ocn: toString(order?.orderCtlNo),
                            values,
                            orderStayedUM: stayedUM,
                            sameCart,
                            nextRoute: choose(
                              isGoToReviewCart,
                              goToReviewCart({
                                miLoc,
                                shipToCustNo,
                                orderCtlNo,
                                ocnType: 'order',
                                ocnMode: 'checkout',
                                reset: true,
                              }),
                              goToOrderCart(
                                miLoc,
                                shipToCustNo,
                                toString(order?.orderCtlNo)
                              )
                            ) as string,
                          });
                          setIsGoToReviewCart(false);
                        }}
                        testid="switch-cart-modal"
                      />
                      <DiscardModal
                        title={t('ProductSearchApp-Search:abandonOcn')}
                        discardMsg={t(
                          'ProductSearchApp-Search:abandonOcnMessage',
                          {
                            ocn: removeLeadingZeros(
                              currentCartCustomer?.orderCtlNo
                            ),
                          }
                        )}
                        initialBreakpoint={0.35}
                        withRightCloseButton
                        setIsOpen={setAbandonCartModalIsOpen}
                        isOpen={abandonCartModalIsOpen}
                        testid="abandon-ocn-modal"
                        discardButtonTitle={t('cancel')}
                        goBackButtonTitle={t(
                          'ProductSearchApp-Search:abandonOcn'
                        )}
                        onGoBackClick={() => {
                          switchCartToast({
                            ocn: toString(order?.orderCtlNo),
                            values,
                            orderStayedUM: stayedUM,
                            sameCart,
                            nextRoute: choose(
                              isGoToReviewCart,
                              goToReviewCart({
                                miLoc,
                                shipToCustNo,
                                orderCtlNo,
                                ocnType: 'order',
                                ocnMode: 'checkout',
                                reset: true,
                              }),
                              goToOrderCart(
                                miLoc,
                                shipToCustNo,
                                toString(order?.orderCtlNo)
                              )
                            ) as string,
                          });
                          setFetchingOrder(false);
                        }}
                      />
                    </>
                  )}
                </IonContent>
                {ifRender(
                  and(canCreateEditOrder, !isClosedOrder(order)),
                  <Footer buttons={footerButtons} />
                )}
              </IonPage>
            );
          }}
        </Formik>
      )}
    </Formik>
  );
};

export default ReviewOrder;
