import React from 'react';
import { startCase, lowerCase } from 'lodash';
import { IonRow } from '@ionic/react';
import type { ContactModalProps } from 'common/components/ContactModal/ContactModal';
import Button from 'components/Button/Button';
import Text from 'components/Text/Text';
import classes from './ShareWithTeamHelpButton.module.scss';

interface TeamMemberListItemProps {
  jobTitle: string;
  name: string;
  empDisplayName: string;
  mobilePhone: string;
  workPhone?: string;
  email: string;
  testId: string;
  setIsContactModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setContactModalData: React.Dispatch<
    React.SetStateAction<Partial<ContactModalProps>>
  >;
}

const TeamMemberListItem = (props: TeamMemberListItemProps) => {
  const {
    jobTitle,
    name,
    empDisplayName,
    mobilePhone,
    workPhone,
    email,
    testId,
    setContactModalData,
    setIsContactModalOpen,
  } = props;
  return (
    <li>
      <IonRow>
        <Button
          className={classes.others}
          variant="link"
          textVariant="mipro-body-copy-bold"
          text={startCase(lowerCase(empDisplayName || name))}
          onClick={(e) => {
            e.stopPropagation();
            setContactModalData({
              name: empDisplayName,
              phoneNumber: workPhone,
              mobilePhone,
              email,
            });
            setIsContactModalOpen(true);
          }}
          testid={`${testId}-btn`}
        />
      </IonRow>
      <IonRow>
        <Text
          text={jobTitle}
          variant="content-default"
          testid={`${testId}-label`}
          className={classes.teamMemberTitle}
        />
      </IonRow>
    </li>
  );
};

export default TeamMemberListItem;
