import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { motionID } from 'constants/regex';
import classNames from 'classnames';
import { get, includes, isEmpty, isNil, toString } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IonContent, IonList, IonPage, IonRow } from '@ionic/react';
import { or } from 'common/utils/logicHelpers';
import { useFormik } from 'formik';
import {
  AuthStateEvents,
  AuthStates,
  useAuthState,
} from 'providers/AuthStateProvider';
import { UnlockMode, useVault } from 'providers/VaultProvider';
import * as yup from 'yup';
import { ModelLoginEnum } from 'api/user/useSignin';
import useFeatureFlags, { FeatureFlagType } from 'hooks/useFeatureFlags';
import useGetAppLogo from 'hooks/useGetAppLogo';
import { formatMilliseconds } from 'utils/date';
import { findIcon } from 'utils/icons';
import { getLockAfterBackgroundedConfig } from 'utils/vault';
import faceId from 'assets/faceId.svg';
import fingerprint from 'assets/fingerprint.svg';
import touchId from 'assets/touchId.svg';
import AppVersion from 'components/AppVersion/AppVersion';
import Button from 'components/Button/Button';
import Header from 'components/Header/Header';
import Input from 'components/Input/Input';
import Loader from 'components/Loader/Loader';
import ConfirmDialog from 'components/Modals/ConfirmDialog/ConfirmDialog';
import PasswordInput from 'components/PasswordInput/PasswordInput';
import Select from 'components/Select/Select';
import Text from 'components/Text/Text';
import classes from './Login.module.scss';

export const getIcon = (icon: string): string => {
  switch (icon) {
    case 'touchId':
      return touchId;
    case 'faceId':
      return faceId;
    case 'fingerprint':
      return fingerprint;
    default:
      return '';
  }
};

type LoginForm = {
  username: string;
  password: string;
  model: string;
};

const Login = (): JSX.Element => {
  const { isServicesLogo, logo } = useGetAppLogo();
  const tapCounter = 10;
  const { state, send, loginError, event } = useAuthState();
  const {
    isSystemPinEnabled,
    biometricData,
    isBiometricEnabled,
    isLockedAfterBackgrounded,
    updateUnlockMode,
    getUnlockMode,
    showPinModal,
  } = useVault();
  const vaultLockMode = getUnlockMode();
  const [isLoading, setIsLoading] = useState(false);
  const [biometricLoginIsOpen, setBiometricLoginIsOpen] = useState(false);
  const [showDevToolsCounter, setShowDevToolsCounter] = useState<number>(1);
  const { t } = useTranslation();

  const isDemoMode = useFeatureFlags(FeatureFlagType.demoMode);

  const loginFormValidationSchema = yup.object().shape({
    username: yup
      .string()
      .required(t('login:usernameRequired', { login: t('login') }))
      .matches(motionID, t('login:invalidUsername', { login: t('login') })),
    password: yup.string().required(t('login:passwordRequired')),
    // TODO: validation message
    model: isDemoMode ? yup.string().required() : yup.string().optional(),
  });

  const initialLoginFormValues: LoginForm = {
    username: '',
    password: '',
    model: '',
  };

  const { values, errors, handleSubmit, setFieldValue, validateField } =
    useFormik<LoginForm>({
      initialValues: initialLoginFormValues,
      onSubmit: ({ username, password, model }) => {
        setIsLoading(true);
        send({
          type: AuthStateEvents.SEND_LOGIN_CREDENTIALS,
          payload: { username, password, model },
        });
      },
      validationSchema: loginFormValidationSchema,
      validateOnChange: false,
      validateOnBlur: true,
    });

  const onBiometricLoginYes = async () => {
    const token = toString(get(event, 'payload.motionToken'));
    if (isSystemPinEnabled) {
      await updateUnlockMode(UnlockMode.BiometricsScreenLock, false);
    } else {
      await updateUnlockMode(UnlockMode.Biometrics, false);
    }
    send({
      type: AuthStateEvents.SAVE_VAULT_CREDENTIALS,
      payload: { motionToken: token },
    });
    setBiometricLoginIsOpen(false);
  };

  const onBiometricLoginNo = () => {
    setBiometricLoginIsOpen(false);
    void updateUnlockMode(UnlockMode.CustomPIN, false);
  };

  useEffect(() => {
    const enableSecuritySetup = async () => {
      if (
        includes([AuthStates.ASKING_TO_SET_VAULT_CREDENTIALS], state) &&
        !showPinModal
      ) {
        const motionToken = toString(get(event, 'payload.motionToken'));
        if (isBiometricEnabled) {
          setBiometricLoginIsOpen(true);
        } else {
          await updateUnlockMode(UnlockMode.CustomPIN, false);
          send({
            type: AuthStateEvents.SAVE_VAULT_CREDENTIALS,
            payload: { motionToken },
          });
        }
      }
    };
    void enableSecuritySetup();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event, isBiometricEnabled, showPinModal, state]);

  useEffect(() => {
    if (
      includes(
        [
          // DOC: display loader if has session and is in login screen
          AuthStates.HAS_VALID_SESSION,
          // DOC: display loader while validating biometric
          // DOC: display loader after save biometric credentials yes/no
          AuthStates.SAVING_VAULT_CREDENTIALS,
        ],
        state
      ) ||
      (!showPinModal && state === AuthStates.VALIDATING_VAULT_LOGIN)
    ) {
      setIsLoading(true);
    }
    if (
      or(
        includes(
          [
            // DOC: dismiss loader when app is ready
            AuthStates.APP_IS_READY,
            // DOC: dismiss loader when navigating to login page
            AuthStates.LOGIN_WITHOUT_VAULT_CREDENTIALS,
            // DOC: dismiss loader while asking for to save biometric credentials
            AuthStates.ASKING_TO_SET_VAULT_CREDENTIALS,
            AuthStates.LOGOUT_BY_RATE_LIMIT,
            AuthStates.LOGOUT_BY_TEA_POT_ERROR,
          ],
          state
        ),
        showPinModal && state === AuthStates.VALIDATING_VAULT_LOGIN
      )
    ) {
      setIsLoading(false);
    }
  }, [showPinModal, state]);

  const showDevTools = showDevToolsCounter > tapCounter;

  return (
    <IonPage className={classes.login} data-testid="login-page">
      <Header
        theme="light"
        testid="login"
        showCartIcon={false}
        hideHomeMenu
        className={classNames(classes.headerLogin, {
          [classes.headerError]: !isNil(loginError),
        })}
        toolbar={
          <>
            {!isNil(loginError) && (
              <IonRow className={classes.errorAlert}>
                <FontAwesomeIcon
                  className={classes.errorAlertIcon}
                  icon={findIcon('exclamation-triangle', 'fas')}
                />
                <p>
                  <Text
                    className={classes.errorAlertText}
                    variant="label-header"
                    testid="loginErrorMsg"
                    text={t('login:loginError', {
                      errorMessage: isEmpty(loginError.message)
                        ? ''
                        : `${loginError.message}`,
                    })}
                  />
                  <Button
                    className={classes.alertCallLink}
                    variant="underlined"
                    isExternalLink
                    href="tel:8005269328"
                    testid="alert-call-link"
                  >
                    <Text
                      className={classes.errorAlertText}
                      variant="label-header"
                      text="(800) 526-9328"
                    />
                  </Button>
                </p>
              </IonRow>
            )}
          </>
        }
      />
      <IonContent className={classes.content}>
        <Loader type="fullscreen" isOpen={isLoading} />
        <div className={classes.flexWrapper}>
          <div className={classes.wrapper}>
            <form
              onSubmit={handleSubmit}
              className={classes.form}
              noValidate
              data-testid="signin-form"
              name="signin-form"
            >
              {/* DOC: this button has position absolute because we want the form centered vertically */}
              <Button
                variant="clear"
                className={classNames(classes.logo, {
                  [classes.servicesLogo]: isServicesLogo,
                })}
                onClick={() => {
                  if (showDevToolsCounter <= tapCounter) {
                    setShowDevToolsCounter((prev) => prev + 1);
                  }
                }}
                testid="mipro-logo-wrapper"
              >
                <img
                  id="mipro-logo"
                  data-testid="mipro-logo"
                  src={logo}
                  alt="Mi Pro"
                />
              </Button>
              <Text
                className={classes.title}
                variant="title-screen-section"
                text={t('signIn')}
              />
              {isLockedAfterBackgrounded && (
                <Text
                  className={classes.isLockedAfterBackgrounded}
                  variant="content-small"
                  text={t('login:idleMessage', {
                    // TODO: this duration string is not translated
                    duration: formatMilliseconds(
                      getLockAfterBackgroundedConfig() || 0
                    ),
                  })}
                />
              )}
              <IonList>
                <Input
                  className={classes.username}
                  enterkeyhint="next"
                  inputmode="text"
                  label={t('login')}
                  name="username"
                  value={values.username}
                  error={errors.username}
                  setValue={(e) => setFieldValue('username', e)}
                  onBlur={(e) => validateField(e.target.name)}
                  disabled={isLoading}
                  testid="username-input"
                />
                <IonRow className="ion-align-items-end">
                  <PasswordInput
                    className={classes.password}
                    value={values.password}
                    error={errors.password}
                    setValue={(e) => {
                      void setFieldValue('password', e);
                    }}
                    onBlur={(e) => validateField(e.target.name)}
                    disabled={isLoading}
                    testid="password-input"
                    label={t('password')}
                  />
                </IonRow>
                {isDemoMode && (
                  <Select
                    className={classes.modelSelect}
                    label={t('login:modelSelect')}
                    name="model"
                    options={[
                      { id: ModelLoginEnum.modelAR, text: t('login:modelAR') },
                      { id: ModelLoginEnum.modelBM, text: t('login:modelBM') },
                      {
                        id: ModelLoginEnum.modelCAM,
                        text: t('login:modelCAM'),
                      },
                    ]}
                    value={values.model}
                    error={errors.model}
                    setValue={(e) => setFieldValue('model', e)}
                    disabled={isLoading}
                    testid="model-input"
                  />
                )}
              </IonList>
              <IonRow>
                <Button
                  className={classes.signinButton}
                  type="submit"
                  variant="action"
                  text={t('signIn')}
                  disabled={isLoading}
                  testid="signin-button"
                />
                {/* hackaround form onSubmit https://github.com/ionic-team/ionic-framework/issues/19368 */}
                <button
                  aria-hidden
                  type="submit"
                  className={classes.hiddenButton}
                />
              </IonRow>
              <IonRow className={classes.flexCenter}>
                <Text
                  className={classes.credentialsHelp}
                  variant="content-heavy"
                  text={t('login:credentialsHelp')}
                />
              </IonRow>
              <IonRow className={classes.note}>
                <Text variant="content-heavy" text={t('login:signInHelp')} />
                <Button
                  className={classes.callLink}
                  variant="link"
                  isExternalLink
                  href="tel:8005269328"
                  text={t('login:callHelpdesk')}
                  testid="call-link"
                />
              </IonRow>
              {vaultLockMode !== UnlockMode.NeverLock && (
                <IonRow className={classes.flexCenter}>
                  <Button
                    variant="icon-action"
                    testid="contact-customer-name-button"
                    className={classes.lockButton}
                    icon={
                      vaultLockMode === UnlockMode.CustomPIN
                        ? findIcon('lock-alt')
                        : undefined
                    }
                    iconClassName={classes.lockButtonIcon}
                    onClick={() => {
                      send({
                        type: AuthStateEvents.RETRY_VAULT_LOGIN,
                      });
                    }}
                  >
                    {includes(
                      [UnlockMode.Biometrics, UnlockMode.BiometricsScreenLock],
                      vaultLockMode
                    ) && (
                      <img
                        className={classes.biometricIcon}
                        data-testid="biometric-icon"
                        src={getIcon(biometricData.icon)}
                        alt="Bio"
                      />
                    )}
                  </Button>
                </IonRow>
              )}
            </form>
          </div>
          {showDevTools && <AppVersion />}
        </div>
        <ConfirmDialog
          isOpen={biometricLoginIsOpen}
          setIsOpen={setBiometricLoginIsOpen}
          title={t('login:biometricLogin', {
            title: t(biometricData.title),
          })}
          text={t('login:biometricConfirm', {
            turn: t('turnOn'),
            title: t(biometricData.title),
          })}
          primaryText={t('yes')}
          secondaryText={t('no')}
          onPrimaryClick={onBiometricLoginYes}
          onSecondaryClick={onBiometricLoginNo}
          onClose={onBiometricLoginNo}
          testid="biologin-modal"
        />
      </IonContent>
    </IonPage>
  );
};

export default Login;
