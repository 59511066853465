import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { map, toString, set, some } from 'lodash';
import {
  IonCol,
  IonRow,
  IonItem,
  IonLabel,
  IonPopover,
  IonList,
  isPlatform,
} from '@ionic/react';
import { namespaces } from 'i18n/i18n.constants';
import useGetDownloadAttachment from 'api/attachments/useGetDownloadAttachment';
import {
  AttachmentSize,
  type FileAttachmentProp,
  type MiProFile,
} from 'models/Attachment';
import {
  attachmentAcceptedFiles,
  updateFileSizeFlag,
  getFileExtension,
  isImageFile,
  isPreviewDisabled,
} from 'utils/filesUpload';
import { compressImages, withStringProp } from 'utils/helpers';
import { findIcon } from 'utils/icons';
import Button from 'components/Button/Button';
import ConfirmDialog from 'components/Modals/ConfirmDialog/ConfirmDialog';
import Text from 'components/Text/Text';
import classes from './Attachments.module.scss';
import AttachmentThumbnail from './AttachmentThumbnail';

interface AttachmentsProps {
  domain?: string;
  size?: string;
  label?: string;
  className?: string;
  name: string;
  disabled?: boolean;
  editMode?: boolean;
  prefetchAll?: boolean;
  enableSmallPreview?: boolean;
  files?: MiProFile[];
  setFieldError?: (n: string, v: string) => void;
  onAdd?: (file: MiProFile[]) => void;
  onRemove?: (file: MiProFile) => void;
  testid: string;
}

const Attachments = ({
  domain,
  size,
  name,
  label,
  className,
  disabled,
  enableSmallPreview,
  files,
  setFieldError,
  onAdd,
  onRemove,
  testid,
  prefetchAll = false,
  editMode = true,
}: AttachmentsProps): JSX.Element => {
  const withLabel = withStringProp(label);
  const { t } = useTranslation(namespaces.common);
  const { startDownload } = useGetDownloadAttachment({
    domain: toString(domain),
  });
  const [filesToUpload, setFilesToUpload] = useState<Array<MiProFile>>([]);
  const [fileRemoveConfirmDialogIsOpen, setFileRemoveConfirmDialogIsOpen] =
    useState(false);
  const [fileForRemoval, setFileForRemoval] = useState<{
    fileToRemove: MiProFile | undefined;
    index: number;
  }>({
    fileToRemove: undefined,
    index: -1,
  });

  const attachmentKey = useMemo(() => toString(Date.now()), []);

  useEffect(() => {
    setFilesToUpload(files || []);
  }, [files]);

  const removeFile = (index: number, file?: MiProFile) => {
    if (file) {
      onRemove?.(file);
      setFilesToUpload([
        ...filesToUpload.slice(0, index),
        ...filesToUpload.slice(index + 1),
      ]);
    }
  };

  const photoLibFileInput = useRef<HTMLInputElement>(null);
  const takePhotoFileInput = useRef<HTMLInputElement>(null);
  const fileInput = useRef<HTMLInputElement>(null);
  const hiddenFileInput = useRef<HTMLInputElement>(null);

  const onFilesClick = (e: React.BaseSyntheticEvent) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    e.target.value = null;
  };

  const onFilesChange = async (e: React.BaseSyntheticEvent) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    const fileListObj = e.target.files as FileList;
    const fileArray: MiProFile[] = [];
    await Promise.all(
      map(fileListObj, async (fileItem) => {
        // file is compressed only if it is image type
        let updatedFile = fileItem;
        let fileURL = '';
        if (fileItem.type.startsWith('image/')) {
          updatedFile = await compressImages(fileItem);
          fileURL = await new Promise((resolve) => {
            const reader = new FileReader();
            reader.onload = (fileResult) => {
              resolve(toString(fileResult.target?.result));
            };
            reader.readAsDataURL(updatedFile);
          });
        }
        set(updatedFile, 'fileName', `${Date.now()}-${updatedFile.name}`);
        set(updatedFile, 'fileURL', fileURL);
        fileArray.push(updatedFile);
      })
    );
    onAdd?.([...fileArray]);
    setFilesToUpload([...filesToUpload, ...fileArray]);
  };

  const fileSizeExceeded = some(
    map(filesToUpload, (file) => updateFileSizeFlag([file]))
  );
  const attachmentsTooLargeError = t('attachmentsTooLarge', {
    count: filesToUpload.length,
  });

  const onFileClick = (img: FileAttachmentProp | MiProFile) => {
    if (size === AttachmentSize.SMALL && !enableSmallPreview) {
      return;
    }
    startDownload(img as MiProFile);
  };

  const onFileRemoveConfirmDialogYes = () => {
    setFileRemoveConfirmDialogIsOpen(false);
    removeFile(fileForRemoval.index, fileForRemoval?.fileToRemove);
  };

  const onFileRemoveConfirmDialogNo = () => {
    setFileRemoveConfirmDialogIsOpen(false);
  };

  useEffect(() => {
    setFieldError?.(name, fileSizeExceeded ? attachmentsTooLargeError : '');
  }, [setFieldError, name, fileSizeExceeded, attachmentsTooLargeError]);

  return (
    <IonItem
      className={classNames(className, classes.attachmentCont)}
      lines="none"
    >
      <IonCol>
        {withLabel && (
          <IonRow className={classes.labelRow}>
            <Text
              className={classNames(classes.label, {
                [classes.errWarning]: fileSizeExceeded,
              })}
              variant="title-card-red"
              text={toString(label)}
              testid={`${testid}-label`}
            />
          </IonRow>
        )}
        {fileSizeExceeded && (
          <IonRow className={classes.sizeExceedError}>
            <Text
              text={attachmentsTooLargeError}
              className={classNames(classes.label)}
            />
          </IonRow>
        )}
        <IonRow
          className={classNames(classes.attachmentRow, {
            [classes.viewSmall]: !editMode && size === AttachmentSize.SMALL,
          })}
        >
          {editMode && (
            <IonCol
              className={classNames(classes.attachItemCol, classes.addColumn, {
                [classes.disabledColumn]: disabled,
                [classes.small]: size === AttachmentSize.SMALL,
                [classes.medium]: size === AttachmentSize.MEDIUM,
              })}
            >
              <Button
                className={classNames(classes.addImageButton, {
                  [classes.disabledAdd]: disabled,
                })}
                variant="secondary"
                icon={findIcon('plus', 'fas')}
                onClick={
                  isPlatform('ios')
                    ? () => hiddenFileInput.current?.click()
                    : undefined
                }
                testid="add-image-button"
                disabled={disabled}
                id={`auto-trigger-${attachmentKey}`}
              />
              {isPlatform('ios') ? (
                <input
                  accept={attachmentAcceptedFiles}
                  type="file"
                  name={name}
                  onClick={(e) => onFilesClick(e)}
                  onChange={(e) => onFilesChange(e)}
                  multiple
                  style={{ display: 'none' }}
                  ref={hiddenFileInput}
                  disabled={disabled}
                />
              ) : (
                <>
                  <IonPopover
                    key={`${attachmentKey}-popover`}
                    trigger={`auto-trigger-${attachmentKey}`}
                    dismissOnSelect
                    side="top"
                  >
                    <IonList>
                      <IonItem
                        button
                        detail={false}
                        onClick={() => photoLibFileInput.current?.click()}
                      >
                        <IonLabel>
                          <>{t('photoLibrary')}</>
                        </IonLabel>
                      </IonItem>
                      <IonItem
                        button
                        detail={false}
                        onClick={() => takePhotoFileInput.current?.click()}
                      >
                        <IonLabel>
                          <>{t('takePhoto')}</>
                        </IonLabel>
                      </IonItem>
                      <IonItem
                        button
                        detail={false}
                        onClick={() => fileInput.current?.click()}
                      >
                        <IonLabel>
                          <>{t('chooseFile')}</>
                        </IonLabel>
                      </IonItem>
                    </IonList>
                  </IonPopover>
                  <input
                    key="photo-lib"
                    type="file"
                    name={name}
                    onClick={(e) => onFilesClick(e)}
                    onChange={(e) => onFilesChange(e)}
                    style={{ display: 'none' }}
                    ref={photoLibFileInput}
                    accept="image/*"
                    disabled={disabled}
                  />
                  <input
                    key="take-photo"
                    type="file"
                    name={name}
                    onClick={(e) => onFilesClick(e)}
                    onChange={(e) => onFilesChange(e)}
                    multiple
                    style={{ display: 'none' }}
                    ref={takePhotoFileInput}
                    disabled={disabled}
                    accept="image/*"
                    capture
                  />
                  <input
                    data-testid="file-input"
                    key="file-input"
                    accept={attachmentAcceptedFiles}
                    type="file"
                    name={name}
                    onClick={(e) => onFilesClick(e)}
                    onChange={(e) => onFilesChange(e)}
                    multiple
                    style={{ display: 'none' }}
                    ref={fileInput}
                    disabled={disabled}
                  />
                </>
              )}
            </IonCol>
          )}
          {map(filesToUpload, (file, index) => {
            let fileType = file.type;
            fileType ||= getFileExtension({ file });
            const isImage = isImageFile(fileType);
            const previewDisabled = isPreviewDisabled({ file });
            return (
              <IonCol
                key={`${file.name}-${index}`}
                className={classNames(classes.attachItemCol, {
                  [classes.imageCol]: isImage,
                  [classes.imageError]: updateFileSizeFlag([file]),
                  [classes.small]: size === AttachmentSize.SMALL,
                  [classes.medium]: size === AttachmentSize.MEDIUM,
                  [classes.viewOnly]: !editMode,
                  [classes.previewDisabled]: previewDisabled,
                })}
              >
                <AttachmentThumbnail
                  domain={domain}
                  width={500}
                  height={500}
                  file={
                    {
                      ...file,
                      ...(file.fileURL
                        ? { forUpload: true, src: file.fileURL }
                        : undefined),
                    } as FileAttachmentProp
                  }
                  prefetch={prefetchAll}
                  isImage={isImage}
                  fileType={fileType}
                  onImageClick={onFileClick}
                />

                {editMode && (
                  <Button
                    key={`${file.name}${index}`}
                    className={classes.removeButton}
                    icon={findIcon('close', 'fas')}
                    iconClassName={classes.removeIcon}
                    onClick={() => {
                      setFileRemoveConfirmDialogIsOpen(true);
                      setFileForRemoval({ fileToRemove: file, index });
                    }}
                    testid="remove-img-button"
                    disabled={disabled}
                  />
                )}
              </IonCol>
            );
          })}
        </IonRow>
      </IonCol>

      <ConfirmDialog
        isOpen={fileRemoveConfirmDialogIsOpen}
        setIsOpen={setFileRemoveConfirmDialogIsOpen}
        title={t('removeFileTitle')}
        text={t('removeFileMessage')}
        primaryText={t('removeFileNo')}
        secondaryText={t('removeFileYes')}
        onPrimaryClick={onFileRemoveConfirmDialogNo}
        onSecondaryClick={onFileRemoveConfirmDialogYes}
        testid="remove-file-modal"
      />
    </IonItem>
  );
};

export default Attachments;
