import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { isEmpty, padEnd, split, toString } from 'lodash';
import CustomerName from 'common/components/Header/CustomerName';
import { and, choose, ifFunction, or } from 'common/utils/logicHelpers';
import { useGetAvpUser } from 'common/utils/userInfo';
import { searchURL } from 'navigation';
import type { SalesPerformanceURLParams } from 'ReportsApp/pages/SalesPerformance/SalesPerformance';
import { getIsSearchTab } from 'ReportsApp/pages/SalesPerformance/salesReportHelpers';
import useGetCustomer from 'api/customer/useGetCustomer';
import useGetEmployee from 'api/employee/useGetEmployee';
import { useGetSelectedMiLoc } from 'api/helpers';
import useGetAccountRep from 'api/location/useGetAccountRep';
import useGetLocation from 'api/location/useGetLocation';
import useGetProductCategory from 'api/location/useGetProductCategory';
import useAccessControls, { AccessControlType } from 'hooks/useAccessControls';
import type { RootState } from 'store/reducers';
import { concatRoutes } from 'utils/navigations';
import { handleSearchNavigation } from 'utils/search';
import Button from 'components/Button/Button';

interface UseGetTitleAndLinkProps {
  miLoc: string;
  customerMiLoc: string;
  rowId: string;
}

export const useGetTitleAndLink = ({
  miLoc,
  customerMiLoc,
  rowId,
}: UseGetTitleAndLinkProps) => {
  const { t } = useTranslation();
  const canViewCustomers = useAccessControls(AccessControlType.viewCustomers);
  const { isCamUser } = useSelector((state: RootState) => state.user);

  const { orgType = '', pgc1 = '' } = useParams<SalesPerformanceURLParams>();

  const { data: locationData } = useGetLocation({ miLoc });
  const { data: accountRepData } = useGetAccountRep({ miLoc, id: rowId });

  const { data: customerData } = useGetCustomer({
    searchType: 'customer',
    miLoc: customerMiLoc,
    id: rowId ? padEnd(rowId, 8, '0') : '',
    enabled: and(!!rowId, orgType !== 'REP', orgType !== 'CAM'),
  });

  const { isAvpUserWithExecView } = useGetAvpUser();

  const { data: employeeData } = useGetEmployee({
    id: choose(orgType === 'CAM', rowId) as string,
  });

  const { data: pgc1Data } = useGetProductCategory({ miLoc, pgc1 });

  let title;
  let headerLink;
  let locationTitle;
  let locationLink;

  locationTitle = locationData?.miLoc
    ? `${toString(locationData?.miLoc)}: ${toString(locationData?.name)}`
    : t('common:loading');
  if (locationData?.userRole === 'TEAM') {
    locationTitle = locationData?.name;
  }
  title = locationTitle;
  if (locationData?.userRole === 'BRCH') {
    locationLink = handleSearchNavigation({
      type: 'motionLocation',
      miLoc,
      sequenceNo: miLoc,
    });
    headerLink = locationLink;
  }
  if (accountRepData?.name) {
    headerLink = handleSearchNavigation({
      type: 'employee',
      miLoc,
      employeeId: rowId,
    });
    title = accountRepData?.name;
  }
  if (and(customerData?.name, customerData?.customerNo)) {
    if (canViewCustomers) {
      headerLink = handleSearchNavigation({
        type: 'customer',
        miLoc: customerMiLoc,
        customerId: rowId,
      });
    }
    title = customerData?.name;
    if (or(isCamUser, isAvpUserWithExecView)) {
      title = `${toString(customerData?.name)} > ${miLoc}`;
    }
  }
  if (pgc1Data?.name) {
    title = pgc1Data?.name;
  }

  return {
    title,
    headerLink,
    locationTitle,
    locationLink,
    customerData,
    employeeData,
  };
};

const useReportHeader = (sales?: boolean) => {
  const { t } = useTranslation();
  const { miLoc: stateMiLoc = '', isCamUser } = useSelector(
    (state: RootState) => state.user
  );
  const { isAvpUserWithExecView } = useGetAvpUser();

  const {
    miLoc: routeMiLoc = '',
    rowId: routeRowId = '',
    pgc1 = '',
    orgType = '',
    tab = '',
  } = useParams<SalesPerformanceURLParams>();

  const [rowId, rowMiLoc] = split(routeRowId, '-');
  let miLoc = routeMiLoc;
  miLoc ||= stateMiLoc;
  let customerMiLoc = rowMiLoc;
  customerMiLoc ||= miLoc;

  const titleAndLink = useGetTitleAndLink({
    miLoc,
    customerMiLoc,
    rowId,
  });
  let { title } = titleAndLink;
  const {
    headerLink,
    locationTitle,
    locationLink,
    customerData,
    employeeData,
  } = titleAndLink;

  const { fromVirtualTeam, singleTeam } = useGetSelectedMiLoc(miLoc);

  if (fromVirtualTeam) {
    title = singleTeam && sales ? singleTeam.locName : t('reports:myTeams');
  }

  if (and(or(isCamUser, isAvpUserWithExecView), miLoc === 'EXEC')) {
    title = t('reports:myCorporateAccounts');
  }

  ifFunction(orgType === 'CAM', () => {
    title = `${toString(employeeData?.name)} > ${miLoc}`;
  });

  const camLink = handleSearchNavigation({
    type: 'employee',
    miLoc,
    employeeId: rowId,
  });

  return {
    title,
    reportHeader: (
      <>
        {customerData?.customerNo ? (
          <CustomerName customerData={customerData} />
        ) : (
          <Button
            text={choose(
              orgType === 'CAM',
              t('reports:myCorporateAccounts'),
              title
            )}
            textVariant="mipro-h2-headline"
            variant={headerLink ? 'link' : 'clear'}
            href={
              headerLink ? concatRoutes(searchURL(), headerLink) : undefined
            }
            testid="location-button"
          />
        )}
        {pgc1 && (
          <Button
            text={title}
            textVariant="mipro-h2-headline"
            variant="clear"
            testid="location-button"
          />
        )}
        {or(isCamUser, isAvpUserWithExecView) &&
          customerData?.customerNo &&
          !getIsSearchTab(tab) && (
            <Button
              text={locationTitle}
              textVariant="mipro-h2-headline"
              variant={locationLink ? 'link' : 'clear'}
              href={
                locationLink
                  ? concatRoutes(searchURL(), locationLink)
                  : undefined
              }
              testid="location-button"
            />
          )}

        {and(isAvpUserWithExecView, !isEmpty(employeeData)) && (
          <Button
            text={employeeData?.name}
            textVariant="mipro-h3-headline"
            variant="link"
            href={choose(camLink, concatRoutes(searchURL(), camLink))}
            testid="employee-button"
          />
        )}
      </>
    ),
  };
};

export default useReportHeader;
