import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import type { Dictionary } from 'lodash';
import { isNil, toNumber, toString } from 'lodash';
import { getUnixTime, startOfToday } from 'date-fns';
import type { DateSegmentType, ReportsContextProps } from 'models/Reports';
import { SortDirEnum, SortFieldEnum } from 'models/Sort';
import type { FilterOption } from 'components/Filter/Filter';

interface IReportsContextProps {
  busPeriod?: Dictionary<number>;
  defaultRequestType?: Dictionary<DateSegmentType>;
  requestType?: Dictionary<DateSegmentType>;
  sortField?: Dictionary<string>;
  sortDir?: Dictionary<string>;
  otherFilters?: Dictionary<Dictionary<FilterOption>>;
  updateRequestType: (key: string, dateSegment: DateSegmentType) => void;
  updateBusPeriod: (key: string, date: number) => void;
  updateSortField: (key: string, field: string) => void;
  updateSortDir: (key: string, dir: string) => void;
  updateOtherFilters: (key: string, filter?: FilterOption) => void;
  defaultSortField?: Dictionary<string>;
  defaultSortType?: Dictionary<string>;
  defaultSortDir?: Dictionary<string>;
}

export const TabHomeKey = 'home-tab';
export const TabSalesPerformanceKey = 'sales-tab';
export const TabPick12ReportKey = 'pick12-report-tab';
export const TabWebSalesReportKey = 'web-report-tab';
export const SalesPerformanceHomeKey = 'sales-home-key';
export const Pick12HomeKey = 'pick12-home-key';
export const WebSalesHomeKey = 'web-home-key';
export const ProductGroupKey = 'product-group-key';
export const TabEOMReportKey = 'eom-group-key';
export const TabCostSavingsReportKey = 'cost-savings-key';
export const TabUnbilledReportKey = 'unbilled-key';
export const DatePickerKey = 'date-picker-key';

const ReportsContext = createContext<IReportsContextProps>({
  updateRequestType: () => null,
  updateBusPeriod: () => null,
  updateSortField: () => null,
  updateSortDir: () => null,
  updateOtherFilters: () => null,
});

const ReportsProvider = ({
  children,
}: React.PropsWithChildren<unknown>): JSX.Element => {
  const [busPeriod, setBusPeriod] = useState<Dictionary<number>>();
  const [sortField, setSortFieldKey] = useState<Dictionary<string>>();
  const [sortDir, setSortDirKey] = useState<Dictionary<string>>();
  const [requestType, setRequestType] = useState<Dictionary<DateSegmentType>>();
  const [otherFilters, setOtherFilters] =
    useState<Dictionary<Dictionary<FilterOption>>>();
  const defaultRequestType: Dictionary<DateSegmentType> = {
    [TabHomeKey]: 'DAILY',
    [Pick12HomeKey]: 'YTD',
    [SalesPerformanceHomeKey]: 'DAILY',
    [WebSalesHomeKey]: 'MTD',
    [ProductGroupKey]: 'YTD',
    [TabEOMReportKey]: 'MTD',
    [DatePickerKey]: 'DAILY',
  };

  const defaultSortField: Dictionary<string> = {
    [TabCostSavingsReportKey]: SortFieldEnum.overage,
    [TabSalesPerformanceKey]: SortFieldEnum.currentSales,
    [TabUnbilledReportKey]: SortFieldEnum.totalPossibleAmt,
    [TabPick12ReportKey]: SortFieldEnum.currentSales,
    [TabWebSalesReportKey]: SortFieldEnum.sales,
  };

  const defaultSortDir: Dictionary<string> = {
    [TabCostSavingsReportKey]: SortDirEnum.ASCENDING,
    [TabSalesPerformanceKey]: SortDirEnum.DESCENDING,
    [TabUnbilledReportKey]: SortDirEnum.DESCENDING,
    [TabWebSalesReportKey]: SortDirEnum.DESCENDING,
  };

  const updateRequestType = (key: string, newDateSegment: DateSegmentType) => {
    if (requestType?.[key] !== newDateSegment) {
      setRequestType((prev) => ({ ...prev, [key]: newDateSegment }));
    }
  };

  const updateBusPeriod = (key: string, newBusPeriod: number) => {
    if (busPeriod?.[key] !== newBusPeriod) {
      setBusPeriod((prev) => ({ ...prev, [key]: newBusPeriod }));
    }
  };

  const updateSortField = (key: string, field: string) => {
    if (sortField?.[key] !== field) {
      setSortFieldKey((prev) => ({ ...prev, [key]: field }));
    }
  };

  const updateSortDir = (key: string, dir: string) => {
    if (sortDir?.[key] !== dir) {
      setSortDirKey((prev) => ({ ...prev, [key]: dir }));
    }
  };

  const updateOtherFilters = (key: string, filter?: FilterOption) => {
    const filterId = toString(filter?.id);
    if (isNil(filter)) {
      setOtherFilters((prev) => ({
        ...prev,
        [key]: {},
      }));
    }
    if (otherFilters?.[key]?.[filterId]?.key !== filter?.key) {
      setOtherFilters((prev) => ({
        ...prev,
        [key]: { ...prev?.[key], [filterId]: filter as FilterOption },
      }));
    }
  };

  const contextValue = useMemo(
    () => ({
      busPeriod,
      defaultRequestType,
      requestType,
      sortField,
      sortDir,
      otherFilters,
      updateRequestType,
      updateBusPeriod,
      updateSortField,
      updateSortDir,
      updateOtherFilters,
      defaultSortField,
      defaultSortDir,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      busPeriod,
      defaultRequestType,
      defaultSortDir,
      defaultSortField,
      otherFilters,
      requestType,
      sortDir,
      sortField,
    ]
  );

  return (
    <ReportsContext.Provider value={contextValue}>
      {children}
    </ReportsContext.Provider>
  );
};
export default ReportsProvider;

interface UseReportsConfigProps {
  key: string;
}

export const useReportsConfig = ({
  key,
}: UseReportsConfigProps): ReportsContextProps => {
  const ctx = useContext(ReportsContext);

  if (!ctx) {
    throw Error(
      'The `useReportsConfig` hook must be called inside a `ReportsProvider`.'
    );
  }

  const defaultRequestType = ctx.defaultRequestType?.[key] || 'MTD';

  return {
    busPeriod: !isNil(ctx.busPeriod?.[key])
      ? toNumber(ctx.busPeriod?.[key])
      : getUnixTime(startOfToday()),
    requestType:
      ctx.requestType?.[key] || ctx.defaultRequestType?.[key] || 'MTD',
    updateRequestType: useCallback(
      (newDateSegment: DateSegmentType = defaultRequestType) =>
        ctx.updateRequestType(key, newDateSegment),
      [ctx, defaultRequestType, key]
    ),
    updateBusPeriod: useCallback(
      (newBusPeriod: Date) =>
        ctx.updateBusPeriod(key, getUnixTime(newBusPeriod)),
      [ctx, key]
    ),
    sortField: ctx.sortField?.[key] || ctx.defaultSortField?.[key],
    updateSortField: useCallback(
      (option: string) => ctx.updateSortField(key, option),
      [ctx, key]
    ),
    sortDir:
      ctx.sortDir?.[key] || ctx.defaultSortDir?.[key] || SortDirEnum.DESCENDING,
    updateSortDir: useCallback(
      (option: string) => ctx.updateSortDir(key, option),
      [ctx, key]
    ),
    otherFilters: ctx.otherFilters?.[key],
    updateOtherFilters: useCallback(
      (filter?: FilterOption) => ctx.updateOtherFilters(key, filter),
      [ctx, key]
    ),
  };
};
