import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { toString } from 'lodash';
import type { BaseComponentProps } from 'common/components/utils/renderHelpers';
import { choose } from 'common/utils/logicHelpers';
import type { FilterOption } from 'components/Filter/Filter';
import Filter from 'components/Filter/Filter';
import classes from './ReportFilter.module.scss';

interface FilterTypeOption {
  title: string;
  options: {
    key: string;
    name: string;
  }[];
  hidden: boolean;
}

interface ReportFilterProps extends BaseComponentProps {
  custTypeOptions: FilterTypeOption;
  contractTypeOptions: FilterTypeOption;
  selectedTypeData?: FilterOption;
  updateOtherFilters?: (type: FilterOption) => void;
  defaultFilter: boolean;
  filterCount: number;
  isHeader: boolean;
  isContractFilter: boolean;
}

const ReportFilter = ({
  custTypeOptions,
  contractTypeOptions,
  selectedTypeData,
  defaultFilter,
  filterCount,
  updateOtherFilters,
  isHeader,
  testid = 'reportfilter',
  isContractFilter,
}: ReportFilterProps): React.ReactNode => {
  const { t } = useTranslation();
  // region filters
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  return (
    <Filter
      className={isHeader ? classes.filterHeader : classes.filter}
      selectedItems={[selectedTypeData]}
      setFilterData={[
        (option) => {
          updateOtherFilters?.({
            ...option,
            id: isContractFilter
              ? 'contractTypesToShow'
              : 'custAcctTypesToShow',
          } as FilterOption);
        },
      ]}
      filterOptions={[isContractFilter ? contractTypeOptions : custTypeOptions]}
      isOpen={isFilterOpen}
      setIsOpen={setIsFilterOpen}
      testid={`${testid}-filter`}
      variant="sort"
      modalTitle={t('common:filter')}
      customButton={{
        className: classNames(classes.filterButton, {
          [classes.filterNone]: defaultFilter,
          [classes.hasFilters]: !defaultFilter,
        }),
        text: choose(defaultFilter, t('common:filter'), toString(filterCount)),
        testid: `${testid}-button`,
        children: undefined,
        rightIcon: undefined,
        icon: ['far', 'sliders-up'],
      }}
    />
  );
};

export default ReportFilter;
