import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import {
  filter,
  find,
  forEach,
  head,
  isEmpty,
  kebabCase,
  map,
  size,
  toString,
} from 'lodash';
import type { IconName, IconPrefix } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { IonicReactProps } from '@ionic/react/dist/types/components/IonicReactProps';
import { namespaces } from 'i18n/i18n.constants';
import { findIcon } from 'utils/icons';
import Button from 'components/Button/Button';
import Loader from 'components/Loader/Loader';
import SheetModal from 'components/Modals/SheetModal/SheetModal';
import Text from 'components/Text/Text';
import classes from './Filter.module.scss';

export interface FilterOption {
  id?: string;
  key: string;
  name: string;
  icon?: IconName;
  status?: string;
  type?: string;
  iconPrefix?: IconPrefix;
  customContent?: React.ReactNode;
}

interface FilterProps {
  variant?: 'filter' | 'sort';
  customButton?: React.ComponentProps<typeof Button>;
  customContent?: React.ReactNode;
  selectedItems?: Array<FilterOption | undefined>;
  filterOptions: Array<{
    title: string;
    options:
      | FilterOption[]
      | ((items: Array<FilterOption | undefined>) => FilterOption[]);
    hidden?: boolean;
  }>;
  setFilterData?: Array<(option?: FilterOption) => void>;
  testid: string;
  toggleOnSelection?: boolean;
  withFooter?: boolean;
  modalTitle?: string;
}

const Filter = ({
  isOpen,
  setIsOpen,
  variant = 'filter',
  initialBreakpoint,
  customButton,
  customContent: pCustomContent,
  className,
  selectedItems,
  filterOptions,
  setFilterData,
  testid,
  toggleOnSelection = false,
  modalTitle,
  withFooter = false,
}: FilterProps &
  IonicReactProps &
  Omit<React.ComponentProps<typeof SheetModal>, 'title'>): JSX.Element => {
  const { t } = useTranslation();
  const [selectedOptions, setSelectedOptions] =
    useState<Array<FilterOption | undefined>>();

  useEffect(() => {
    if (isOpen) {
      setSelectedOptions(selectedItems);
    }
  }, [isOpen, selectedItems]);

  const hasSelectedItems = size(filter(selectedItems, (o) => !!o)) > 0;
  const filterText = hasSelectedItems
    ? map(selectedItems, (o) => toString(o?.name)).join(' ')
    : t('filter');
  const onlySection = size(filterOptions) === 1;

  const disabledFilter = isEmpty(
    find(filterOptions, (item) => !item.hidden)?.options
  );

  return (
    <div className={className}>
      <Button
        className={classNames(classes.button, classes[variant], {
          [classes.filterSelected]: hasSelectedItems,
        })}
        variant={variant === 'sort' ? 'link' : undefined}
        onClick={() => {
          if (toggleOnSelection && hasSelectedItems) {
            forEach(setFilterData, (fn) => fn(undefined));
          } else {
            setIsOpen?.(true);
          }
        }}
        icon={
          variant === 'filter'
            ? findIcon(hasSelectedItems ? 'times' : 'filter', 'far')
            : undefined
        }
        rightIcon={
          variant === 'sort' ? findIcon('caret-down', 'fas') : undefined
        }
        text={variant === 'filter' ? filterText : t('common:sort')}
        testid={`${testid}-open-filter`}
        // eslint-disable-next-line react/no-children-prop
        children={
          variant === 'sort' && (
            <div className={classes.sortRow}>
              {map(
                selectedItems,
                (i) =>
                  !!i && (
                    <div key={i.key} className={classes.sortLabel}>
                      {i.icon && <FontAwesomeIcon icon={i.icon} />}
                      <Text text={i.name} />
                    </div>
                  )
              )}
            </div>
          )
        }
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...customButton}
      />
      <SheetModal
        title={
          modalTitle || (variant === 'filter' ? t('filter') : t('common:sort'))
        }
        className={classes.filterSheet}
        isOpen={isOpen}
        withRightCloseButton={withFooter}
        setIsOpen={setIsOpen}
        initialBreakpoint={initialBreakpoint}
        customHeader={
          !withFooter &&
          !onlySection && (
            <Button
              variant="link"
              text={t('common:apply')}
              onClick={() => {
                setIsOpen?.(false);
                forEach(setFilterData, (fn, idx) => fn(selectedOptions?.[idx]));
              }}
              testid="apply-button"
            />
          )
        }
        testid={`${testid}-modal`}
      >
        <div className={classes.content}>
          {pCustomContent}
          {map(filterOptions, ({ title, hidden, options: iOptions }, idx) => {
            const options =
              typeof iOptions === 'function'
                ? iOptions?.(selectedOptions || [])
                : iOptions;

            return (
              <div
                key={idx}
                className={classNames({
                  [classes.hideOption]: hidden,
                })}
              >
                <Text
                  className={classes.sectionTitle}
                  variant="list-item-subtitle"
                  text={title}
                />
                <div>
                  {map(
                    options,
                    ({ key, name, icon, customContent, iconPrefix }, index) => {
                      const selectedKey = selectedOptions?.[idx]?.key;
                      const isSelected = key === selectedKey;
                      const validIconType = iconPrefix || 'far';

                      return (
                        <Button
                          key={`${kebabCase(name)}-${index}`}
                          className={classNames(classes.filterRow, {
                            [classes.optionSelected]: isSelected,
                          })}
                          variant={isSelected ? 'action' : 'clear'}
                          icon={
                            icon ? findIcon(icon, validIconType) : undefined
                          }
                          text={name}
                          textVariant="mipro-h6-headline"
                          onClick={() => {
                            setSelectedOptions((prev = []) => {
                              const result = [...prev];
                              result[idx] = options[index];
                              return result;
                            });
                            // DOC: wait for next tick
                            setTimeout(() => {
                              if (onlySection) {
                                setIsOpen?.(false);
                                head(setFilterData)?.(options[index]);
                              }
                            });
                          }}
                          // eslint-disable-next-line react/no-children-prop
                          children={customContent}
                          testid={`${testid}-${kebabCase(name)}`}
                        />
                      );
                    }
                  )}
                  {disabledFilter && (
                    <Loader
                      isOpen={disabledFilter}
                      testid="filter-loader"
                      text={t(`${namespaces.common}:filterLoader`)}
                    />
                  )}
                </div>
              </div>
            );
          })}
        </div>
        {withFooter && (
          <div className={classes.footer}>
            <div className={classes.buttonBar}>
              <Button
                variant="secondary"
                text={t('common:clear')}
                testid="clear-filter"
                className={classes.fullWidth}
                onClick={() => {
                  forEach(setFilterData, (fn, idx) => {
                    let options = filterOptions?.[idx]?.options;
                    options =
                      typeof options === 'function'
                        ? options?.(selectedOptions || [])
                        : options;
                    fn(options?.[0]);
                  });
                  setIsOpen?.(false);
                }}
              />
              <Button
                variant="action"
                text={t('common:apply')}
                testid="apply-filter"
                className={classes.fullWidth}
                onClick={() => {
                  setIsOpen?.(false);
                  forEach(setFilterData, (fn, idx) =>
                    fn(selectedOptions?.[idx])
                  );
                }}
              />
            </div>
          </div>
        )}
      </SheetModal>
    </div>
  );
};

export default Filter;
