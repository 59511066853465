import React, { useEffect, useImperativeHandle, useState } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { toString, toNumber, size, isNil, map } from 'lodash';
import { Style as StatusBarStyle } from '@capacitor/status-bar';
import {
  IonButtons,
  IonHeader,
  IonMenuButton,
  IonMenuToggle,
  IonRow,
  IonTitle,
  IonToolbar,
  useIonViewWillEnter,
} from '@ionic/react';
import type { IonicReactProps } from '@ionic/react/dist/types/components/IonicReactProps';
import {
  getIntervalRender,
  getOptionalRenderProp,
  type OptionalComponentProps,
  type OptionalRenderProp,
  useClassNameObserver,
  useNodeRef,
} from 'common/components/utils/renderHelpers';
import { and, choose, ifRender, or } from 'common/utils/logicHelpers';
import useGetOrder from 'ProductSearchApp/api/useGetOrder';
import useAccessControls, { AccessControlType } from 'hooks/useAccessControls';
import useChangeAppMode from 'hooks/useChangeAppMode';
import useChangeLocation from 'hooks/useChangeLocation';
import useGoBack from 'hooks/useGoBack';
import type { RootState } from 'store/reducers';
import { setStatusBarTheme } from 'utils/helpers';
import { goToOrderCart } from 'navigation/navigationHelpers';
import Badge from 'components/Badge/Badge';
import Button from 'components/Button/Button';
import HeaderActions from 'components/HeaderActions/HeaderActions';
import Text from 'components/Text/Text';
import classes from './Header.module.scss';

export enum HeaderThemeEnum {
  light = 'light',
  dark = 'dark',
}

interface HeaderProps {
  /* eslint-disable react/require-default-props */
  eyebrow?: OptionalRenderProp;
  title?: OptionalRenderProp;
  subTitle?: OptionalRenderProp;
  pageTitle?: OptionalRenderProp;
  customTitle?: React.ReactNode;
  endSlotComponent?: React.ReactNode;
  backButton?: OptionalComponentProps<React.ComponentProps<typeof Button>>;
  showLocationText?: boolean;
  hideMenuButton?: boolean;
  hideBackButton?: boolean;
  hideCartButton?: boolean;
  hideLocationSelector?: boolean;
  hideEndSlotComponent?: boolean;
  headerActions?: OptionalComponentProps<
    React.ComponentProps<typeof HeaderActions>
  >;
  theme?: HeaderThemeEnum;
  testid: string;
  customEndButtons?: OptionalComponentProps<
    React.ComponentProps<typeof Button>[]
  >;
  /* eslint-enable react/require-default-props */
}

const Header = React.forwardRef<
  HTMLIonHeaderElement | undefined,
  HeaderProps &
    Omit<React.ComponentProps<typeof IonHeader>, 'title'> &
    IonicReactProps
>((props, outerRef) => {
  const {
    className,
    customTitle,
    backButton,
    headerActions,
    children,
    theme = HeaderThemeEnum.dark,
    collapse,
    testid,
    endSlotComponent,
    customEndButtons,
    showLocationText,
    hideEndSlotComponent = true,
  } = props;
  const { goBack } = useGoBack();
  const { currentCartCustomer } = useSelector((state: RootState) => state.user);

  const { isMiProApp } = useChangeAppMode();
  const { isLocatorAvailable, locatorURL, locationText } = useChangeLocation();
  const isCartAccessible = useAccessControls(
    AccessControlType.EditOrdersAccessControls
  );
  const { numberOfCartItems } = useGetOrder({
    miLoc: currentCartCustomer?.miLoc,
    orderCtlNo: currentCartCustomer?.orderCtlNo,
  });
  const [actionsIsOpen, setActionsIsOpen] = useState(false);
  const {
    hasClassName: condenseInactive,
    node,
    nodeRef,
  } = useClassNameObserver<HTMLIonHeaderElement>(
    'header-collapse-condense-inactive'
  );
  const { node: toolbarRef, nodeRef: setToolbarRef } = useNodeRef();
  const isCollapsed = !condenseInactive;

  useImperativeHandle(outerRef, () => node, [node]);

  const { text: eyebrow, props: eyebrowProps } = getOptionalRenderProp(
    props.eyebrow
  );
  const { text: title, props: titleProps } = getOptionalRenderProp(props.title);
  const { text: subTitle, props: subTitleProps } = getOptionalRenderProp(
    props.subTitle
  );
  const { text: pageTitle, props: pageTitleProps } = getOptionalRenderProp(
    props.pageTitle
  );

  const condense = collapse === 'condense';
  let hideMenuButton = choose(condense, true, props.hideMenuButton);
  const hideBackButton = choose(condense, true, props.hideBackButton);
  const hideCartButton = choose(condense, true, props.hideCartButton);
  const showEndSlotComponent = choose(endSlotComponent, isCollapsed, false);
  let hideLocationSelector = choose(
    isNil(props.hideLocationSelector),
    props.hideMenuButton,
    props.hideLocationSelector
  );

  hideLocationSelector = choose(condense, true, hideLocationSelector);
  const headerTheme = toString(choose(condense, '', theme));
  const hideLocationText = and(!showLocationText, or(condense, isCollapsed));
  const showLocationSelector = and(
    !hideLocationSelector,
    isLocatorAvailable,
    !showEndSlotComponent
  );
  hideMenuButton = choose(
    or(
      and(!condense, isCollapsed, size(headerActions?.options) > 0),
      showEndSlotComponent
    ),
    true,
    hideMenuButton
  );

  useIonViewWillEnter(() => {
    if (headerTheme) {
      void setStatusBarTheme(
        choose(
          headerTheme === HeaderThemeEnum.light,
          StatusBarStyle.Light,
          StatusBarStyle.Dark
        ) as StatusBarStyle
      );
    }
  }, [headerTheme]);

  useEffect(() => {
    const interval = getIntervalRender({
      fn: () => {
        const toolbarContainer =
          toolbarRef?.shadowRoot?.querySelector('.toolbar-container');
        if (toolbarContainer) {
          if (!toolbarContainer?.getAttribute('part')) {
            toolbarContainer?.setAttribute('part', 'container');
          }
          clearInterval(interval);
        }
      },
    });
    return () => {
      clearInterval(interval);
    };
  }, [toolbarRef]);

  return (
    <IonHeader
      ref={nodeRef}
      className={classNames(classes.header, classes[headerTheme], className, {
        [classes.collapsed]: !condense,
      })}
      mode="ios"
      translucent={false}
      collapse={collapse}
      data-testid={testid}
    >
      <IonToolbar ref={setToolbarRef}>
        <div className={classes.container}>
          <div className={classes.wrapper}>
            <IonButtons>
              {ifRender(
                !hideBackButton,
                <Button
                  variant="clear"
                  icon={['far', 'chevron-left']}
                  href={backButton?.href}
                  routerLink={backButton?.href}
                  routerDirection="back"
                  testid="back-button"
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...backButton}
                  className={classNames(
                    classes.backButton,
                    backButton?.className
                  )}
                  onClick={(e) => {
                    if (backButton?.onClick) {
                      backButton?.onClick?.(e);
                      return;
                    }
                    if (!backButton?.href) {
                      goBack();
                    }
                  }}
                />
              )}
            </IonButtons>
            <IonTitle
              className={classNames({
                [classes.topPageTitle]: showLocationText,
              })}
            >
              {ifRender(
                pageTitle,
                <IonRow>
                  <Text
                    variant="mipro-h1-headline"
                    text={pageTitle}
                    testid="pageTitle"
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...pageTitleProps}
                    className={classNames(
                      classes.pageTitle,
                      pageTitleProps?.className
                    )}
                  />
                </IonRow>
              )}
              {ifRender(
                eyebrow,
                <IonRow>
                  <Text
                    variant="mipro-h6-headline"
                    text={eyebrow}
                    testid="eyebrow"
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...eyebrowProps}
                    className={classNames(
                      classes.eyebrow,
                      eyebrowProps?.className
                    )}
                  />
                </IonRow>
              )}
              {ifRender(
                title,
                <IonRow>
                  <Text
                    variant="mipro-h2-headline"
                    text={title}
                    testid="title"
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...titleProps}
                    className={classNames(classes.title, titleProps?.className)}
                  />
                </IonRow>
              )}
              {ifRender(
                subTitle,
                <IonRow>
                  <Text
                    variant="mipro-h6-headline"
                    text={subTitle}
                    testid="subTitle"
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...subTitleProps}
                    className={classNames(
                      classes.subTitle,
                      subTitleProps?.className
                    )}
                  />
                </IonRow>
              )}
              {customTitle}
            </IonTitle>
            <IonButtons>
              {ifRender(
                or(showEndSlotComponent, !hideEndSlotComponent),
                endSlotComponent
              )}
              {ifRender(
                and(!hideCartButton, isMiProApp, isCartAccessible),
                <Button
                  className={classes.cartButton}
                  icon={['far', 'cart-shopping']}
                  href={goToOrderCart(
                    currentCartCustomer?.miLoc,
                    currentCartCustomer?.shipToCustNo,
                    currentCartCustomer?.orderCtlNo
                  )}
                  testid="order-cart-button"
                >
                  {ifRender(
                    toNumber(numberOfCartItems) > 0,
                    <Badge
                      className={classes.badge}
                      text={toString(numberOfCartItems)}
                      type="error"
                      testid="order-cart-badge"
                    />
                  )}
                </Button>
              )}
              {ifRender(
                showLocationSelector,
                <Button
                  variant="link"
                  icon={['fal', 'location-dot']}
                  testid="location-selector"
                  text={choose(!hideLocationText, locationText)}
                  href={locatorURL}
                  textVariant="label-header"
                  className={classNames(
                    classes.cartButton,
                    classes.locationButton
                  )}
                />
              )}
              {ifRender(
                !hideMenuButton,
                <IonMenuToggle
                  className={classes.menuToggle}
                  menu="home-menu"
                  data-testid="menu-toggle"
                >
                  <IonMenuButton
                    className={classes.menuButton}
                    autoHide={false}
                  />
                </IonMenuToggle>
              )}
            </IonButtons>
            <IonButtons
              className={classNames({ [classes.condensedButtons]: condense })}
            >
              {ifRender(
                hideMenuButton,
                map(customEndButtons, (buttonProps, idx) => {
                  const buttonTestid = toString(buttonProps?.testid);
                  return (
                    <Button
                      testid={buttonTestid}
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...buttonProps}
                      key={`${testid}--${idx}`}
                    />
                  );
                })
              )}
              {/* TODO change icon instead to avoid flashing */}
              {ifRender(
                and(hideMenuButton, size(headerActions?.options) > 0),
                <HeaderActions
                  className={classes.headerActions}
                  isOpen={actionsIsOpen}
                  setIsOpen={setActionsIsOpen}
                  testid="header-actions"
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...headerActions}
                />
              )}
            </IonButtons>
          </div>
          {children}
        </div>
      </IonToolbar>
    </IonHeader>
  );
});

export default Header;
