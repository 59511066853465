import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import type { AxiosError } from 'axios';
import { get, head, isEmpty, map, size, toNumber, toString } from 'lodash';
import type { QueryFunctionContext } from '@tanstack/react-query';
import { useInfiniteQuery } from '@tanstack/react-query';
import useAPIUrl from 'api';
import type { QueryParamsType } from 'common/api/utils/useGetQueryFlags';
import { and, choose, or } from 'common/utils/logicHelpers';
import { useGetAvpUser } from 'common/utils/userInfo';
import { getUnixTime } from 'date-fns';
import { useAxios } from 'providers/AxiosProvider';
import type { BaseReportProps, BaseReportRow } from 'ReportsApp/models';
import { doConcatDataPages, useKeyUserId, useMiLocOrTeamId } from 'api/helpers';
import useGetUserConfig from 'api/user/useGetUserConfig';
import type {
  DrillDownItem,
  ReportDrillDownItem,
  SummaryItemOutput,
  WebPerformanceOutput,
} from 'models/Reports';
import type { InfiniteQueryFlags } from 'models/Search';
import { SortDirEnum } from 'models/Sort';
import type { RootState } from 'store/reducers';
import { pageSize } from 'utils/constants';
import useGetLocationCurrency from 'utils/currency';
import { DateFormatEnum, formatDate } from 'utils/date';
import { transformDrillDownRows } from 'utils/reports';
import { mapGroupBy, mapSortField } from './useGetSalesDashboard';

export const getDigitalSalesReportQueryKey = 'digital-sales-report';

interface GetDigitalSalesResponse {
  webPerformance?: WebPerformanceOutput;
  currencyType?: string;
}

interface GetReportDrillDownData {
  totals?: SummaryItemOutput[];
  items: ReportDrillDownItem[];
  summaryData?: GetDigitalSalesResponse;
}

export interface DigitalSalesRow extends BaseReportRow {
  vsOtherGPBasisPointsDiff: number;
  webSalesPercentOfTotalSales: number;
  webRegisteredPercentOfTotalCustomers: number;
  salesPercentOfTotalSales: number;
  camEmployeeId: string;
  camEmployeeName: string;
  corpLoc: string;
  contractFlag: string;
}

interface DigitalSalesSummary extends DigitalSalesRow {
  web: DigitalSalesRow;
}

interface DigitalSalesReportData {
  rows?: DigitalSalesRow[];
  summary?: DigitalSalesSummary;
}

interface UseGetDigitalSalesReportResponse {
  totalRows?: number;
  totalsData?: SummaryItemOutput[];
  drilldownData: ReportDrillDownItem[];
  summaryData?: GetDigitalSalesResponse;
}

interface WebPerformanceReportProps extends BaseReportProps {
  digitalType?: string;
  camEmployeeId?: string;
}

export const getDigitalSalesRow = (
  row: DigitalSalesRow,
  paramsGroupBy?: string,
  isCamUser?: boolean,
  isAvpUser?: boolean
) => {
  let rowId = row.miLoc;
  let rowName = row.miLocName;
  switch (paramsGroupBy) {
    case 'REP':
      rowId = row.repNo;
      rowName = row.repName;
      break;
    case 'CUSTOMER':
      rowId = row.customerNo;
      rowName = row.customerName;
      break;
    case 'NATLACCT':
      rowId = row.nationalAcctNo;
      rowName = `${row.miLoc}: ${row.customerName}`;
      break;
    case 'BRANCH':
    case 'DIVISION':
    case 'GROUP':
      if (or(isCamUser, isAvpUser)) {
        rowId = row.nationalAcctNo;
        rowName = `${row.miLoc}: ${row.miLocName}`;
      }
      break;
    case 'TEAM':
      rowId = row.teamId;
      rowName = row.teamName;
      break;
    case 'CAM':
      rowId = row.camEmployeeId;
      rowName = `${row.corpLoc}: ${row.camEmployeeName}`;
      break;
    default:
  }

  return { rowId, rowName };
};

export const getRowMiLoc = (row: DigitalSalesRow, paramsGroupBy?: string) => {
  return choose(paramsGroupBy === 'CAM', row.corpLoc, row.miLoc) as string;
};

const useGetDigitalSalesReport = ({
  miLoc: propsMiLoc,
  busPeriod,
  requestType = 'MTD',
  groupBy,
  nationalAcctNo,
  sortField = 'name',
  sortDir = SortDirEnum.ASCENDING,
  summaryOnly,
  limit = pageSize(),
  enabled = true,
  sendVirtualTeamId = true,
  territory,
  digitalType = 'WEB',
  camEmployeeId,
  custAcctTypesToShow = 'ALL',
  contractFlag,
}: WebPerformanceReportProps): UseGetDigitalSalesReportResponse &
  InfiniteQueryFlags => {
  const { axios } = useAxios();
  const { getNewDigitalSalesReportAPI } = useAPIUrl();
  const { createQueryKey } = useKeyUserId();
  const { createParams, getURLParams } = useMiLocOrTeamId({
    miLoc: propsMiLoc,
    sendVirtualTeamId,
  });

  const { userInfo, isCamUser } = useSelector((state: RootState) => state.user);
  const userId = get(userInfo, 'userid', '');
  const { data: currencyData } = useGetUserConfig({
    configType: 'currency',
    enabled: enabled && !isEmpty(userId),
  });
  const { secondaryCurrencyType } = useGetLocationCurrency();
  const currencyType = or(currencyData?.currency, secondaryCurrencyType);
  const propsGroupBy = toString(groupBy);
  const paramsGroupBy = or(mapGroupBy[propsGroupBy], propsGroupBy);
  const { isAvpUserWithExecView } = useGetAvpUser();

  const params: QueryParamsType = {
    ...createParams(),
    requestDate: formatDate(busPeriod, DateFormatEnum.reportsDateAPI),
    requestType,
    usdRequested: currencyType === 'USD',
    limit,
    nationalAcctNo,
    showCAMView: or(isCamUser, isAvpUserWithExecView),
    territory,
    camEmployeeId,
    digitalType,
    ...choose(
      summaryOnly,
      choose(isAvpUserWithExecView, { groupBy: 'CAM' }, {}),
      {
        groupBy: paramsGroupBy,
        sortField: mapSortField(sortField, paramsGroupBy),
        sortDir,
      }
    ),
    custAcctTypesToShow: choose(
      !isAvpUserWithExecView,
      custAcctTypesToShow,
      'ALL'
    ),
    contractFlag,
  };

  const doGetDigitalSalesReport = async ({
    pageParam = 1,
    signal,
  }: QueryFunctionContext) => {
    const { data: digitalSalesData } = await axios.get<DigitalSalesReportData>(
      getNewDigitalSalesReportAPI(
        getURLParams({
          ...params,
          // api doesn't support page param
          start: limit * (pageParam - 1),
        })
      ),
      { signal }
    );
    const digitalSalesSummary = digitalSalesData.summary;

    const dataCurrencyType = or(digitalSalesSummary?.currency, currencyType);

    return {
      summaryData: {
        webPerformance: {
          vsOtherGPBasisPointsDiff:
            digitalSalesSummary?.web?.vsOtherGPBasisPointsDiff,
          salesPercentOfTotalSales:
            digitalSalesSummary?.web?.salesPercentOfTotalSales,
          digitalPercentOfTotalSales:
            digitalSalesSummary?.webSalesPercentOfTotalSales,
          sales: [
            {
              Name: 'Sales',
              amount: digitalSalesSummary?.web?.currentSales,
              change:
                digitalSalesSummary?.web?.currentSalesChangeToCurrentBusDay,
            },
            {
              Name: 'Digital Sales',
              amount: digitalSalesSummary?.currentSales,
              change: digitalSalesSummary?.currentSalesChangeToCurrentBusDay,
            },
            {
              Name: 'Avg Daily',
              amount: digitalSalesSummary?.web?.currentAvgDaily,
              change:
                digitalSalesSummary?.web?.currentAvgDailyChangeToCurrentBusDay,
            },
          ],
          profit: [
            {
              Name: 'GP%',
              amount: digitalSalesSummary?.web?.currentGpPercent,
              change:
                digitalSalesSummary?.web?.currentGpPercentChangeToCurrentBusDay,
            },
          ],
        },
        currencyType: dataCurrencyType,
      } as GetDigitalSalesResponse,
      totals: transformDrillDownRows({
        ...digitalSalesSummary,
        currencyType: dataCurrencyType,
        sales: digitalSalesSummary?.currentSales,
        salesChange: digitalSalesSummary?.currentSalesChangeToCurrentBusDay,
        transactions: digitalSalesSummary?.currentOrders,
        transactionsChange:
          digitalSalesSummary?.currentOrdersChangeToCurrentBusDay,
        avgDaily: digitalSalesSummary?.currentAvgDaily,
        avgDailyChange:
          digitalSalesSummary?.currentAvgDailyChangeToCurrentBusDay,
        gpAmount: digitalSalesSummary?.currentGp,
        gpChange: digitalSalesSummary?.currentGpChangeToCurrentBusDay,
        gpPercentAmount: digitalSalesSummary?.currentGpPercent,
        gpPercentChange:
          digitalSalesSummary?.currentGpPercentChangeToCurrentBusDay,
        webPercentOfTotalSales:
          digitalSalesSummary?.webSalesPercentOfTotalSales,
        activeWebRegistered:
          digitalSalesSummary?.webRegisteredPercentOfTotalCustomers,
      } as unknown as DrillDownItem),
      items: map(digitalSalesData.rows, (row) => {
        const { rowId, rowName } = getDigitalSalesRow(
          row,
          paramsGroupBy,
          isCamUser,
          isAvpUserWithExecView
        );
        return {
          miLoc: getRowMiLoc(row, paramsGroupBy),
          id: rowId,
          Name: rowName,
          disabledDrilldown: row.disabled,
          items: transformDrillDownRows({
            ...row,
            currencyType: or(row.currency, dataCurrencyType),
            sales: row.currentSales,
            salesChange: row.currentSalesChangeToCurrentBusDay,
            transactions: row.currentOrders,
            transactionsChange: row.currentOrdersChangeToCurrentBusDay,
            avgDaily: row.currentAvgDaily,
            avgDailyChange: row.currentAvgDailyChangeToCurrentBusDay,
            gpAmount: row.currentGp,
            gpChange: row.currentGpChangeToCurrentBusDay,
            gpPercentAmount: row.currentGpPercent,
            gpPercentChange: row.currentGpPercentChangeToCurrentBusDay,
            webPercentOfTotalSales: row.webSalesPercentOfTotalSales,
            activeWebRegistered: row?.webRegisteredPercentOfTotalCustomers,
            contractFlag: row?.contractFlag,
          } as unknown as DrillDownItem),
        };
      }),
    };
  };

  const response = useInfiniteQuery<GetReportDrillDownData, AxiosError>(
    createQueryKey(getDigitalSalesReportQueryKey, params),
    doGetDigitalSalesReport,
    {
      enabled: enabled && !isEmpty(userId),
      // TUDU: refactor and extract this retry logic to be resuable across the application
      retry: (failureCount, err) =>
        err.response?.status === 403 ? false : failureCount >= 3,
      getNextPageParam: (lastPage, pages) =>
        choose(size(lastPage.items) < limit, false, size(pages) + 1),
    }
  );

  const {
    data,
    error,
    status,
    hasNextPage,
    isFetchingNextPage,
    dataUpdatedAt,
    refetch,
    fetchNextPage,
    fetchStatus,
  } = response;

  const drilldownData = useMemo(
    () =>
      doConcatDataPages<ReportDrillDownItem, GetReportDrillDownData>(
        data,
        'items'
      ),
    [data]
  );

  // TUDU use useGetQueryFlags instead
  const hasItems = size(drilldownData) > 0;
  const hasError = status === 'error';
  const isEmptyResponse = and(status === 'success', !hasNextPage, !hasItems);
  const noMoreData = and(status === 'success', !hasNextPage, hasItems);
  const showLoader = fetchStatus === 'fetching';
  const enableInfiniteScroll = !or(!hasNextPage, isFetchingNextPage);
  let lastUpdatedAt = new Date();
  if (toNumber(dataUpdatedAt) > 0) {
    lastUpdatedAt = new Date(dataUpdatedAt);
  }

  return {
    drilldownData,
    summaryData: head(data?.pages)?.summaryData,
    refetch: async () => {
      await refetch();
    },
    fetchNextPage: async () => {
      await fetchNextPage();
    },
    error: error as AxiosError,
    hasError,
    showLoader,
    isEmptyResponse,
    noMoreData,
    enableInfiniteScroll,
    lastUpdatedAt: getUnixTime(lastUpdatedAt),
    totalsData: head(data?.pages)?.totals,
  };
};

export default useGetDigitalSalesReport;
