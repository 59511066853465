import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import type { AxiosError } from 'axios';
import { get, head, isEmpty, map, size, toString } from 'lodash';
import { useInfiniteQuery } from '@tanstack/react-query';
import useAPIUrl from 'api';
import type {
  QueryFnProps,
  QueryParamsType,
} from 'common/api/utils/useGetQueryFlags';
import { getUnixTime, startOfToday } from 'date-fns';
import { useAxios } from 'providers/AxiosProvider';
import {
  doConcatDataPages,
  doPromiseAPI,
  useKeyUserId,
  useMiLocOrTeamId,
} from 'api/helpers';
import useGetUserConfig from 'api/user/useGetUserConfig';
import type {
  DataType,
  DateSegmentType,
  GetReportDrillDownAPIResponse,
  ReportDrillDownItem,
  RoleGroupType,
  SummaryItemOutput,
  ViewAsRoleType,
} from 'models/Reports';
import type { InfiniteQueryFlags } from 'models/Search';
import { SortDirEnum, SortFieldEnum } from 'models/Sort';
import type { RootState } from 'store/reducers';
import { pageSize } from 'utils/constants';
import useGetLocationCurrency from 'utils/currency';
import { DateFormatEnum, formatDate } from 'utils/date';
import {
  transformDrillDownRows,
  transformDrillDownTotalRows,
} from 'utils/reports';

export const getReportDrillDownQueryKey = 'report-drilldown';

export interface UseGetReportDrillDownProps {
  miLoc: string;
  id?: string;
  pgc1?: string;
  territory?: string;
  busPeriod?: number;
  requestType?: DateSegmentType;
  viewAsRole: ViewAsRoleType;
  groupBy: RoleGroupType;
  dataType?: DataType;
  sortField?: SortFieldEnum;
  sortDir?: SortDirEnum;
  limit?: number;
  pickTwelve?: boolean;
  locationPick12?: boolean;
  enabled: boolean;
  showZeroSalesPGC?: boolean;
  includeDailyTotal?: boolean;
  sendVirtualTeamId?: boolean;
  includeWebPerfData?: boolean;
  custAcctTypesToShow?: string;
}

interface GetReportDrillDownData {
  totals?: SummaryItemOutput[];
  items: ReportDrillDownItem[];
}

export interface UseGetReportDrillDownResponse {
  totalsData?: SummaryItemOutput[];
  drilldownData: ReportDrillDownItem[];
  totalRows?: number;
}

const useGetReportDrillDown = ({
  miLoc: itemMiLoc,
  id,
  pgc1,
  territory,
  busPeriod = getUnixTime(startOfToday()),
  requestType = 'MTD',
  viewAsRole,
  groupBy,
  dataType = 'SGP',
  sortField = SortFieldEnum.name,
  sortDir = SortDirEnum.ASCENDING,
  limit = pageSize(),
  pickTwelve,
  locationPick12,
  enabled,
  showZeroSalesPGC,
  includeDailyTotal,
  sendVirtualTeamId,
  includeWebPerfData,
}: UseGetReportDrillDownProps): UseGetReportDrillDownResponse &
  InfiniteQueryFlags => {
  const { axios } = useAxios();
  const { getReportDrillDownAPI } = useAPIUrl();
  const { createQueryKey } = useKeyUserId();
  const { createParams, getURLParams } = useMiLocOrTeamId({
    miLoc: itemMiLoc,
    sendVirtualTeamId,
  });
  const { userInfo } = useSelector((state: RootState) => state.user);
  const userId = get(userInfo, 'userid', '');
  const { data: currencyData } = useGetUserConfig({
    configType: 'currency',
    enabled: enabled && !isEmpty(userId),
  });
  const { secondaryCurrencyType } = useGetLocationCurrency();

  const currencyType = currencyData?.currency || secondaryCurrencyType;

  const params: QueryParamsType = {
    ...createParams(),
    busPeriod: formatDate(busPeriod, DateFormatEnum.reportsDateAPI),
    requestType,
    viewAsRole,
    groupBy,
    dataType,
    sortField,
    sortDir,
    limit,
  };
  if (id) {
    params.customerNo = id;
  }
  if (territory) {
    params.territory = territory;
  }
  if (pgc1) {
    params.pgc1 = pgc1;
  }
  if (pickTwelve && !(id || territory || pgc1)) {
    // TODO: toString not needed with new typing
    params.pick12 = toString(true);
  }
  if (locationPick12) {
    params.locationPick12 = toString(true);
  }
  if (showZeroSalesPGC) {
    params.showZeroSalesPGC = toString(true);
  }
  if (includeDailyTotal) {
    params.includeDailyTotal = toString(true);
  }
  if (includeWebPerfData) {
    params.includeWebPerfData = toString(true);
  }
  if (currencyType) {
    params.currencyType = currencyType;
  }

  const doGetReportDrillDown = ({ pageParam = 1, signal }: QueryFnProps) => {
    return doPromiseAPI<GetReportDrillDownData>(async () => {
      const { data } = await axios.get<GetReportDrillDownAPIResponse>(
        getReportDrillDownAPI(
          getURLParams({
            ...params,
            // api doesn't support page param
            start: limit * (pageParam - 1),
          })
        ),
        { signal }
      );

      return {
        totals: transformDrillDownTotalRows(data.totals),
        items: map(data.rows, (row) => ({
          miLoc: row.miLoc,
          id: row.nameCd,
          Name: row.name,
          items: transformDrillDownRows(row),
          customerPick12: row.customerPick12,
        })),
      };
    });
  };

  // TODO reps data don't have a get api hook, we may need to add one later
  const response = useInfiniteQuery<GetReportDrillDownData, AxiosError>(
    createQueryKey(getReportDrillDownQueryKey, params),
    doGetReportDrillDown,
    {
      enabled,
      // TODO: refactor and extract this retry logic to be resuable across the application
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      retry: (failureCount: number, err: AxiosError<any>) =>
        err.response?.status === 403 ? false : !(failureCount < 3),
      getNextPageParam: (lastPage, pages) =>
        size(lastPage.items) < limit ? false : size(pages) + 1,
    }
  );

  const {
    data,
    error,
    status,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    refetch,
  } = response;

  const drilldownData = useMemo(
    () =>
      doConcatDataPages<ReportDrillDownItem, GetReportDrillDownData>(
        data,
        'items'
      ),
    [data]
  );

  const hasItems = size(drilldownData) > 0;
  const hasError = status === 'error';
  const isEmptyResponse = status === 'success' && !hasNextPage && !hasItems;
  const noMoreData = status === 'success' && !hasNextPage && hasItems;
  const showLoader = status === 'loading' || isFetchingNextPage;
  const enableInfiniteScroll = !(!hasNextPage || isFetchingNextPage);

  return {
    refetch: async () => {
      await refetch();
    },
    fetchNextPage: async () => {
      await fetchNextPage();
    },
    totalsData: head(data?.pages)?.totals,
    drilldownData,
    error,
    hasError,
    showLoader,
    isEmptyResponse,
    noMoreData,
    enableInfiniteScroll,
  };
};

export default useGetReportDrillDown;
