import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import type { AxiosError } from 'axios';
import { find, isEmpty, isNil } from 'lodash';
import { useIonViewDidEnter } from '@ionic/react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import useAPIUrl from 'api';
import { useAxios } from 'providers/AxiosProvider';
import type { SalesReportRow } from 'ReportsApp/api/useGetSalesDashboard';
import { getSalesDashboardQueryKey } from 'ReportsApp/api/useGetSalesDashboard';
import { getSalesReportQueryKey } from 'ReportsApp/api/useGetSalesReport';
import {
  doGetIsLoading,
  doPromiseAPI,
  getPlaceholderData,
  useKeyUserId,
} from 'api/helpers';
import { findPickTwelveQueryKey } from 'api/salesReports/useFindPickTwelve';
import { getReportDrillDownQueryKey } from 'api/salesReports/useGetReportDrillDown';
import type { Customer } from 'models/Customer';
import type { PickTwelve } from 'models/PickTwelve';
import type { ReportDrillDownItem } from 'models/Reports';
import type { QueryFlags, SearchItem, SearchItemType } from 'models/Search';
import type { RootState } from 'store/reducers';
import { getIsCorpAccount } from 'utils/search';
import { findCustomersQueryKey } from './useFindCustomers';
import { findFavoriteCustomersQueryKey } from './useFindFavoriteCustomers';

export const getCustomerQueryKey = 'customer';

interface UseGetCustomerProps {
  searchType: SearchItemType;
  miLoc: string;
  id: string;
  invalidateQuery?: boolean;
  enabled?: boolean;
}

interface UseGetCustomerResponse {
  data?: Customer;
  isCorp?: boolean;
}

const useGetCustomer = ({
  searchType,
  miLoc,
  id,
  invalidateQuery = false,
  enabled: pEnabled = true,
}: UseGetCustomerProps): UseGetCustomerResponse & QueryFlags => {
  const { axios } = useAxios();
  const { getCustomerAPI } = useAPIUrl();
  const queryClient = useQueryClient();
  const { createQueryKey } = useKeyUserId();
  const { recentlyViewed } = useSelector((state: RootState) => state.user);

  const doGetCustomer = () => {
    return doPromiseAPI<Customer>(async (cancelToken) => {
      const { data } = await axios.get<Customer>(getCustomerAPI(miLoc, id), {
        cancelToken,
      });
      return data;
    });
  };

  const enabled =
    pEnabled &&
    searchType === 'customer' &&
    !isEmpty(miLoc) &&
    !isEmpty(id) &&
    miLoc.length === 4;

  const response = useQuery<Customer, AxiosError>(
    createQueryKey(getCustomerQueryKey, { miLoc, id }),
    doGetCustomer,
    {
      enabled,
      placeholderData: useMemo(() => {
        const globalCustomer = getPlaceholderData<Customer>({
          queryClient,
          queryKeyRegExp: /customers/,
          objectKey: 'items',
          findPredicate: { miLoc, customerNo: id },
        });
        if (globalCustomer) {
          return globalCustomer;
        }
        // look for customer in favorites
        const favoriteCustomer = getPlaceholderData<SearchItem>({
          queryClient,
          queryKey: findFavoriteCustomersQueryKey,
          objectKey: 'items',
          findPredicate: { miLoc, id },
        });
        if (!isNil(favoriteCustomer)) {
          return {
            miLoc,
            customerNo: id,
            name: favoriteCustomer.text,
            isBookmarked: true,
          };
        }
        // look for customer in pick12 list
        const pickTwelveCustomer = getPlaceholderData<PickTwelve>({
          queryClient,
          queryKey: findPickTwelveQueryKey,
          findPredicate: { miLoc, custNo: id },
        });
        if (!isNil(pickTwelveCustomer)) {
          return { miLoc, customerNo: id, name: pickTwelveCustomer.custName };
        }
        // look for customer in drilldown data list
        const drilldownCustomer = getPlaceholderData<ReportDrillDownItem>({
          queryClient,
          queryKey: getReportDrillDownQueryKey,
          findPredicate: { miLoc, id },
          objectKey: 'items',
        });
        if (!isNil(drilldownCustomer)) {
          return {
            miLoc,
            customerNo: drilldownCustomer.id,
            name: drilldownCustomer.Name,
          };
        }
        // look for customer in new drilldown data list
        const newDrilldownCustomer = getPlaceholderData<ReportDrillDownItem>({
          queryClient,
          queryKey: getSalesReportQueryKey,
          findPredicate: { miLoc, id },
          objectKey: 'items',
        });
        if (!isNil(newDrilldownCustomer)) {
          return {
            miLoc,
            customerNo: newDrilldownCustomer.id,
            name: newDrilldownCustomer.Name,
          };
        }
        // look for customer in sales report data
        const salesReportCustomer = getPlaceholderData<SalesReportRow>({
          queryClient,
          queryKey: getSalesDashboardQueryKey,
          findPredicate: { miLoc, customerNo: id },
          objectKey: 'rows',
        });

        if (!isNil(salesReportCustomer)) {
          return {
            miLoc,
            customerNo: salesReportCustomer.customerNo,
            name: salesReportCustomer.customerName,
          };
        }
        // look for customer in search results
        const searchCustomer = getPlaceholderData<SearchItem>({
          queryClient,
          queryKey: findCustomersQueryKey,
          objectKey: 'items',
          findPredicate: { miLoc, id },
        });
        if (!isNil(searchCustomer)) {
          return {
            miLoc,
            customerNo: id,
            name: searchCustomer.text,
            natlAcctNo: searchCustomer.natlAcctNo,
            address: searchCustomer.address,
          };
        }
        // look for customer in recently viewed
        const recentCustomer = find(recentlyViewed, {
          type: 'customer',
          miLoc,
          id,
        });
        if (!isNil(recentCustomer)) {
          return {
            miLoc,
            customerNo: id,
            name: recentCustomer.text,
          };
        }
        // no customer found in query cache
        return undefined;
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [id, miLoc, recentlyViewed]),
    }
  );

  const { data, error, refetch } = response;

  useIonViewDidEnter(() => {
    if (invalidateQuery && enabled) {
      void refetch();
    }
  });

  return {
    data,
    isCorp: getIsCorpAccount(data?.customerNo, data?.natlAcctNo),
    error,
    isLoading: doGetIsLoading({ ...response, dataPath: 'address' }),
    refetch: async () => {
      await refetch();
    },
  };
};

export default useGetCustomer;
