export enum SalesOpportunityEnum {
  salesOpportunity = 'SalesOpportunity',
}

export enum SalesOpportunityIconEnum {
  salesOpportunity = 'hand-holding-dollar',
}

export enum SalesOpportunityColorEnum {
  salesOpportunity = 'color-salesOpportunity',
}

export interface Task {
  estimatedValue: number;
  estimatedCloseDate: string;
  currency: string;
  creationUserId: string;
  customerNo: string;
  historyId: string;
  miLoc: string;
}
