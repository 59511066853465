import React from 'react';
import { map, filter } from 'lodash';
import type { ContactModalProps } from 'common/components/ContactModal/ContactModal';
import { and } from 'common/utils/logicHelpers';
import type { Employee } from 'models/Employee';
import classes from './ShareWithTeamHelpButton.module.scss';
import TeamMemberListItem from './TeamMemberListItem';

interface TeamMembersListProps {
  teamList?: Employee[];
  loggedInUserEmpNo: string;
  setIsContactModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setContactModalData: React.Dispatch<
    React.SetStateAction<Partial<ContactModalProps>>
  >;
  testId: string;
}
const TeamMembersList = (props: TeamMembersListProps) => {
  const {
    teamList,
    loggedInUserEmpNo,
    setContactModalData,
    setIsContactModalOpen,
    testId,
  } = props;

  return (
    <ul className={classes.teamList} data-testid={`${testId}-list`}>
      {map(
        filter(teamList, (employee) =>
          and(employee.id !== loggedInUserEmpNo, employee.assignedRole !== '')
        ),
        ({
          title,
          id,
          name,
          name_empDisplayName: empDisplayName,
          mobilePhone,
          workPhone,
          email,
        }) => (
          <TeamMemberListItem
            testId={`${empDisplayName}-${id}`}
            key={`${empDisplayName}-${id}`}
            jobTitle={title}
            name={name}
            empDisplayName={empDisplayName}
            mobilePhone={mobilePhone}
            workPhone={workPhone}
            email={email}
            setContactModalData={setContactModalData}
            setIsContactModalOpen={setIsContactModalOpen}
          />
        )
      )}
    </ul>
  );
};

export default TeamMembersList;
