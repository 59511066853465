import React from 'react';
import classNames from 'classnames';
import type { Dictionary } from 'lodash';
import { toNumber, toString } from 'lodash';
import CrmTaskForm from 'ActivitiesApp/components/CrmTaskForm/CrmTaskForm';
import {
  SalesOpportunityColorEnum,
  SalesOpportunityEnum,
  SalesOpportunityIconEnum,
} from 'ActivitiesApp/models/SalesOpportunity';
import { isNonBlankEstimatedCloseDate } from 'ActivitiesApp/utils/helpers';
import { choose, ifRender } from 'common/utils/logicHelpers';
import { formatNumber } from 'common/utils/numberHelper';
import { formatCardDate } from 'utils/date';
import type {
  ActionCardConfig,
  ActivityTypeConfig,
  GetActivityConfigExtendedProps,
  GetActivityTypeDataProps,
} from 'pages/Activities/ActivityActionCard/ActivityCardConfig';
import classes from 'components/Activities/ActionCard/ActionCard.module.scss';
import Badge from 'components/Badge/Badge';

export const getSalesOpportunityData = ({
  eventTagName,
  t,
}: GetActivityTypeDataProps): ActivityTypeConfig | undefined => {
  if (eventTagName === SalesOpportunityEnum.salesOpportunity) {
    return {
      cardType: SalesOpportunityEnum.salesOpportunity,
      title: t('salesOpportunity'),
      icon: SalesOpportunityIconEnum.salesOpportunity,
      color: SalesOpportunityColorEnum.salesOpportunity,
    };
  }

  return undefined;
};

export const getSalesOpportunityConfig = ({
  activityVersion,
  activityData,
  defaultFirstLine,
  t,
}: GetActivityConfigExtendedProps): ActionCardConfig | undefined => {
  const { commentCount, extendedInfo } = activityData;
  const hasComments = toNumber(commentCount) > 0;
  const estimatedCloseDate = extendedInfo?.estimatedCloseDate;
  const estimatedValue = extendedInfo?.estimatedValue;
  const currency = extendedInfo?.currency as string;

  const estValue = formatNumber({
    number: toNumber(estimatedValue),
    currencyType: currency,
  });

  const commentLines = [
    {
      description: '',
      badge: (
        <div className={classes.badgeRow}>
          {ifRender(
            hasComments,
            <Badge
              type="info"
              icon="comment"
              text={t('comments', {
                notesCount: commentCount,
                count: toNumber(commentCount),
              })}
              testid="on-hold"
              textVariant="content-smaller"
              iconVariant="far"
              className={classes.badge}
              textClassName={classNames(
                classes.linePrefixText,
                classes.commentText
              )}
              iconClassName={classNames(classes.prefixIcon, classes.comment)}
            />
          )}
        </div>
      ),
    },
  ];

  if (activityVersion === `${SalesOpportunityEnum.salesOpportunity}-1`) {
    return {
      card: {
        defaultTitle: t('salesOpportunity'),
        highlight: [{ query: t('salesOpportunity') }],
        body: {
          description: '',
          lines: [
            ...defaultFirstLine,
            { description: toString(extendedInfo?.subject) },
            {
              description: choose(
                isNonBlankEstimatedCloseDate(toString(estimatedCloseDate)),
                t('estimatedCloseDate', {
                  estimatedCloseDate: formatCardDate(
                    toString(estimatedCloseDate),
                    false,
                    false
                  ),
                }),
                ''
              ) as string,
              highlight: t('estimatedCloseDate'),
              followUpDescription: estimatedValue
                ? t('activities:crmActivityEst', { estValue })
                : '',
              followUpHighlight: t('activities:crmActivityEst'),
            },
            ...commentLines,
          ],
        },
      },
      hasComments: true,
      modal: {
        title: t('salesOpportunity'),
        customContent: (props: Dictionary<unknown>) => (
          <CrmTaskForm
            activity={activityData}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
          />
        ),
      },
    };
  }
  return undefined;
};
