import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { filter, includes } from 'lodash';
import type { IonicReactProps } from '@ionic/react/dist/types/components/IonicReactProps';
import { and } from 'common/utils/logicHelpers';
import { useGetAvpUser } from 'common/utils/userInfo';
import { DrillDownFieldsEnum } from 'models/Reports';
import type { RootState } from 'store/reducers';
import TableDrillDown from 'pages/Reports/DrillDown/TableDrillDown';
import MIIcon from 'assets/MIIcon.svg';

export const WebSalesDrillDownFields = [
  {
    key: DrillDownFieldsEnum.Sales,
    id: 'sales',
  },
  {
    key: DrillDownFieldsEnum.GP,
    id: 'gpAmount',
  },
  {
    key: DrillDownFieldsEnum['GP %'],
    id: 'gpPercentAmount',
  },
  {
    key: DrillDownFieldsEnum['% of Total Sales'],
    id: 'webPercentOfTotalSales',
  },
  {
    key: DrillDownFieldsEnum['Yoy Growth'],
    id: 'salesChange',
  },
  {
    key: DrillDownFieldsEnum['Avg Daily'],
    id: 'avgDaily',
  },
  {
    key: DrillDownFieldsEnum['% Active WB'],
    id: 'activeWebRegistered',
  },
  {
    key: DrillDownFieldsEnum['Contract Type'],
    id: 'contractFlag',
    type: 'string',
  },
];

const WebSalesTableDrillDown = (
  props: IonicReactProps & { isContractFilter: boolean } & Omit<
      React.ComponentProps<typeof TableDrillDown>,
      'headerText' | 'fields' | 'customHeader'
    >
): JSX.Element => {
  const { t } = useTranslation();
  const { isCamUser } = useSelector((state: RootState) => state.user);
  let { title } = { ...props };
  const { miLoc, selectedGroupBy, endSlotComponent, isContractFilter } = {
    ...props,
  };

  const { isAvpUserWithExecView } = useGetAvpUser();

  if (and(isCamUser, miLoc === 'EXEC')) {
    title = t('reports:myCorporateAccounts');
  }

  if (
    and(
      isAvpUserWithExecView,
      includes(['CAM', 'NATLACCT'], selectedGroupBy.key)
    )
  ) {
    title = t('reports:myCorporateAccounts');
  }

  return (
    <TableDrillDown
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      fields={
        isContractFilter && selectedGroupBy.key !== 'CAM'
          ? WebSalesDrillDownFields
          : filter(
              WebSalesDrillDownFields,
              (item) => item.id !== 'contractFlag'
            )
      }
      headerText={t('reports:webSales')}
      headerImage={MIIcon}
      title={title}
      endSlotComponent={endSlotComponent}
    />
  );
};

export default WebSalesTableDrillDown;
