import { useTranslation } from 'react-i18next';
import type { AxiosError } from 'axios';
import type { Dictionary } from 'lodash';
import {
  toNumber,
  isNumber,
  isNil,
  map,
  size,
  forEach,
  includes,
} from 'lodash';
import { isToday } from 'date-fns';
import { namespaces } from 'i18n/i18n.constants';
import useFeatureFlags, { FeatureFlagType } from 'hooks/useFeatureFlags';
import type { ChartData, LineChartData, LineKeys } from 'models/Charts';
import type {
  DataResponse,
  DateSegmentType,
  DrillDownItem,
  DrilldownTotals,
  SummaryItemInput,
  SummaryItemOutput,
  TrendData,
} from 'models/Reports';
import { DrillDownFieldsEnum } from 'models/Reports';
import { parseDate } from './date';

export const getTrendData = (
  // eslint-disable-next-line @typescript-eslint/default-param-last
  collection: TrendData[] = [],
  collectionKey: LineKeys,
  id: string,
  removeNullValue = false
): LineChartData[] => {
  if (size(collection) > 0) {
    let data: ChartData[] = [];

    if (removeNullValue) {
      let count = 0;
      forEach(collection, ({ [collectionKey]: newData }) => {
        const yAxisValue = isNumber(newData) ? newData : 0;
        if (yAxisValue) {
          count += 1;
          data.push({ x: count, y: yAxisValue });
        }
      });
    } else {
      data = map(collection, ({ date, [collectionKey]: newData }) => ({
        x: date,
        y: isNumber(newData) ? newData : 0,
      }));
    }

    return [{ id, color: 'hsla(3, 70%, 0%, 0.8)', data }];
  }
  return [];
};

export const transformSummaryRows = (
  sales: SummaryItemOutput[],
  profit: SummaryItemOutput[],
  row: SummaryItemInput,
  trend: TrendData[] = [],
  removeNullValue = false,
  currencyType?: string
): void => {
  switch (row.Name) {
    case 'GP':
      profit.push({
        Name: row.Name,
        amount: row.amount,
        change: row.change,
        trend: getTrendData(trend, 'gp', 'profit'),
        currencyType,
      });
      break;
    case 'GP%':
      profit.push({
        Name: 'GP %',
        amount: row.amount,
        change: row.change,
        trend: getTrendData(trend, 'gpPercent', 'profit'),
      });
      break;
    case 'Sales':
      sales.push({
        Name: row.Name,
        amount: row.amount,
        change: row.change,
        trend: getTrendData(trend, 'dollars', 'sales', removeNullValue),
        currencyType,
      });
      break;
    case 'Transactions':
      sales.push({
        Name: 'Orders',
        amount: row.amount,
        change: row.change,
        trend: getTrendData(trend, 'quantity', 'sales'),
      });
      break;
    case 'Avg Daily':
      sales.push({
        Name: row.Name,
        amount: row.amount,
        change: row.change,
        trend: [],
        currencyType,
      });
      break;
    case 'Unbilled GP':
      profit.push({
        Name: row.Name,
        amount: row.amount,
        change: row.change,
        trend: [],
        currencyType,
      });
      break;
    case 'Unbilled GP%':
      profit.push({
        Name: row.Name,
        amount: row.amount,
        change: row.change,
        trend: [],
      });
      break;
    case 'Unbilled':
      sales.push({
        Name: row.Name,
        amount: row.amount,
        change: row.change,
        trend: [],
      });
      break;
    default:
      sales.push({
        Name: row.Name,
        amount: row.amount,
        change: row.change,
        trend: [],
      });
      break;
  }
};

export const transformDrillDownRows = (
  row: DrillDownItem
): SummaryItemOutput[] => {
  const items: SummaryItemOutput[] = [];
  if (!isNil(row.sales) && !isNil(row.salesChange)) {
    items.push({
      Name: DrillDownFieldsEnum.Sales,
      amount: row.sales,
      change: row.salesChange,
      trend: getTrendData(row.salesTrend, 'dollars', 'sales'),
      currencyType: row.currencyType,
    });
  }
  if (!isNil(row.transactions) && !isNil(row.transactionsChange)) {
    items.push({
      Name: DrillDownFieldsEnum.Orders,
      amount: row.transactions,
      change: row.transactionsChange,
      trend: getTrendData(row.salesTrend, 'quantity', 'sales'),
    });
  }
  if (!isNil(row.avgDaily) && !isNil(row.avgDailyChange)) {
    items.push({
      Name: DrillDownFieldsEnum['Avg Daily'],
      amount: row.avgDaily,
      change: row.avgDailyChange,
      currencyType: row.currencyType,
    });
  }
  if (!isNil(row.gpAmount) && !isNil(row.gpChange)) {
    items.push({
      Name: DrillDownFieldsEnum.GP,
      amount: row.gpAmount,
      change: row.gpChange,
      trend: getTrendData(row.profitTrend, 'gp', 'profit'),
      currencyType: row.currencyType,
    });
  }
  if (!isNil(row.gpPercentAmount) && !isNil(row.gpPercentChange)) {
    items.push({
      Name: DrillDownFieldsEnum['GP %'],
      amount: row.gpPercentAmount,
      change: row.gpPercentChange,
      trend: getTrendData(row.profitTrend, 'gpPercent', 'profit'),
    });
  }
  if (!isNil(row.pick12CustomerCount)) {
    items.push({
      Name: DrillDownFieldsEnum['Pick12 Count'],
      amount: row.pick12CustomerCount,
      change: 0,
    });
  }
  if (!isNil(row.totalPick12CustCount)) {
    items.push({
      Name: DrillDownFieldsEnum['Total Pick12 Count'],
      amount: row.totalPick12CustCount,
      change: 0,
    });
  }
  if (!isNil(row.pick12Pct)) {
    items.push({
      Name: DrillDownFieldsEnum['Pick12 %'],
      amount: row.pick12Pct,
      change: 0,
    });
  }
  if (!isNil(row.unbilled)) {
    items.push({
      Name: 'Unbilled',
      amount: row.unbilled,
      change: 0,
    });
  }
  if (!isNil(row.avgSalesPerAcct)) {
    items.push({
      Name: DrillDownFieldsEnum['Avg Sales Acct'],
      amount: row.avgSalesPerAcct,
      change: 0,
      currencyType: row.currencyType,
    });
  }
  if (!isNil(row.previousSales)) {
    items.push({
      Name: DrillDownFieldsEnum['Last Year Sales'],
      amount: row.previousSales,
      change: 0,
      currencyType: row.currencyType,
    });
  }
  if (!isNil(row.salesChange)) {
    items.push({
      Name: DrillDownFieldsEnum['Yoy Growth'],
      amount: row.salesChange,
      change: 0,
    });
  }
  if (!isNil(row.activeWebRegistered)) {
    items.push({
      Name: DrillDownFieldsEnum['% Active WB'],
      amount: toNumber(row.activeWebRegistered),
      change: 0,
    });
  }
  if (!isNil(row.webPercentOfTotalSales)) {
    items.push({
      Name: DrillDownFieldsEnum['% of Total Sales'],
      amount: toNumber(row.webPercentOfTotalSales),
      change: 0,
    });
  }
  if (!isNil(row.effectiveDate)) {
    items.push({
      Name: DrillDownFieldsEnum['Effective Date'],
      amount: 0,
      change: 0,
      text: row.effectiveDate,
    });
  }
  if (!isNil(row.expirationDate)) {
    items.push({
      Name: DrillDownFieldsEnum['Expiration Date'],
      amount: 0,
      change: 0,
      text: row.expirationDate,
    });
  }
  if (!isNil(row.contractFlag)) {
    items.push({
      Name: DrillDownFieldsEnum['Contract Type'],
      text: row.contractFlag,
      amount: 0,
      change: 0,
    });
  }
  return items;
};

export const transformCostSavingsDrillDownRows = (
  row: DrillDownItem,
  currencyType?: string
): SummaryItemOutput[] => {
  const items: SummaryItemOutput[] = [];
  if (!isNil(row.sales)) {
    items.push({
      Name: DrillDownFieldsEnum.Sales,
      amount: row.sales,
      change: 0,
      currencyType,
    });
  }
  if (!isNil(row.overage)) {
    items.push({
      Name: DrillDownFieldsEnum.Overage,
      amount: toNumber(row.overage),
      change: 0,
      currencyType,
    });
  }
  if (!isNil(row.commitCurrentSales)) {
    items.push({
      Name: DrillDownFieldsEnum.Commit,
      amount: toNumber(row.commitCurrentSales),
      change: 0,
      currencyType,
    });
  }
  if (!isNil(row.commitPercentage)) {
    items.push({
      Name: DrillDownFieldsEnum['Commit %'],
      amount: toNumber(row.commitPercentage),
      change: 0,
    });
  }
  if (!isNil(row.projectedSales)) {
    items.push({
      Name: DrillDownFieldsEnum.Projected,
      amount: toNumber(row.projectedSales),
      change: 0,
      currencyType,
    });
  }
  if (!isNil(row.approvedSales)) {
    items.push({
      Name: DrillDownFieldsEnum.Approved,
      amount: toNumber(row.approvedSales),
      change: 0,
      currencyType,
    });
  }
  if (!isNil(row.pendingSales)) {
    items.push({
      Name: DrillDownFieldsEnum.Pending,
      amount: toNumber(row.pendingSales),
      change: 0,
      currencyType,
    });
  }
  if (!isNil(row.guaranteed)) {
    items.push({
      Name: DrillDownFieldsEnum['Guaranteed Paid'],
      amount: 0,
      change: 0,
      text: row.guaranteed,
    });
  }
  return items;
};

export const transformDrillDownTotalRows = (
  row?: DrilldownTotals,
  currencyType?: string
): SummaryItemOutput[] => {
  const items: SummaryItemOutput[] = [];
  const definition: Dictionary<number> = {
    [DrillDownFieldsEnum.Sales]: toNumber(row?.sales),
    [DrillDownFieldsEnum.GP]: toNumber(row?.gp),
    [DrillDownFieldsEnum['GP %']]: toNumber(row?.gpPercent),
    [DrillDownFieldsEnum['Pick12 Count']]: toNumber(row?.activePicks),
    [DrillDownFieldsEnum['Total Pick12 Count']]: toNumber(row?.picks),
    [DrillDownFieldsEnum['Pick12 %']]: toNumber(row?.activePickPercent),
    [DrillDownFieldsEnum['Avg Sales Acct']]: toNumber(row?.avgSalesPick),
    [DrillDownFieldsEnum['Last Year Sales']]: toNumber(row?.salesPrevious),
    [DrillDownFieldsEnum['Yoy Growth']]: toNumber(row?.salesYoyPercent),
    [DrillDownFieldsEnum['% of Total Sales']]: toNumber(
      row?.webPercentOfTotalSales
    ),
    [DrillDownFieldsEnum['% Active WB']]: toNumber(row?.activeWebRegistered),
    [DrillDownFieldsEnum['Avg Daily']]: toNumber(row?.avgDaily),
    [DrillDownFieldsEnum.Commit]: toNumber(row?.commitCurrentSales),
    [DrillDownFieldsEnum.Approved]: toNumber(row?.approvedSales),
    [DrillDownFieldsEnum.Pending]: toNumber(row?.pendingSales),
    [DrillDownFieldsEnum.Projected]: toNumber(row?.projectedSales),
    [DrillDownFieldsEnum.Overage]: toNumber(row?.overage),
    [DrillDownFieldsEnum.ApprovedCount]: toNumber(row?.approvedCount),
    [DrillDownFieldsEnum.DisApprovedCount]: toNumber(row?.disapprovedCount),
    [DrillDownFieldsEnum.DisApprovedSales]: toNumber(row?.disapprovedSales),
    [DrillDownFieldsEnum.PendingCount]: toNumber(row?.pendingCount),
    [DrillDownFieldsEnum.MaxApprovedSales]: toNumber(row?.maxApprovedSales),
    [DrillDownFieldsEnum.MaxOverage]: toNumber(row?.maxOverage),
  };
  map(Object.keys(definition), (key) => {
    if (!isNil(definition[key])) {
      items.push({
        Name: key,
        amount: definition[key],
        change: 0,
        currencyType,
      });
    }
  });
  return items;
};

interface SalesReportNameProps {
  miLoc?: string;
  locName?: string;
  team: boolean;
}

export const getReportName = ({
  miLoc = '',
  locName = '',
  team = false,
}: SalesReportNameProps): string => {
  return team ? locName : `${miLoc}${locName ? `: ${locName}` : ''}`;
};

interface IncludeDailyTotalsProps {
  requestType: DateSegmentType;
  busPeriod: number;
}

interface IncludeDailyTotalsResponse {
  includeDailyTotal: boolean;
  includeDailyTotalMsg?: string;
  includeDailyTotalInfo?: string;
}

// TODO: rename this as a hook
export const GetIncludeDailyTotalsMsg = ({
  requestType,
  busPeriod,
}: IncludeDailyTotalsProps): IncludeDailyTotalsResponse => {
  const { t } = useTranslation(namespaces.common);
  const includeDailyTotalsFeatureFlag = useFeatureFlags(
    FeatureFlagType.includeDailyTotals
  );

  if (includeDailyTotalsFeatureFlag) {
    if (
      includes(['YTD', 'MTD'], requestType) &&
      isToday(parseDate(busPeriod))
    ) {
      const includeDailyTotalMsg = t(
        `${namespaces.common}:includeDailyTotalMsg`,
        { requestType }
      );
      const includeDailyTotalInfo = t(
        `${namespaces.common}:includeDailyTotalInfo`,
        {
          periodType:
            requestType === 'MTD'
              ? t(`${namespaces.common}:month`)
              : t(`${namespaces.common}:year`),
        }
      );
      return {
        includeDailyTotal: true,
        includeDailyTotalMsg,
        includeDailyTotalInfo,
      };
    }
  }

  return { includeDailyTotal: false };
};

export const getUserRole = (role: string): string => {
  let userRole = role;
  // from rollupTree, DIV should be handled as GRP
  if (role === 'DIV') {
    userRole = 'GRP';
  }
  // from rollupTree, REG should be handled as DIV
  if (role === 'REG') {
    userRole = 'DIV';
  }
  return userRole;
};

export const invalidBusinessDay = 'invalidBusDay';
export const salesJobErrorMsg = 'Sales Job is currently running';
export const noAcctsAssigned = 'noAcctsAssigned';

export const isSalesJobRunning = (error?: AxiosError | null) => {
  const errorData = error?.response?.data as DataResponse;
  return errorData?.messages?.[0]?.message === salesJobErrorMsg;
};

export const noCoprporateAccountAssigned = (error?: AxiosError | null) => {
  const errorData = error?.response?.data as DataResponse;
  return errorData?.messages?.[0]?.message === noAcctsAssigned;
};
