import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams, useRouteMatch } from 'react-router-dom';
import { find, get, map, size, toString } from 'lodash';
import { IonPage, IonContent, IonRow } from '@ionic/react';
import Alert from 'common/components/Alert/Alert';
import { and, choose } from 'common/utils/logicHelpers';
import { namespaces } from 'i18n/i18n.constants';
import { customerAddEditNoteURL } from 'navigation';
import useFindNotes from 'api/notebooks/useFindNotes';
import { AttachmentSize } from 'models/Attachment';
import type { Note } from 'models/Notebook';
import type { RootState } from 'store/reducers';
import { DateFormatEnum, formatDate } from 'utils/date';
import { getErrorMessage } from 'utils/helpers';
import { findIcon } from 'utils/icons';
import { concatRoutes } from 'utils/navigations';
import { getSnapshotEyebrow } from 'utils/search';
import ActionRow from 'components/ActionRow/ActionRow';
import Attachments from 'components/Attachments/Attachments';
import Header from 'components/Header/Header';
import InfiniteScroll from 'components/InfiniteScroll/InfiniteScroll';
import Loader from 'components/Loader/Loader';
import Refresher from 'components/Refresher/Refresher';
import SegmentTabs from 'components/SegmentTabs/SegmentTabs';
import Text from 'components/Text/Text';
import WarningMessage from 'components/WarningMessage/WarningMessage';
import classes from './NotesAttachments.module.scss';

interface NotebookItemProps {
  miLoc: string;
  id: string;
  url: string;
  notebook: Note;
}

const NotebookItem = ({
  miLoc,
  id,
  url,
  notebook,
}: NotebookItemProps): JSX.Element => {
  const { userInfo } = useSelector((state: RootState) => state.user);
  const userId = get(userInfo, 'userid', '');
  const { t, i18n } = useTranslation(namespaces.notes);
  const {
    title = '',
    text = '',
    historyId,
    itemLineNo,
    notebookType,
    updatedTimestamp,
    attachments,
    updatedUserid = '',
    updatedUserName = '',
  } = notebook;

  const isAlert = notebookType === 'ALERT';

  return (
    <ActionRow
      className={classes.noteCard}
      disabled={false}
      href={concatRoutes(
        url,
        customerAddEditNoteURL(
          notebookType,
          toString(choose(isAlert, historyId, itemLineNo))
        )
      )}
      testid="note"
    >
      <div className={classes.noteItem}>
        <IonRow>
          <Text
            className={classes.updateDate}
            variant="label-micro"
            text={formatDate(
              updatedTimestamp,
              DateFormatEnum.fullDate,
              i18n.language
            )}
          />
          <Text
            className={classes.userId}
            variant="label-micro"
            text={`(${
              updatedUserid === userId ? t('common:you') : updatedUserName
            })`}
          />
        </IonRow>
        <Text
          text={choose(isAlert, text, title)}
          testid="notebookTitle"
          className={classes.ellipsis}
        />
        {choose(and(!title, !isAlert), <Alert text={t('noTitle')} />)}
        {size(attachments) > 0 && (
          <Attachments
            size={AttachmentSize.SMALL}
            editMode={false}
            className={classes.galleryAttachmentsSmall}
            domain="customer"
            name="customer"
            files={attachments}
            disabled
            testid={`attachment-${miLoc}-${id}`}
          />
        )}
      </div>
    </ActionRow>
  );
};

interface NotesAttachmentsURLParams {
  miLoc: string;
  id: string;
}

const NotesAttachmentList = (): JSX.Element => {
  const { url } = useRouteMatch();
  const { miLoc, id } = useParams<NotesAttachmentsURLParams>();
  const { t } = useTranslation(namespaces.notes);
  enum ListType {
    ALL = 'ALL',
    CUSTOMER = 'CUSTOMER',
    VISIT = 'VISIT',
  }

  const options = [
    { key: ListType.ALL, text: t('common:all') },
    { key: ListType.CUSTOMER, text: t('common:customer') },
    { key: ListType.VISIT, text: t('common:visit') },
  ];
  const [selectedFilter, setSelectedFilter] = useState<ListType>(ListType.ALL);
  const selectedTabName = toString(
    find(options, { key: selectedFilter })?.text
  );

  const {
    notes,
    customerData,
    showLoader,
    isEmptyResponse,
    hasError,
    error,
    enableInfiniteScroll,
    fetchNextPage,
    refetch,
  } = useFindNotes({
    miLoc,
    id,
    showAlertNotes: selectedFilter !== ListType.CUSTOMER,
    showCustomerNotes: selectedFilter !== ListType.VISIT,
  });

  return (
    <IonPage>
      <Header
        title={t('snapshot:notesAttachments')}
        eyebrow={getSnapshotEyebrow({ name: customerData?.name, miLoc, id })}
        withBackButton
        testid="notesAttachmentList"
      >
        <SegmentTabs
          className={classes.filterTabs}
          options={options}
          value={selectedFilter}
          setValue={(v: string) => {
            setSelectedFilter(v as ListType);
          }}
          testid="notes-tabs"
        />
      </Header>
      <IonContent className={classes.content}>
        <Refresher
          slot="fixed"
          hidden
          onRefresh={refetch}
          testid="notes-list-refresher"
          disabled={showLoader}
        />
        {selectedFilter !== ListType.VISIT && (
          <div className={classes.filterWrapper}>
            <ActionRow
              text={t('addNote')}
              icon={findIcon('sticky-note')}
              href={concatRoutes(url, customerAddEditNoteURL('CM', 'add'))}
              className={classes.addNoteRow}
              testid="add-notebook-button"
            />
          </div>
        )}
        {!hasError && (
          <div data-testid="notes-list-main-container">
            {map(notes, (note, index) => (
              <NotebookItem
                key={`${toString(note.itemLineNo)}-${index}`}
                miLoc={miLoc}
                id={id}
                url={url}
                notebook={note}
              />
            ))}
          </div>
        )}
        {isEmptyResponse && (
          <WarningMessage
            className={classes.warningMessage}
            icon={['far', 'info-circle']}
            title={t('noResults', { selectedTabName })}
          />
        )}
        {hasError && (
          <WarningMessage
            className={classes.warningMessage}
            title={t('errorLoadingNotes')}
            body={getErrorMessage(error)}
          />
        )}
        <Loader
          className={classes.loader}
          text={t('loadingNotes', { selectedTabName })}
          isOpen={showLoader}
          testid="loadingNotes"
        />
        <InfiniteScroll
          disabled={!enableInfiniteScroll}
          onLoadMore={fetchNextPage}
          testid="infinite-scroll"
        />
      </IonContent>
    </IonPage>
  );
};

export default NotesAttachmentList;
