import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import { get, isEmpty, map, toLower, toString } from 'lodash';
import { useIonViewWillEnter } from '@ionic/react';
import {
  SendEmailFormik,
  SendEmailPage,
} from 'common/components/SendEmail/SendEmail';
import type { SendEmailPageRef } from 'common/components/SendEmail/SendEmail';
import { choose } from 'common/utils/logicHelpers';
import useSendEmail from 'api/activities/useSendEmail';
import useFindAttachments from 'api/attachments/useFindAttachments';
import useGetNote from 'api/notebooks/useGetNote';
import useGoBack from 'hooks/useGoBack';
import type { RootState } from 'store/reducers';
import { formatSnoozeDate } from 'utils/date';
import { goToShareCustomerNote } from 'navigation/navigationHelpers';
import type { AddNoteURLParams } from 'pages/Snapshots/Customers/NotesAttachments/AddEditNote';

const ShareCustomerNote = (): JSX.Element => {
  const { goBack } = useGoBack();
  const { t } = useTranslation();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const resetView = params.get('reset');
  const pageRef = useRef<SendEmailPageRef>(null);
  const { miLoc, id, noteType, noteId } = useParams<AddNoteURLParams>();
  const { userInfo } = useSelector((state: RootState) => state.user);
  const userId = get(userInfo, 'userid', '');
  const userName = get(userInfo, 'name', '');
  const history = useHistory();

  useIonViewWillEnter(() => {
    if (resetView) {
      pageRef.current?.resetForm?.();
      history.replace(
        goToShareCustomerNote({
          miLoc,
          id,
          noteType,
          noteId,
        })
      );
    }
  }, [resetView]);

  const { onSendEmail, status } = useSendEmail();

  useEffect(() => {
    if (status === 'success') {
      goBack();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  const {
    customerData,
    notebook,
    error: customerError,
    isLoading: customerLoading,
  } = useGetNote({
    miLoc,
    id,
    noteType,
    noteId,
  });

  const isCustomerNotebook = notebook?.notebookType === 'CM';
  const ownerUserId = notebook?.userId;
  const attachementUserId = ownerUserId === userId ? userId : ownerUserId;

  const {
    data: attachmentsData,
    isLoading: attachmentsLoading,
    error: attachmentsError,
  } = useFindAttachments({
    domain: isCustomerNotebook ? 'customer' : 'mprovisi',
    miLoc: isCustomerNotebook ? miLoc : 'EXEC',
    ctlNo: isCustomerNotebook ? id : attachementUserId,
    lineNo: noteId,
    enabled: !isEmpty(userId) && !customerLoading,
  });

  const loggedInUserId = get(userInfo, 'userid', '');

  const isSubmitting = status === 'loading';
  const subject = t('activities:sendEmail:defaultSubject', {
    userName,
    ownerName: notebook?.updatedUserName,
    customerName: customerData?.name,
    type: toLower(t('common:note')),
    count: loggedInUserId === notebook?.updatedUserid ? 0 : 1,
  });

  const message = choose(
    noteType === 'ALERT',
    t('activities:sendEmail:defaultMessageNoTitle_zero', {
      customerName: customerData?.name,
      type: t('common:visit'),
      note: notebook?.text,
      date: formatSnoozeDate(toString(notebook?.creationTimestamp)),
    }),
    t('activities:sendEmail:defaultNoteMessage', {
      noteTitle: notebook?.title,
      customerName: customerData?.name,
      note: notebook?.text,
    })
  ) as string;
  return (
    <SendEmailFormik
      onSubmit={(values) => {
        onSendEmail({
          subject: values.subject,
          recipients: map(values.recepientsList, ({ email }) =>
            toString(email)
          ),
          toastText: t('activities:sendEmail:emailNotesSuccess', {
            recipients: map(values.recepientsList, (i) => i.name).join('; '),
          }),
          body: toString(values.message),
          filesToUpload: values.files as File[],
        });
      }}
      values={{
        subject,
        message,
        recepients: '',
        files: attachmentsData,
      }}
    >
      <SendEmailPage
        ref={pageRef}
        customerData={customerData}
        isLoading={customerLoading || attachmentsLoading}
        error={customerError || attachmentsError}
        isSubmitting={isSubmitting}
        title={t('activities:emailNote')}
      />
    </SendEmailFormik>
  );
};

export default ShareCustomerNote;
