import type { AxiosError } from 'axios';
import { isEmpty, isNil } from 'lodash';
import { useIonViewDidEnter } from '@ionic/react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import useAPIUrl from 'api';
import { and } from 'common/utils/logicHelpers';
import { useAxios } from 'providers/AxiosProvider';
import { doPromiseAPI, getPlaceholderData, useKeyUserId } from 'api/helpers';
import type { Employee } from 'models/Employee';
import type { QueryFlags } from 'models/Search';
import { findEmployeesQueryKey } from './useFindEmployees';

export const getEmployeeQueryKey = 'employee';

interface UseGetEmployeeProps {
  id: string;
  invalidateQuery?: boolean;
  enabledProp?: boolean;
}

interface UseGetEmployeeResponse {
  data?: Employee;
}

const useGetEmployee = ({
  id,
  invalidateQuery = false,
  enabledProp = true,
}: UseGetEmployeeProps): UseGetEmployeeResponse & QueryFlags => {
  const { axios } = useAxios();
  const { getEmployeeAPI } = useAPIUrl();
  const queryClient = useQueryClient();
  const { createQueryKey } = useKeyUserId();

  const doGetEmployee = () => {
    return doPromiseAPI<Employee>(async (cancelToken) => {
      const { data } = await axios.get<Employee>(getEmployeeAPI(id), {
        cancelToken,
      });
      return data;
    });
  };

  const enabled = and(!isEmpty(id), enabledProp);

  const { data, status, error, isFetching, refetch } = useQuery<
    Employee,
    AxiosError
  >(createQueryKey(getEmployeeQueryKey, { id }), doGetEmployee, {
    enabled,
    placeholderData: () => {
      return getPlaceholderData<Employee>({
        queryClient,
        queryKey: findEmployeesQueryKey,
        objectKey: 'items',
        findPredicate: { id },
      });
    },
  });

  useIonViewDidEnter(() => {
    if (invalidateQuery && enabled) {
      void refetch();
    }
  });

  return {
    data,
    error,
    isLoading: status === 'loading' || (isFetching && isNil(data)),
  };
};

export default useGetEmployee;
