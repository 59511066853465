import React from 'react';
import { Link } from 'react-router-dom';
import { IonMenuToggle } from '@ionic/react';
import { ifRender } from 'common/utils/logicHelpers';
import useChangeLocation from 'hooks/useChangeLocation';
import Text from 'components/Text/Text';
import classes from './HomeMenu.module.scss';

const UserLocation = (): JSX.Element => {
  const { isLocatorAvailable, locatorURL, locationText } = useChangeLocation();

  return (
    <>
      <Text
        variant="label-header"
        text={locationText}
        testid="home-menu-location"
      />
      <IonMenuToggle>
        {ifRender(
          isLocatorAvailable,
          <Link className={classes.setLocationButton} to={locatorURL}>
            <Text variant="content-small" text="Change" />
          </Link>
        )}
      </IonMenuToggle>
    </>
  );
};

export default UserLocation;
