import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IonRow } from '@ionic/react';
import useGetTeamToShareWith from 'ActivitiesApp/api/useGetTeamToShareWith';
import type { ContactModalProps } from 'common/components/ContactModal/ContactModal';
import ContactModal from 'common/components/ContactModal/ContactModal';
import HelpButton from 'common/components/HelpButton/HelpButton';
import { ifRender } from 'common/utils/logicHelpers';
import { namespaces } from 'i18n/i18n.constants';
import { getErrorMessage } from 'utils/helpers';
import Text from 'components/Text/Text';
import WarningMessage from 'components/WarningMessage/WarningMessage';
import classes from './ShareWithTeamHelpButton.module.scss';
import TeamMembersList from './TeamMembersList';

interface ShareWithTeamHelpButtonProps {
  loggedInUserEmpNo: string;
  testId: string;
  miLoc: string;
  custId: string;
}
const ShareWithTeamHelpButton = (
  props: ShareWithTeamHelpButtonProps
): JSX.Element => {
  const { testId, loggedInUserEmpNo, miLoc, custId } = props;
  const { t } = useTranslation(namespaces.activities);
  const [isContactModalOpen, setIsContactModalOpen] = useState(false);
  const {
    data: teamList,
    error: teamError,
    isLoading: isTeamLoading,
  } = useGetTeamToShareWith({ miLoc, id: custId });
  const [contactModalData, setContactModalData] = useState<
    Partial<ContactModalProps>
  >({
    name: '',
    phoneNumber: '',
    mobilePhone: '',
    email: '',
  });

  return (
    <>
      <HelpButton
        modal={{
          title: t('shareWithTeam'),
          testid: `${testId}-info`,
          initialBreakpoint: 0.75,
          children: (
            <>
              <IonRow>
                <Text text={t('shareWithTeamMessage')} />
              </IonRow>
              {ifRender(
                !isTeamLoading,
                <TeamMembersList
                  teamList={teamList}
                  testId={testId}
                  setIsContactModalOpen={setIsContactModalOpen}
                  setContactModalData={setContactModalData}
                  loggedInUserEmpNo={loggedInUserEmpNo}
                />
              )}
              {ifRender(
                !!teamError,
                <WarningMessage
                  title={t('common:errorTitle')}
                  body={getErrorMessage(teamError)}
                  testid={`${testId}-warning`}
                />
              )}
            </>
          ),
        }}
        button={{
          testid: `${testId}-btn`,
          icon: ['far', 'info-circle'],
          className: classes.infoButton,
        }}
      />
      <ContactModal
        isOpen={isContactModalOpen}
        setIsOpen={setIsContactModalOpen}
        testid="team-member-contact-modal"
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...contactModalData}
      />
    </>
  );
};

export default ShareWithTeamHelpButton;
