import {
  forEach,
  size,
  trim,
  map,
  keyBy,
  head,
  orderBy,
  filter,
  groupBy,
  toNumber,
} from 'lodash';
import type { Dictionary } from 'lodash';
import { choose, or } from 'common/utils/logicHelpers';
import type { Territory, TerritoryLocation } from 'models/Territory';

export const parseRollupLocation = (
  node: Territory,
  keepOriginal = false
): TerritoryLocation => {
  return {
    ...choose(keepOriginal, node),
    miLoc: node.mi_loc,
    locationType: node.LOC_TYPE,
    teamId: trim(node.team_id),
    name: trim(node.SHORT_NAME),
    userRole: trim(node.PERCEIVED_USER_ROLE),
    hasAccess: or(node.hasRealmAccess, node.hasRollupAccess),
    hasRollupAccess: node.hasRollupAccess,
    hasChildren: size(node.items) > 0,
    id: node.id,
  };
};

export const mapLocations = (
  rollupTree: Territory[] = [],
  parent = '',
  depth = 0,
  parents: string[] = []
): TerritoryLocation[] => {
  let result: TerritoryLocation[] = [];
  forEach(rollupTree, (node) => {
    result.push({
      ...parseRollupLocation(node),
      parent,
      parents,
      depth,
    });
    result = result.concat(
      mapLocations(node.items, node.mi_loc, depth + 1, [
        ...parents,
        node.mi_loc,
      ])
    );
  });
  return result;
};

export const getLocationTree = (
  rollupTree?: Territory[]
): Dictionary<TerritoryLocation> =>
  keyBy(
    map(mapLocations(rollupTree), (item, index) => ({ ...item, order: index })),
    'miLoc'
  );

export const getFirstAccessibleLocation = (
  locationTree: Dictionary<TerritoryLocation>
): TerritoryLocation | undefined =>
  head(
    orderBy(
      filter(locationTree, { hasAccess: true }),
      ['hasRollupAccess', 'depth', 'order'],
      ['desc', 'asc', 'asc']
    )
  );

export const getFallbackDeepestLocation = (
  locationTree: Dictionary<TerritoryLocation>
): TerritoryLocation | undefined => {
  let fallbackItem: TerritoryLocation | undefined;
  const locationGroups = orderBy(
    groupBy(
      filter(
        locationTree,
        ({ locationType }) => !['VT', 'T'].includes(locationType)
      ),
      'depth'
    ),
    ['depth'],
    ['asc']
  );

  locationGroups.forEach((locationItemArray) => {
    if (
      locationItemArray.length === 1 &&
      (!fallbackItem ||
        (toNumber(fallbackItem.depth) < toNumber(locationItemArray[0].depth) &&
          locationItemArray[0].hasAccess))
    ) {
      // eslint-disable-next-line prefer-destructuring
      fallbackItem = locationItemArray[0];
    }
  });
  return fallbackItem;
};
