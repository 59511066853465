import { choose, ifFunction, or } from 'common/utils/logicHelpers';

export interface GroupByProps {
  name: string;
  key: string;
  suffix?: string;
}

export const groupByDivisionObj: GroupByProps = {
  key: 'DIV',
  name: 'reports:divisions',
};
export const groupByBranchObj: GroupByProps = {
  key: 'BRCH',
  name: 'reports:branches',
};

export const groupByGrpObj: GroupByProps = {
  key: 'GRP',
  name: 'reports:groups',
};

export const groupByExexObj: GroupByProps = {
  key: 'EXEC',
  name: 'reports:executive',
};

export const groupByCustomerObj: GroupByProps = {
  key: 'CUST',
  name: 'common:customers',
};

const groupByPgc2Obj: GroupByProps = {
  key: 'PRD_GRP_02',
  name: 'reports:pgcLevel',
  suffix: '2',
};

const groupByPgc1Obj: GroupByProps = {
  key: 'PRD_GRP_01',
  name: 'reports:pgcLevel',
  suffix: '1',
};

export const groupByPick12Corps = { key: 'CORP', name: 'reports:corpsPick12' };
export const groupByCorporations = {
  ...groupByPick12Corps,
  name: 'reports:corporations',
};

export const groupyByPick2Reps = {
  key: 'REP',
  name: 'reports:acctRepsPick12',
};

export const groupByReps = {
  key: 'REP',
  name: 'reports:acctReps',
};

export const groupByAvpObj = {
  key: 'CAM',
  name: 'ReportApp-SalesPerformanceReport:cam',
};

export const groupByRoleOptions = (isTableView?: boolean): GroupByProps[] => [
  groupByExexObj,
  choose(isTableView, groupByPick12Corps, groupByCorporations) as GroupByProps,
  groupByGrpObj,
  groupByDivisionObj,
  groupByBranchObj,
  choose(isTableView, groupyByPick2Reps, groupByReps) as GroupByProps,
];

export const groupByBranch = (isTableView?: boolean): GroupByProps[] => [
  choose(isTableView, groupyByPick2Reps, groupByReps) as GroupByProps,
  groupByCustomerObj,
];

export const groupByCostSavings: GroupByProps[] = [
  ...groupByRoleOptions(),
  groupByCustomerObj,
];

export const groupByUnbilled = (userRole: string): GroupByProps[] => {
  let groupBy = [groupByDivisionObj, groupByBranchObj];
  ifFunction(userRole === 'EXEC', () => {
    groupBy = [groupByPick12Corps];
  });

  ifFunction(userRole === 'CORP', () => {
    groupBy = [groupByGrpObj];
  });

  ifFunction(or(userRole === 'DIV', userRole === 'BRCH'), () => {
    groupBy = [groupByBranchObj];
  });

  return groupBy;
};

export const groupByVirtualTeam: GroupByProps[] = [
  { key: 'TEAM', name: 'common:teams' },
  groupByCustomerObj,
];

export const groupByCamExec: GroupByProps[] = [
  { key: 'NATLACCT', name: 'common:corpaccounts' },
];

export const groupByCamCorp: GroupByProps[] = [
  groupByGrpObj,
  groupByDivisionObj,
  groupByBranchObj,
  groupByCustomerObj,
];

export const groupByCam: GroupByProps[] = [groupByCustomerObj];

export const groupByAVP: GroupByProps[] = [groupByAvpObj];

export const groupByTeamAndRep: GroupByProps[] = [groupByCustomerObj];

export const groupByPersonalPick12: GroupByProps[] = [groupByCustomerObj];

export const groupByCustomer: GroupByProps[] = [groupByPgc1Obj, groupByPgc2Obj];

export const groupByProductGroup01 = [groupByPgc2Obj];

export const groupByWithNameColumn = [
  'CAM',
  'TEAM',
  'REP',
  'NATLACCT',
  'CUST',
  'PRD_GRP_01',
  'PRD_GRP_02',
];

export const groupByAvpCostSavings: GroupByProps[] = [
  groupByAvpObj,
  ...groupByCamCorp,
];
