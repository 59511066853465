import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { get, has, some, toString } from 'lodash';
import { and, choose, or } from 'common/utils/logicHelpers';
import { isAccountRep, useGetAvpUser } from 'common/utils/userInfo';
import { territoriesURL } from 'navigation';
import type { RootState } from 'store/reducers';
import useChangeAppMode from './useChangeAppMode';

interface UseChangeLocatorResponse {
  isLocatorAvailable: boolean;
  locatorURL: string;
  locationText: string;
}

const useChangeLocation = (): UseChangeLocatorResponse => {
  const {
    miLoc = '',
    userInfo,
    locationTree = {},
    isCamUser,
  } = useSelector((state: RootState) => state.user);
  const jobCode = get(userInfo, 'jobCode');
  const { isMiProApp } = useChangeAppMode();
  const { isAvpUserWithJobCode, isAvpUserWithExecView } = useGetAvpUser();
  const hideLocationSwitcher = and(
    or(
      and(isAccountRep(jobCode), has(locationTree, 'VT')),
      isCamUser,
      isAvpUserWithJobCode
    ),
    isMiProApp
  );

  const currentLocation = locationTree[miLoc]?.parent;
  const isMultiLocator = useMemo(
    () =>
      some(Object.values(locationTree), (item) =>
        and(item.hasAccess, item.miLoc !== miLoc)
      ),
    [locationTree, miLoc]
  );

  return {
    isLocatorAvailable: and(!hideLocationSwitcher, isMultiLocator),
    locatorURL: territoriesURL(currentLocation),
    locationText: toString(
      choose(isCamUser, 'CAM', choose(isAvpUserWithExecView, 'AVP', miLoc))
    ),
  };
};

export default useChangeLocation;
