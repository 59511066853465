import { isNil, findKey, find } from 'lodash';
import type {
  IconProp,
  IconPrefix,
  IconName,
} from '@fortawesome/fontawesome-svg-core';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fad } from '@fortawesome/pro-duotone-svg-icons';
import { fal } from '@fortawesome/pro-light-svg-icons';
import { far } from '@fortawesome/pro-regular-svg-icons';
import { fas } from '@fortawesome/pro-solid-svg-icons';

library.add(far, fas, fal, fad);

export const icons = {
  far: [
    'rocket',
    'chevron-right',
    'chevron-left',
    'chevron-up',
    'chevron-down',
    'check',
    'times',
    'comment-alt-dots',
    'ghost',
    'info-circle',
    'bell-slash',
    'comment-alt-slash',
    'camera',
    'external-link-alt',
    'exclamation-circle',
    'arrow-up',
    'arrow-right',
    'history',
    'cog',
    'file-plus',
    'filter',
    'external-link',
    'user-plus',
    'sticky-note',
    'caret-up',
    'caret-down',
    'grip-lines',
    'map-marker',
    'phone',
    'envelope',
    'watch',
    'bell',
    'edit',
    'calendar-day',
    'phone-plus',
    'envelope-open-text',
    'sync',
    'paper-plane',
    'pen',
    'trash',
    'user-check',
    'minus',
    'plus',
    'eye',
    'eye-slash',
    'arrow-down',
    'times-circle',
    'bullhorn',
    'file-alt',
    'plus-circle',
    'check-double',
    'thumbs-up',
    'thumbs-down',
    'download',
    'upload',
    'user',
    // tab icons
    'home-alt',
    'search',
    'comment',
    'comment-alt',
    'chart-line',
    'map-marker-check',
    'user-chart',
    'file-spreadsheet',
    'warehouse-alt',
    'file-exclamation',
    // activity card icons
    'clock',
    'star',
    'check-circle',
    'bell-plus',
    'ban',
    'alarm-clock',
    'alarm-exclamation',
    'lightbulb-dollar',
    'chart-line-down',
    'random',
    'calculator',
    'sack-dollar',
    'envelope-open-dollar',
    'browser',
    'heartbeat',
    'address-card',
    'exclamation-square',
    'question-square',
    'archive',
    'person-carry',
    'traffic-cone',
    'screwdriver-wrench',
    'hand-holding-dollar',
    // search tab
    'industry-alt',
    'truck',
    'random',
    'hard-hat',
    'ellipsis-v',
    'circle',
    'check',
    'shipping-fast',
    'lock-alt',
    'delete-left',
    'sign-in',
    'clone',
    'badge-dollar',
    'ellipsis-h',
    'trophy',
    'link',
    'truck-pickup',
    'long-arrow-up',
    'long-arrow-down',
    'gear',
    'trophy-alt',
    'piggy-bank',
    'xmark',
    'trash-can',
    'barcode-read',
    'loader',
    'sliders',
    'hand-holding-dollar',
    'list-check',
  ],
  fas: [
    'bat',
    'bomb',
    'barcode',
    'dog',
    'unicorn',
    'cat',
    'crow',
    'fighter-jet',
    'football-helmet',
    'motorcycle',
    'pepper-hot',
    'alien-monster',
    'anchor',
    'baby',
    'bowling-ball',
    'deer',
    'feather-alt',
    'boombox',
    'futbol',
    'ghost',
    'hat-cowboy',
    'cactus',
    'cassette-tape',
    'cocktail',
    'dove',
    'french-fries',
    'guitar-electric',
    'hockey-sticks',
    'knife-kitchen',
    'rocket-launch',
    'beer',
    'hotdog',
    'corn',
    'bacon',
    'bone',
    'campfire',
    'candy-cane',
    'candy-corn',
    'cheese',
    'hamburger',
    'dice',
    'frog',
    'ice-cream',
    'robot',
    'acorn',
    'baseball-ball',
    'basketball-ball',
    'coffee-togo',
    'cow',
    'hand-spock',
    'flag-checkered',
    'exclamation-triangle',
    'caret-up',
    'caret-down',
    'chevron-right',
    'chevron-left',
    'chevron-up',
    'chevron-down',
    'minus-circle',
    'mobile-alt',
    'truck',
    'times-circle',
    'wrench',
    'user',
    // tab icons
    'home-alt',
    'search',
    'comment-alt',
    'chart-line',
    'user-chart',
    'file-spreadsheet',
    'warehouse-alt',
    'file-exclamation',
    // activity card icons
    'clock',
    'star',
    'check-circle',
    'bell-plus',
    'ban',
    'trash',
    'random',
    'trash-alt',
    'shipping-fast',
    'clone',
    'exclamation-triangle',
    'play-circle',
    'wifi',
    'wifi-slash',
    'triangle-exclamation',
  ],
  fal: [
    'map-signs',
    'cogs',
    'file-edit',
    'file-image',
    'file-video',
    'file-pdf',
    'file-spreadsheet',
    'file-powerpoint',
    'file-csv',
    'file-image',
    'file-alt',
    'shipping-fast',
    'user-alt',
  ],
};

export const findIcon = (icon?: IconName, family?: IconPrefix): IconProp => {
  let prefix = family;
  if (!prefix) {
    prefix = findKey(icons, (collection): boolean => {
      const iconExists = find(collection, (element) => element === icon);
      return !!iconExists;
    }) as IconPrefix;
  }
  if (!isNil(prefix) && !isNil(icon)) {
    return [prefix, icon];
  }
  return ['fas', 'rocket-launch'];
};
