import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import AppIcons from 'constants/appIcons';
import classNames from 'classnames';
import {
  isNil,
  map,
  toString,
  toNumber,
  size,
  kebabCase,
  endsWith,
  trim,
  isEmpty,
} from 'lodash';
import {
  IonContent,
  IonFooter,
  IonPage,
  IonRow,
  IonToolbar,
  useIonViewWillEnter,
} from '@ionic/react';
import useAddActivityNote from 'ActivitiesApp/api/notes/useAddActivityNote';
import useUpdateActivityNote from 'ActivitiesApp/api/notes/useUpdateActivityNote';
import type { CommentListRef } from 'ActivitiesApp/components/Comments/CommentList';
import CommentList, {
  onScroll,
} from 'ActivitiesApp/components/Comments/CommentList';
import RelatedOpportunity from 'ActivitiesApp/components/RelatedOpportunity/RelatedOppurtunity';
import type { ActivityDetailURLParams } from 'ActivitiesApp/models/ActivityDetail';
import {
  crmDisableComments,
  crmHasOpenStatus,
  highPriorityTask,
} from 'ActivitiesApp/utils/helpers';
import type { InputRef } from 'common/components/Forms/Input/Input';
import Input from 'common/components/Forms/Input/Input';
import Header from 'common/components/Header/Header';
import {
  getIntervalRender,
  toggleKeyboard,
  useIonContentRef,
} from 'common/components/utils/renderHelpers';
import {
  and,
  choose,
  ifFunction,
  ifRender,
  or,
} from 'common/utils/logicHelpers';
import { useGetUserInfo } from 'common/utils/userInfo';
import { DataTypeEnum } from 'common/utils/valueFormatter';
import { differenceInMinutes, formatDistance } from 'date-fns';
import { namespaces } from 'i18n/i18n.constants';
import {
  activitiesURL,
  logAVisitURL,
  searchCustomerURL,
  searchURL,
} from 'navigation';
import { useToasts } from 'providers/ToastProvider';
import useGetActivity from 'api/activities/useGetActivity';
import useMarkActivityAsRead from 'api/activities/useMarkActivityAsRead';
import useUpdateActivity from 'api/activities/useUpdateActivity';
import useGetCustomer from 'api/customer/useGetCustomer';
import useAccessControls, { AccessControlType } from 'hooks/useAccessControls';
import type {
  ActionCardActivity,
  UpdateActivityFlags,
} from 'models/ActivityModels';
import { ActivityType } from 'models/ActivityModels';
import type { Customer } from 'models/Customer';
import type { UpdateActivityNoteBody } from 'models/Notebook';
import type { SnoozedData } from 'models/SnoozedData';
import { clearDeepLink } from 'store/user';
import {
  DateFormatEnum,
  formatDate,
  formatSnoozeDate,
  getDateLocale,
  parseDate,
} from 'utils/date';
import { withStringProp } from 'utils/helpers';
import { concatRoutes } from 'utils/navigations';
import {
  getActivityConfig,
  getActivityType,
  useGetActionCardConfig,
} from 'pages/Activities/ActivityActionCard/ActivityCardConfig';
import type { ActionCardOption } from 'components/Activities/ActionCard/ActionCard';
import ActionCardModalData from 'components/Activities/ActionCardModal/ActionCardModalData';
import type { ActivityModalData } from 'components/Activities/ActionCardModal/ActionCardModalData';
import OwnerTitle from 'components/Activities/OwnerTitle/OwnerTitle';
import SnoozeModal from 'components/Activities/SnoozeModal/SnoozeModal';
import SnoozeTitle from 'components/Activities/SnoozeTitle/SnoozeTitle';
import Badge from 'components/Badge/Badge';
import Button from 'components/Button/Button';
import ContactModal from 'components/Contacts/ContactModal/ContactModal';
import SnapshotLink from 'components/Contacts/SnapshotLink/SnapshotLink';
import Loader from 'components/Loader/Loader';
import ConfirmDialog from 'components/Modals/ConfirmDialog/ConfirmDialog';
import SendEmailModal from 'components/Modals/SendEmailModal/SendEmailModal';
import ActivityNotesList from 'components/Notes/ActivityNotesList';
import Refresher from 'components/Refresher/Refresher';
import Text from 'components/Text/Text';
import classes from './ActivityDetail.module.scss';

const defaultDataDefinition: ActivityModalData = {
  orderNumber: { label: 'common:ocn', type: DataTypeEnum.ocn },
  orderNumberOrder: { label: 'ocn (order)', type: DataTypeEnum.ocn },
  orderNumberQuote: { label: 'ocn (quote)', type: DataTypeEnum.ocn },
  lines: { label: 'common:lines', type: DataTypeEnum.string },
  total: { label: 'common:total', type: DataTypeEnum.currency },
  custPONumber: { label: 'common:poNumber', type: DataTypeEnum.string },
  expectedDate: { label: 'common:expected', type: DataTypeEnum.date },
  orderDate: { label: 'common:ordered', type: DataTypeEnum.date },
  lastUpdatedDate: { label: 'lastActivity', type: DataTypeEnum.date },
  orderStatus: { label: 'common:status', type: DataTypeEnum.string },
  shipStatus: { label: 'common:status', type: DataTypeEnum.string },
  contact: { label: 'common:contact', type: DataTypeEnum.string },
  company: { label: 'common:company', type: DataTypeEnum.string },
  address: { label: 'common:address', type: DataTypeEnum.address },
  phone: { label: 'common:phone', type: DataTypeEnum.phone },
  email: { label: 'common:email', type: DataTypeEnum.email },
  ocnContactName: { label: 'common:contact', type: DataTypeEnum.contact },
  created: { label: 'common:created', type: DataTypeEnum.date },
  createdBy: { label: 'common:createdBy', type: DataTypeEnum.string },
};

export interface ActivityComponentRef {
  onSubmit: () => void;
}

const ActivityDetail = (): JSX.Element => {
  const dispatch = useDispatch();
  const { userId: loggedInUserId } = useGetUserInfo();
  const ac = useAccessControls(AccessControlType.viewCustomers);
  const { miLoc, id, userId, historyId } = useParams<ActivityDetailURLParams>();
  const history = useHistory();
  const { url } = useRouteMatch();
  const { t, i18n } = useTranslation(namespaces.activities);

  const { onMarkActivityAsRead } = useMarkActivityAsRead();

  const [closeModalIsOpen, setCloseModalIsOpen] = useState(false);
  const [didChange, setDidChange] = useState(false);
  const [hideActivityFlags, setHideActivityFlags] = useState(false);
  const [disableActivityFlags, setDisableActivityFlags] = useState(false);
  const [showContactModal, setShowContactModal] = useState(false);
  const [sendEmailModal, setSendEmailModal] = useState(false);
  const [isToBeDeleted, setIsToBeDeleted] = useState(false);
  const [isBusy, setIsBusy] = useState(false);
  const footerRef = useRef<HTMLDivElement>(null);
  const contentBeforeDetailsRef = useRef<HTMLDivElement>(null);
  const componentRef = useRef<ActivityComponentRef>(null);
  const commentsListRef = useRef<CommentListRef>(null);
  const { addToast } = useToasts();

  const isActivityDetail = !endsWith(url, logAVisitURL());

  const { data: customerData, isLoading: customerIsLoading } = useGetCustomer({
    searchType: 'customer',
    miLoc,
    id,
  });

  const {
    data: activity,
    isFetching: activityIsLoading,
    refetch: refetchActivity,
  } = useGetActivity({
    historyId: toNumber(historyId),
    userId: toString(or(userId, loggedInUserId)),
    enabled: isActivityDetail,
  });

  const { onUpdateActivity } = useUpdateActivity({
    userId: toString(activity?.userId),
    historyId: toNumber(historyId),
  });

  const customerVisitActivity = {
    userId: loggedInUserId,
    custMiLoc: miLoc,
    custNo: id,
    eventTagName: ActivityType.customerVisit,
    baseConfigVersion: '1',
  } as unknown as ActionCardActivity;

  const {
    emailContact,
    emailContactName,
    activityConfig: activityDetailConfig,
    modalHeader: activityDetailEyebrow,
  } = getActivityConfig({
    cardType: getActivityType(toString(activity?.eventTagName), t).cardType,
    activityData: or(activity, {} as ActionCardActivity),
    t,
    i18n,
    loggedInUserId,
    compressedName: customerData?.compressedName,
  });

  const {
    activityConfig: customerVisitActivityConfig,
    modalHeader: customerVisitEyebrow,
  } = useGetActionCardConfig({
    cardType: ActivityType.customerVisit,
    activityData: customerVisitActivity,
    loggedInUserId,
    compressedName: customerData?.compressedName,
  });

  const { status } = useUpdateActivity({
    userId: toString(activity?.userId),
    historyId: toNumber(activity?.historyId),
  });

  const activityConfig = choose(
    isActivityDetail,
    activityDetailConfig,
    customerVisitActivityConfig
  );

  const isLoading = or(activityIsLoading, customerIsLoading);

  const isSubmitting = status === 'loading';

  const isNonLoggedInOwner = activity?.userId !== loggedInUserId;
  const customerName = or(activity?.customerName, customerData?.name);
  const customerURL = concatRoutes(
    searchURL(),
    searchCustomerURL(or(activity?.custMiLoc, miLoc), or(activity?.custNo, id))
  );

  const disableHeaderFlags = activity?.userId !== userId;

  const { node, nodeRef } = useIonContentRef();

  const CustomContent = useMemo(
    () =>
      or(
        activityConfig?.modal?.customContent,
        activityConfig?.modal?.customContentWithRef
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activity?.eventTagName, activity?.historyId]
  );

  const CustomHeader = useMemo(
    () => activityConfig?.modal?.customHeader,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activity?.eventTagName, activity?.historyId]
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ) as React.FC<any>;

  const { title, description, buttons, hideSaveButton, highlight } =
    activityConfig?.modal ?? {};

  const goBack = useCallback(() => {
    if (history.location.pathname === '/tabs') {
      history.push(activitiesURL());
    } else history.goBack();
  }, [history]);

  // TODU: check if this is still in use
  const onSendEmail = () => {
    setSendEmailModal(true);
  };

  const onOpenContactModal = () => {
    if (emailContactName) {
      setShowContactModal(true);
    }
  };

  // #region activity actions
  const [showAfterData, setShowAfterData] = useState({
    showAfter: activity?.showAfter,
    showAfterISO: activity?.showAfterISO,
    isSnoozed: activity?.isSnoozed,
  });

  const isSnoozedBefore: boolean =
    differenceInMinutes(
      parseDate(showAfterData.showAfterISO),
      parseDate(activity?.creationTimestampISO)
    ) !== 0;

  const snoozedData: SnoozedData = {
    isSnoozed: showAfterData.isSnoozed === 'Y',
    isSnoozedBefore: isSnoozedBefore && showAfterData.isSnoozed === 'N',
    when: choose(
      showAfterData.isSnoozed === 'Y',
      t('snoozeUntilDate', {
        formatDate: formatSnoozeDate(
          parseDate(showAfterData.showAfterISO),
          i18n.language
        ),
      }),
      t('snoozedDate', {
        formatDate: formatDistance(
          parseDate(showAfterData.showAfterISO),
          new Date(),
          {
            addSuffix: true,
            locale: getDateLocale(),
          }
        ),
      })
    ) as string,
  };

  const [isFollowedUp, setIsFollowedUp] = useState(activity?.followUp === 'Y');
  const [isDone, setIsDone] = useState(activity?.done === 'Y');

  const onRefresh = async () => {
    await refetchActivity?.();
    commentsListRef.current?.refetch?.();
  };

  useIonViewWillEnter(() => {
    if (isActivityDetail) {
      void onRefresh();
    }
    setDidChange(false);
    setHideActivityFlags(false);
    setShowAfterData({
      showAfter: activity?.showAfter,
      showAfterISO: activity?.showAfterISO,
      isSnoozed: activity?.isSnoozed,
    });
    setIsFollowedUp(activity?.followUp === 'Y');
    setIsDone(activity?.done === 'Y');
    setIsToBeDeleted(false);
  }, []);

  useEffect(() => {
    setShowAfterData({
      showAfter: activity?.showAfter,
      showAfterISO: activity?.showAfterISO,
      isSnoozed: activity?.isSnoozed,
    });
    setIsFollowedUp(activity?.followUp === 'Y');
    setIsDone(activity?.done === 'Y');
  }, [activity]);

  useEffect(() => {
    setDisableActivityFlags(disableHeaderFlags);
  }, [disableHeaderFlags, activity?.userId, userId]);

  useEffect(() => {
    if (!isNil(activity) && activity.eventRead === 'N') {
      onMarkActivityAsRead({ historyId: activity.historyId });
    }
    dispatch(clearDeepLink());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activity]);

  const [showSnoozeModal, setShowSnoozeModal] = useState(false);

  const onSnoozeDone = (v: number) => {
    setDidChange(true);
    setShowAfterData({
      isSnoozed: 'Y',
      showAfter: formatDate(v * 1000, DateFormatEnum.showAfterTime),
      showAfterISO: formatDate(v * 1000, DateFormatEnum.ISO),
    });
  };
  const onUnSnooze = () => {
    setDidChange(true);
    setShowAfterData({
      isSnoozed: 'N',
      showAfter: activity?.creationTimestamp,
      showAfterISO: activity?.creationTimestampISO,
    });
  };

  const onFollowUp = () => {
    setDidChange(true);
    setIsFollowedUp((prev) => !prev);
  };

  const onUpdateActivityFlags = ({
    undoData,
    activityUpdate,
  }: UpdateActivityFlags) => {
    onUpdateActivity(activityUpdate);
    addToast({
      text: choose(activityUpdate.done, t('deleteActivity'), t('savedToast')),
      button: {
        role: 'undo',
        text: t('common:undo'),
        handler: () => onUpdateActivity(undoData),
      },
      testid: 'update-activity-toast',
    });
  };

  const onDeleteActivity = () => {
    setIsDone((prev) => !prev);
    onUpdateActivityFlags({
      undoData: { done: activity?.done === 'Y' },
      activityUpdate: { done: true },
    });
    goBack();
  };

  const onComplete = () => {
    if (didChange) {
      setCloseModalIsOpen(true);
      setIsToBeDeleted(true);
    } else {
      onDeleteActivity();
    }
  };

  const disableActionButtons = or(
    isDone,
    and(
      or(
        isBusy,
        isLoading,
        isSubmitting,
        isActivityDetail,
        disableActivityFlags
      ),
      isNonLoggedInOwner
    )
  );

  const isSnoozedOption: ActionCardOption = {
    isActive: snoozedData.isSnoozed,
    activeClass: classes.activeSnooze,
    inActiveClass: classes.inActiveSnooze,
    icon: AppIcons.snoozedIcon(snoozedData.isSnoozed),
    disabled: disableActionButtons,
    variant: 'primary',
    text: t('snooze'),
    onClick: choose(snoozedData.isSnoozed, onUnSnooze, () =>
      setShowSnoozeModal(true)
    ),
    testid: `snooze-button`,
  };

  const onDeleteOption: ActionCardOption = {
    isActive: isDone,
    activeClass: classes.activeDelete,
    inActiveClass: classes.inActiveDelete,
    icon: AppIcons.doneIcon(isDone),
    disabled: or(
      isLoading,
      crmHasOpenStatus(activity),
      disableActivityFlags,
      isNonLoggedInOwner,
      isDone
    ),
    variant: 'success',
    text: t('common:delete'),
    onClick: onComplete,
    testid: `done-button`,
  };

  const onFollowUpOption: ActionCardOption = {
    isActive: isFollowedUp,
    activeClass: classes.activeFollowUp,
    inActiveClass: classes.inActiveFollowUp,
    icon: AppIcons.followUpIcon(isFollowedUp),
    disabled: disableActionButtons,
    variant: 'primary',
    text: t('followUp'),
    onClick: onFollowUp,
    testid: `follow-up-button`,
  };
  // #endregion

  // #region update activity
  const onSubmit = () => {
    onUpdateActivityFlags({
      undoData: {
        done: activity?.done === 'Y',
        followUp: activity?.followUp === 'Y',
        showAfter: activity?.showAfterISO,
      },
      activityUpdate: {
        done: isDone,
        followUp: isFollowedUp,
        showAfter: showAfterData.showAfterISO,
      },
    });

    goBack();
  };
  // #endregion

  const [saveFromOption, setSaveFromOption] = useState<(h?: string) => void>();
  const headerActions = activityConfig?.modal?.headerActions?.(didChange);
  const isHighPriority = activity?.extendedInfo?.priority === highPriorityTask;
  const showHeaderAction = and(
    size(headerActions) > 0,
    or(isActivityDetail, and(!isActivityDetail, didChange))
  );
  const showHighPriorityLabel = isHighPriority && !showHeaderAction;

  // #region comments
  const [comment, setComment] = useState('');
  const [focusComment, setFocusComment] = useState(false);
  const [editComment, setEditComment] = useState<UpdateActivityNoteBody>();
  const [afterSubmit, setAfterSubmit] = useState(false);
  const [commentRerender, setCommentRerender] = useState(0);
  const inputRef = useRef<InputRef>(null);

  const hasActivityData = and(!!activity, isActivityDetail);
  const hasComments = and(hasActivityData, activityConfig?.hasComments);
  const hasNotes = and(hasActivityData, !activityConfig?.hasComments);
  const hasTasks = and(hasActivityData, !isEmpty(activity?.extendedInfo?.task));
  const hasEditComment = !!editComment?.noteId;
  const isCRMDisabled = crmDisableComments(activity);

  const focusInput = () => {
    getIntervalRender({
      timeout: 150,
      fn: () => inputRef.current?.focusInput(),
    });
  };

  const resetComments = () => {
    setComment('');
    setEditComment(undefined);
    setCommentRerender(Date.now());
  };

  const {
    status: addCommentStatus,
    onAddActivityNote,
    data: addedComment,
  } = useAddActivityNote({
    historyId,
    miLoc,
  });

  const { status: updateCommentStatus, onUpdateActivityNote } =
    useUpdateActivityNote({ historyId, miLoc });

  const commentStatus = choose(
    hasEditComment,
    updateCommentStatus,
    addCommentStatus
  );
  const submittingComment = or(commentStatus === 'loading', isCRMDisabled);
  const disableSaveComment = or(
    submittingComment,
    !trim(comment),
    isCRMDisabled
  );

  const scrollComments = (noteId = '') => {
    getIntervalRender({
      timeout: 300,
      fn: () => {
        const commentNode = document.querySelector(
          `[data-testid=comment-${noteId}]`
        );
        if (commentNode) {
          onScroll(commentNode);
        } else {
          document
            .querySelector(`[data-testid=comments-scroll-end]`)
            ?.scrollIntoView({ block: 'nearest', behavior: 'smooth' });
        }
      },
    });
  };

  useEffect(() => {
    ifFunction(addCommentStatus === 'success', () => {
      setAfterSubmit(true);
      scrollComments(addedComment?.ID);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addCommentStatus]);

  useEffect(() => {
    ifFunction(updateCommentStatus === 'success', () => {
      setAfterSubmit(true);
      scrollComments(editComment?.noteId);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateCommentStatus]);

  useEffect(() => {
    ifFunction(afterSubmit, () => {
      resetComments();
      setAfterSubmit(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [afterSubmit]);

  useEffect(() => {
    ifFunction(hasEditComment, () => {
      setComment(toString(editComment?.text));
    });
  }, [editComment?.text, hasEditComment]);
  // #endregion comments

  const customBackButtonClick = () => {
    if (or(didChange, !!trim(comment))) {
      setCloseModalIsOpen(true);
    } else {
      goBack();
    }
  };

  const options = map(headerActions?.options, (item) => {
    const option = item as unknown as React.ComponentProps<typeof Button> & {
      buildHref?: (h?: string) => string;
    };
    const onSaveFromOption = (newId: string) => {
      setSaveFromOption(undefined);
      const href = toString(or(option.buildHref?.(newId), option.href));
      setTimeout(() => {
        if (newId) {
          history.replace(href);
        } else {
          history.push(href);
        }
        // TUDU: hardcoded to give attachments time for upload,
        // this just improves UX, attachments will always be up-to-date due to refetch logic
      }, 1000);
    };
    return {
      ...option,
      onClick: (e: React.MouseEvent<HTMLIonButtonElement, MouseEvent>) => {
        if (didChange) {
          componentRef.current?.onSubmit();
          setSaveFromOption(() => onSaveFromOption);
          return;
        }
        const href = toString(or(option.buildHref?.(), option.href));
        history.push(href);
        option.onClick?.(e);
      },
    };
  });

  const availHeaderActions = (testid: string) =>
    showHeaderAction
      ? {
          initialBreakpoint: 0.3,
          title: t('activities:manageActivity'),
          testid: `${testid}-actions`,
          options,
        }
      : undefined;

  const activityTitle = choose(
    isActivityDetail,
    toString(activityConfig?.modal?.title),
    t('snapshot:logNewVisit')
  );

  return (
    <IonPage id="activity-detail">
      <Header
        backButton={{ onClick: () => customBackButtonClick() }}
        testid="activity-detail-header"
        title={activityTitle}
        subTitle={customerName}
        headerActions={availHeaderActions('activity-detail-header')}
      />
      <IonContent className={classes.content} ref={nodeRef}>
        <Refresher
          slot="fixed"
          onRefresh={onRefresh}
          testid="activity"
          disabled={or(!isActivityDetail, isLoading)}
          hidden
        />
        <div className={classes.activityComponent}>
          <Header
            testid="activity-detail-page-header"
            className={classes.activityHeader}
            collapse="condense"
            customTitle={
              <>
                <div className={classNames(classes.flex, classes.titleHeader)}>
                  <Text
                    text={activityTitle}
                    variant="mipro-h1-headline"
                    testid="activity-detail-title"
                    className={classes.title}
                  />
                  {ifRender(
                    showHighPriorityLabel,
                    <Badge
                      type="error"
                      icon="flag"
                      text={t('activities:highPriority')}
                      testid="on-hold"
                      className={classes.badge}
                      textVariant="content-smaller"
                      textClassName={classNames(
                        classes.linePrefixText,
                        classes.priority
                      )}
                      iconClassName={classNames(
                        classes.prefixIcon,
                        classes.priority
                      )}
                      iconVariant="far"
                    />
                  )}
                </div>
                <div className={classes.flex}>
                  <SnapshotLink
                    className={classes.snapshotLink}
                    customerNameClass={classes.customerName}
                    customerNoClass={classes.customerNoClass}
                    eyebrow={
                      choose(
                        isActivityDetail,
                        activityDetailEyebrow,
                        customerVisitEyebrow
                      ) as string
                    }
                    customerPick12={activity?.customerPick12}
                    hasCorpGlobalIcon={withStringProp(activity?.natlAcctNo)}
                    href={customerURL}
                    text={customerName}
                    disabled={!ac}
                    withIcon={false}
                    testid="customer-snapshot-button"
                  />
                  {ifRender(
                    activity?.userId !== loggedInUserId,
                    <OwnerTitle
                      nonLoggedInOwnerEmpId={toString(activity?.employeeId)}
                      ownerName={activity?.userFullName}
                      link
                      testid="non-logged-in-employee"
                    />
                  )}
                </div>
                <div className={classes.headerWrapper}>
                  {ifRender(
                    description,
                    <Text
                      className={classes.content}
                      text={description}
                      textQuery={highlight}
                    />
                  )}
                  {!!CustomHeader && <CustomHeader />}
                </div>
              </>
            }
            headerActions={availHeaderActions('activity-detail-page-header')}
          />
          <div className={classes.spacing}>
            {ifRender(
              !hideActivityFlags,
              <div className={classes.actionsWrapper}>
                <IonRow className={classes.actions}>
                  {map(
                    [isSnoozedOption, onFollowUpOption, onDeleteOption],
                    (
                      { activeClass = '', inActiveClass = '', ...opt },
                      index
                    ) => (
                      <Button
                        key={index}
                        className={classNames(classes.actionButton, {
                          [activeClass]: opt.isActive,
                          [inActiveClass]: !opt.isActive,
                          [classes.actionDisabled]: opt.disabled,
                        })}
                        variant="icon-action"
                        icon={opt.icon}
                        text={opt.text}
                        onClick={opt.onClick}
                        disabled={opt.disabled}
                        testid={opt.testid}
                      />
                    )
                  )}
                </IonRow>
                <SnoozeTitle
                  className={classes.snoozeContainer}
                  snoozedData={snoozedData}
                  testid="visit-form"
                />
              </div>
            )}
          </div>
          <div ref={contentBeforeDetailsRef} />
          <div className={classes.detailCard}>
            <div className={classes.cardContent}>
              <div className={classes.header}>
                <Text
                  text={t('common:details')}
                  className={classes.title}
                  testid="details-activtity-header"
                />
              </div>
              <Loader
                testid="loader"
                className={classes.loader}
                text={t('common:loading')}
                isOpen={isLoading}
              />
              {!isLoading &&
                (!isNil(CustomContent) ? (
                  <CustomContent
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    ref={componentRef}
                    updateActivityData={{
                      done: isDone,
                      followUp: isFollowedUp,
                      showAfter: showAfterData.showAfterISO,
                    }}
                    onDone={(newId: unknown) => {
                      if (saveFromOption) {
                        saveFromOption?.(toString(newId));
                      } else {
                        setTimeout(() => goBack());
                      }
                    }}
                    didChange={didChange}
                    onContentChange={() => setDidChange(true)}
                    activity={{ ...activity, custMiLoc: miLoc, custNo: id }}
                    activityData={{
                      miLoc,
                      custNo: id,
                      type: activity?.eventTagName,
                      ...activityConfig?.modal?.defaultData,
                    }}
                    triggerRerender={commentRerender}
                    customerData={customerData}
                    isLoading={isLoading}
                    footerRef={footerRef}
                    hideActivityFlags={setHideActivityFlags}
                    disableActivityFlags={setDisableActivityFlags}
                    onUpdatingForm={setIsBusy}
                    onCompleteActivityFlag={setIsDone}
                    onFollowUpActivityFlag={setIsFollowedUp}
                    onUnSnooze={onUnSnooze}
                    onSendEmail={onSendEmail}
                    onContactClick={onOpenContactModal}
                    currency={activity?.currency}
                    contentBeforeDetailsRef={contentBeforeDetailsRef}
                    loggedInUserId={loggedInUserId}
                    onUpdateActivity={onUpdateActivity}
                  />
                ) : (
                  <ActionCardModalData
                    dataDefinition={defaultDataDefinition}
                    activityData={{
                      miLoc,
                      custNo: id,
                      type: activity?.eventTagName,
                      ...activityConfig?.modal?.defaultData,
                    }}
                    onSendEmail={onSendEmail}
                    onContactClick={onOpenContactModal}
                    currency={activity?.currency}
                    scrollParent={node}
                  />
                ))}
            </div>
          </div>
          {ifRender(
            hasComments,
            <CommentList
              ref={commentsListRef}
              historyId={activity?.historyId}
              eventUserId={choose(!isNonLoggedInOwner, activity?.userId)}
              commentCount={activity?.commentCount}
              className={classes.card}
              scrollParent={node}
              isLoading={isLoading}
              disableComments={isCRMDisabled}
              onAddComment={() => {
                resetComments();
                focusInput();
                scrollComments();
              }}
              onEditComment={(v) => {
                setEditComment(v);
                focusInput();
                scrollComments(v.noteId);
              }}
            />
          )}
          {ifRender(
            hasTasks,
            <RelatedOpportunity
              activity={activity}
              testid="related-opportunities"
            />
          )}
          {ifRender(
            hasNotes && !activityConfig?.hideNotes,
            <ActivityNotesList
              historyId={toNumber(activity?.historyId)}
              miLoc={toString(activity?.custMiLoc)}
              eventUserId={choose(
                !isNonLoggedInOwner,
                toString(activity?.userId)
              )}
              className={classes.card}
              custNo={toString(activity?.custNo)}
              userId={toString(activity?.userId)}
            />
          )}
          <SnoozeModal
            isOpen={showSnoozeModal}
            setIsOpen={setShowSnoozeModal}
            onSnooze={onSnoozeDone}
            onUnSnooze={onUnSnooze}
            testid="snooze-modal"
            snoozedData={snoozedData}
          />
          <ConfirmDialog
            isOpen={closeModalIsOpen}
            setIsOpen={setCloseModalIsOpen}
            title={t('discardChanges')}
            text={t('cancelActivityMessage')}
            primaryText={t('cancelActivityNo')}
            secondaryText={t('cancelActivityYes')}
            onPrimaryClick={() => setIsToBeDeleted(false)}
            onSecondaryClick={() => {
              if (isToBeDeleted) {
                onDeleteActivity();
              } else {
                goBack();
              }
            }}
            testid="close-activity-modal"
          />
          <ContactModal
            isOpen={showContactModal}
            setIsOpen={setShowContactModal}
            customerData={
              { ...customerData, mainContact: { ...emailContact } } as Customer
            }
            enableTexting
            title={emailContactName}
            testid="contact-model-customer"
          />
          <SendEmailModal
            searchType="customer"
            miLoc={miLoc}
            id={id}
            isOpen={sendEmailModal}
            setIsOpen={setSendEmailModal}
            title={t('common:sendEmail')}
            testid="action-card-modal-send-email-modal"
            defaultRecipients={activityConfig?.modal?.contactEmail}
          />
        </div>
      </IonContent>
      <IonFooter>
        <IonToolbar>
          {ifRender(
            hasComments,
            <form
              onSubmit={async (e: React.SyntheticEvent) => {
                e.preventDefault();
                if (hasEditComment) {
                  onUpdateActivityNote({
                    noteId: editComment?.noteId,
                    title: editComment?.title,
                    text: comment,
                    successToastText: t('comments:updateCommentToast'),
                  });
                } else {
                  onAddActivityNote({
                    publicFlag: 'Y',
                    text: comment,
                    successToastText: t('comments:addCommentToast'),
                  });
                }
                await toggleKeyboard(false);
                scrollComments(editComment?.noteId);
              }}
            >
              <IonRow className={classes.commentRow}>
                <Input
                  ref={inputRef}
                  className={classes.commentInput}
                  placeholder={t('comments:writeComment')}
                  name="comment"
                  value={comment}
                  setValue={setComment}
                  onFocus={() => {
                    setTimeout(() => {
                      setFocusComment(true);
                      setCommentRerender(Date.now());
                    }, 100);
                  }}
                  onBlur={() => {
                    setTimeout(() => {
                      setFocusComment(false);
                      setCommentRerender(Date.now());
                    }, 100);
                  }}
                  disabled={submittingComment}
                  textarea
                  rows={1}
                  autogrow
                  focusScroll={false}
                  testid="new-comment-input"
                />
                {ifRender(
                  !hasEditComment,
                  <Button
                    variant="action"
                    icon={['fas', 'paper-plane-top']}
                    type="submit"
                    disabled={disableSaveComment}
                    testid="new-comment-button"
                  />
                )}
              </IonRow>
              {ifRender(
                hasEditComment,
                <div className={classes.footerButtons}>
                  <div className={classes.commentsButtonsWrapper}>
                    <Button
                      className={classes.button}
                      variant="action"
                      text={t('common:update')}
                      type="submit"
                      disabled={disableSaveComment}
                      testid="edit-comment"
                    />
                    <Button
                      className={classes.button}
                      variant="secondary"
                      text={t('common:cancel')}
                      onClick={resetComments}
                      testid="cancel-edit-comment"
                    />
                  </div>
                </div>
              )}
            </form>
          )}
          {ifRender(
            and(!focusComment, !hasEditComment),
            <div
              ref={footerRef}
              className={classes.footerButtons}
              data-testid="activity-page-footer"
            >
              {ifRender(
                or(size(buttons) > 0, !hideSaveButton),
                <div className={classes.buttonsWrapper}>
                  {map(buttons, (buttonProps, index) => (
                    <Button
                      className={classes.button}
                      key={index}
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...buttonProps}
                      onClick={buttonProps?.onClick}
                    />
                  ))}
                  {ifRender(
                    !hideSaveButton,
                    <Button
                      className={classes.button}
                      variant="action"
                      text={t('common:save')}
                      onClick={onSubmit}
                      disabled={or(
                        !didChange,
                        isSubmitting,
                        isNonLoggedInOwner
                      )}
                      testid={`${kebabCase(toString(title))}-save-button`}
                    />
                  )}
                </div>
              )}
            </div>
          )}
        </IonToolbar>
      </IonFooter>
    </IonPage>
  );
};

export default ActivityDetail;
