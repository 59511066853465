import { filter, isEmpty, isNil, map, omit, toString } from 'lodash';
import type { MutationStatus } from '@tanstack/react-query';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import type { CustomerVisitBody } from 'ActivitiesApp/models/CustomerVisit';
import useAPIUrl from 'api';
import { differenceInMinutes } from 'date-fns';
import { useAxios } from 'providers/AxiosProvider';
import { useToasts } from 'providers/ToastProvider';
import { findActivitiesQueryKey } from 'api/activities/useFindActivities';
import { findActivitiesV2QueryKey } from 'api/activities/useFindActivitiesV2';
import { findActivityFiltersQueryKey } from 'api/activities/useFindActivityFilters';
import { getActivityQueryKey } from 'api/activities/useGetActivity';
import useUploadFileAttachment from 'api/attachments/useUploadFileAttachment';
import {
  onSuccessMutation,
  doPromiseAPI,
  onErrorUpdate,
  onMutateUpdate,
  getPlaceholderData,
  getAPIHeadersV2,
} from 'api/helpers';
import type { UpdateMutationContext } from 'api/helpers';
import type { ActionCardActivity } from 'models/ActivityModels';
import { ToastType } from 'models/Toast';
import { formatDate, parseDate } from 'utils/date';

interface UseCustomerVisitProps {
  userId: string;
  miLoc: string;
  id: string;
  historyId?: number;
}

interface CustomerVisitAPIReponse {
  historyId: string;
}

interface UseCustomerVisitResponse {
  data?: CustomerVisitAPIReponse;
  status: MutationStatus;
  onCustomerVisit: (body: CustomerVisitBody) => void;
}

const useCustomerVisit = ({
  userId,
  miLoc,
  id,
  historyId,
}: UseCustomerVisitProps): UseCustomerVisitResponse => {
  const { axios } = useAxios();
  const { customerVisitAPI } = useAPIUrl();
  const queryClient = useQueryClient();
  const { addToast } = useToasts();

  const getUpdatedFlags = ({
    showAfter,
    done,
    followUp,
    estimatedValue,
  }: CustomerVisitBody) => {
    const requestBody: Record<string, string> = {};
    if (showAfter) {
      requestBody.showAfter = showAfter;
    }
    if (!isNil(done)) {
      requestBody.done = done ? 'Y' : 'N';
    }
    if (!isNil(followUp)) {
      requestBody.followUp = followUp ? 'Y' : 'N';
    }
    requestBody.estimatedValue = isEmpty(estimatedValue)
      ? '0.0'
      : toString(estimatedValue);
    return requestBody;
  };

  const { uploadAttachment } = useUploadFileAttachment({
    domain: 'mprovisi',
    // TODO: api is hardcoding these values
    miLoc: 'EXEC',
    ctlNo: userId,
  });

  const doCustomerVisit = (body: CustomerVisitBody) => {
    return doPromiseAPI<CustomerVisitAPIReponse>(async (cancelToken) => {
      const contacts = map(
        body.contacts,
        ({ sequenceNo, miLoc: itemMiLoc, customerNo, delete: cDelete }) => ({
          sequenceNo,
          miLoc: itemMiLoc,
          customerNo,
          delete: cDelete,
        })
      );
      const request = {
        ...body,
        visitDate: formatDate(body.visitDate, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"),
        ...getUpdatedFlags(body),
        contacts,
        ...(historyId ? { historyId } : undefined),
      };
      const { data } = await axios.post<CustomerVisitAPIReponse>(
        customerVisitAPI(userId, miLoc, id),
        omit(request, 'filesForUpload', 'visitDateWithZimeZoneCreated'),
        {
          cancelToken,
          headers: { ...getAPIHeadersV2() },
        }
      );
      return data;
    });
  };

  const response = useMutation(doCustomerVisit, {
    onMutate: async (vars) => {
      const cachedActivity = getPlaceholderData<ActionCardActivity>({
        queryClient,
        queryKey: findActivitiesQueryKey,
        findPredicate: { historyId, userId },
      });

      const cachedMyActivity = getPlaceholderData<ActionCardActivity>({
        queryClient,
        queryKey: findActivitiesV2QueryKey,
        findPredicate: { historyId, userId },
      });
      const updatedContacts = map(
        filter(vars.contacts, ({ delete: cDelete }) => !cDelete),
        ({ sequenceNo, name }) => ({
          contactSeqNo: sequenceNo,
          contactName: name,
        })
      );
      const updatedNote = {
        notebookText: vars.notebook?.text,
        notebookTitle: vars.notebook?.title,
      };
      const updatedActivity = getUpdatedFlags(vars);

      if (updatedActivity.showAfter) {
        updatedActivity.isSnoozed =
          differenceInMinutes(
            parseDate(updatedActivity.showAfter),
            parseDate(cachedActivity?.creationTimestampISO)
          ) > 0
            ? 'Y'
            : 'N';
        updatedActivity.showAfterISO = updatedActivity.showAfter;
      }
      const activitiesContext = await onMutateUpdate<ActionCardActivity>({
        queryClient,
        queryKey: findActivitiesQueryKey,
        updatedItems: [
          {
            ...cachedActivity,
            ...updatedActivity,
            extendedInfo: {
              rating: vars.rating,
              visitDate: formatDate(parseDate(vars.visitDate), 'ISO'),
              contacts: updatedContacts,
              ...updatedNote,
            },
            historyId,
          } as ActionCardActivity,
        ],
        findPredicates: [{ historyId }],
        isInfiniteQuery: true,
      });

      const myVisitsContext = await onMutateUpdate<ActionCardActivity>({
        queryClient,
        queryKey: findActivitiesV2QueryKey,
        updatedItems: [
          {
            ...cachedMyActivity,
            ...updatedActivity,
            extendedInfo: {
              rating: vars.rating,
              visitDate: formatDate(parseDate(vars.visitDate), 'ISO'),
              contacts: updatedContacts,
              ...updatedNote,
            },
            historyId,
          } as ActionCardActivity,
        ],
        dataPath: 'rows',
        findPredicates: [{ historyId }],
        isInfiniteQuery: true,
      });
      return { activitiesContext, myVisitsContext };
    },
    onSuccess: (data, vars) => {
      void onSuccessMutation(queryClient, findActivityFiltersQueryKey);
      void onSuccessMutation(queryClient, findActivitiesQueryKey);
      void onSuccessMutation(queryClient, findActivitiesV2QueryKey);
      if (historyId) {
        // TODO timeout needed for reedirect to work in activity detail options
        // adding formik implementation should remove this
        setTimeout(() => {
          void onSuccessMutation(queryClient, getActivityQueryKey, {
            userId,
            historyId,
          });
        });
      }

      if (!historyId) {
        map(vars.filesForUpload, (file) => {
          uploadAttachment({
            file,
            lineNo: toString(data.historyId),
            src: file.fileURL,
          });
        });
      }
    },
    onError: (error, vars, context) => {
      addToast({
        type: ToastType.error,
        text: 'Rate visit operation failed. Please try again later.',
        testid: 'rate-visit-error-toast',
      });
      onErrorUpdate<ActionCardActivity>({
        queryClient,
        context:
          context?.activitiesContext as UpdateMutationContext<ActionCardActivity>[],
        isInfiniteQuery: true,
      });

      onErrorUpdate<ActionCardActivity>({
        queryClient,
        context:
          context?.myVisitsContext as UpdateMutationContext<ActionCardActivity>[],
        isInfiniteQuery: true,
      });
    },
  });

  const { data, status, mutate } = response;

  return {
    data,
    status,
    onCustomerVisit: (body: CustomerVisitBody) => mutate(body),
  };
};

export default useCustomerVisit;
