import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import {
  find,
  head,
  includes,
  isEmpty,
  isNil,
  map,
  size,
  toString,
} from 'lodash';
import List from 'common/components/List/List';
import { and, choose, or } from 'common/utils/logicHelpers';
import useUpdateHeader from 'ProductSearchApp/api/checkout/useUpdateHeader';
import useAddToCart from 'ProductSearchApp/api/useAddToCart';
import useFindProducts from 'ProductSearchApp/api/useFindProducts';
import AdvancedSearchModal from 'ProductSearchApp/components/AdvancedSearchModal/AdvancedSearchModal';
import ProductListItem from 'ProductSearchApp/components/ProductListItem/ProductListItem';
import SelectCustomerBar from 'ProductSearchApp/components/SelectCustomerBar/SelectCustomerBar';
import type {
  AdvancedSearchForm,
  Product,
} from 'ProductSearchApp/models/Products';
import type { ProductSearchByOptionsProp } from 'ProductSearchApp/util/productSearchUtil';
import {
  isCustomerLess,
  productSelectCheckboxOptions,
  useHandleCustomerUpdate,
} from 'ProductSearchApp/util/productSearchUtil';
import useGetCustomer from 'api/customer/useGetCustomer';
import type { SelectModalItem } from 'models/Search';
import type { RootState } from 'store/reducers';
import { setProductSearchCustomer, clearCurrentCartCustomer } from 'store/user';
import { getErrorMessage } from 'utils/helpers';
import { removeLeadingZeros } from 'utils/number';
import {
  goToAddZCodedItem,
  goToProductSearch,
} from 'navigation/navigationHelpers';
import Button from 'components/Button/Button';
import CheckBox from 'components/CheckBox/CheckBox';
import DiscardModal from 'components/Modals/DiscardModal/DiscardModal';
import Refresher from 'components/Refresher/Refresher';
import classes from './ProductSearchResult.module.scss';

interface ProductSearchResultProp {
  miLoc: string;
  id: string;
  triggerAddZcodedItem?: number;
  contentNode?: HTMLElement | null;
  searchMode: ProductSearchByOptionsProp | undefined;
  searchQuery: string;
  searchQueryValue: string;
  productSearchView?: boolean;
  productSearchByOptions: ProductSearchByOptionsProp[];
  setSearchQuery: (searchQuery: string) => void;
  setSearchMode: (searchMode: ProductSearchByOptionsProp | undefined) => void;
}

const ProductSearchResult = ({
  miLoc,
  id,
  searchQueryValue,
  searchQuery,
  triggerAddZcodedItem,
  contentNode,
  searchMode,
  productSearchView = false,
  productSearchByOptions,
  setSearchQuery,
  setSearchMode,
}: ProductSearchResultProp): JSX.Element => {
  const isCustomerLessSearch = isCustomerLess(id);
  const { data: customerData } = useGetCustomer({
    miLoc,
    id,
    searchType: 'customer',
    enabled: !isCustomerLessSearch,
  });
  const dispatch = useDispatch();

  const { productSearchCustomer, showCostDetails, currentCartCustomer } =
    useSelector((state: RootState) => state.user);
  const cartMiLoc = toString(currentCartCustomer?.miLoc);
  const cartOcn = toString(currentCartCustomer?.orderCtlNo);
  const cartCustomerNo = toString(currentCartCustomer?.shipToCustNo);
  const { t } = useTranslation();
  const history = useHistory();
  const namespace = 'productSearch';
  const productSearchNameSpace = 'ProductSearchApp-Search';

  const zCodedNameSpace = 'productSearch:zCodedItem';
  const [diffCartCustomerIsOpen, setDiffCartCustomerIsOpen] = useState(false);
  const [abandonCartModalIsOpen, setAbandonCartModalIsOpen] = useState(false);

  const [selectedOptions, setSelectedOptions] = useState({
    group1: 'corp',
    group2: '',
  });
  const [advancedSearchFields, setAdvancedSearchFields] =
    useState<AdvancedSearchForm>();

  const [isOpenAdvancedSearch, setIsOpenAdvancedSearch] =
    useState<boolean>(false);

  useEffect(() => {
    if (!isCustomerLessSearch && !productSearchView) {
      dispatch(
        setProductSearchCustomer({
          productSearchCustomer: {
            miLoc,
            customerId: id,
            group1: selectedOptions.group1,
            group2: selectedOptions.group2,
            searchQuery: searchQueryValue,
            searchMode: searchMode?.id,
            advancedSearchFields,
          },
        })
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    miLoc,
    id,
    searchQueryValue,
    selectedOptions.group1,
    selectedOptions.group2,
    searchMode?.id,
    advancedSearchFields,
    isCustomerLessSearch,
    productSearchView,
  ]);

  const isCached =
    productSearchCustomer?.miLoc === miLoc &&
    productSearchCustomer.customerId === id;

  useEffect(() => {
    if (!isCustomerLessSearch && !productSearchView) {
      if (isCached) {
        setSearchQuery(toString(productSearchCustomer.searchQuery));
        setSelectedOptions({
          group1: toString(productSearchCustomer.group1),
          group2: toString(productSearchCustomer.group2),
        });
        const searchModeObj = find(
          productSearchByOptions,
          (item) => item.id === productSearchCustomer.searchMode
        );
        setSearchMode(searchModeObj);
        setAdvancedSearchFields(
          productSearchCustomer.advancedSearchFields as AdvancedSearchForm
        );
      } else {
        setSearchQuery('');
        setSelectedOptions({
          group1: 'corp',
          group2: '',
        });
        setSearchMode(head(productSearchByOptions));
        setAdvancedSearchFields(undefined);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, isCustomerLessSearch]);

  let enabled = or(!!searchQueryValue, !!advancedSearchFields?.mfrCtlNo?.key);

  if (
    isCached &&
    !isEmpty(searchQuery) &&
    !isEmpty(advancedSearchFields?.mfrCtlNo)
  ) {
    enabled = and(!!searchQueryValue, !!advancedSearchFields?.mfrCtlNo?.key);
  }

  const {
    products,
    totalRows,
    error,
    isLoading,
    isEmptyResponse,
    noMoreData,
    dataUpdatedAt,
    fetchNextPage,
    refetch,
  } = useFindProducts({
    miLoc,
    customerNo: id,
    query: searchQueryValue,
    inStockOnly: selectedOptions.group1 === 'corp',
    limitToBranch: selectedOptions.group1 === 'branch',
    limitToCustomer: selectedOptions.group2 === 'cust',
    branchItemBalance: selectedOptions.group2 === 'sales',
    searchMode: searchMode?.id,
    returnSCAvail: true,
    mfrCtlNo: advancedSearchFields?.mfrCtlNo?.key,
    enabled,
  });

  let title = t(`${namespace}:results`, { size: totalRows });
  if (searchQueryValue) {
    title = t(`${namespace}:products`, {
      size: totalRows,
      query: searchQueryValue,
    });
  }

  const addZCodedItem = useCallback(() => {
    const isCartHeadless = isCustomerLess(currentCartCustomer?.shipToCustNo);
    const differentCartCustomer = and(
      !isEmpty(currentCartCustomer),
      or(
        currentCartCustomer?.miLoc !== customerData?.miLoc,
        currentCartCustomer?.shipToCustNo !== customerData?.customerNo
      )
    );

    if (isCartHeadless) {
      setAbandonCartModalIsOpen(true);
      return;
    }

    if (differentCartCustomer) {
      setDiffCartCustomerIsOpen(true);
      return;
    }

    history.push(
      goToAddZCodedItem({
        miLoc,
        shipToCustNo: id,
        orderCtlNo: currentCartCustomer?.orderCtlNo,
        reset: true,
      })
    );
  }, [
    currentCartCustomer,
    customerData?.customerNo,
    customerData?.miLoc,
    history,
    id,
    miLoc,
  ]);

  useEffect(() => {
    if (triggerAddZcodedItem) {
      addZCodedItem();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerAddZcodedItem]);

  const handleCheckboxChange = (option: string) => {
    setSelectedOptions((prevSelectedItems) => {
      if (includes(['branch', 'corp'], option)) {
        return {
          ...prevSelectedItems,
          group1: prevSelectedItems.group1 === option ? '' : option,
        };
      }
      if (includes(['cust', 'sales'], option)) {
        return {
          ...prevSelectedItems,
          group2: prevSelectedItems.group2 === option ? '' : option,
        };
      }
      return prevSelectedItems;
    });
  };

  const renderProductItem = (itemIndex: number, item: Product) => (
    <ProductListItem
      productItem={item}
      key={`${item.itemNumber}-${itemIndex}`}
      miLoc={miLoc}
      customerId={id}
      testid={`${item.itemNumber}-${itemIndex}`}
      showGpPercent={showCostDetails}
    />
  );

  const isCustorEligibleSearch = or(!isCustomerLessSearch, productSearchView);
  const { status: addCartStatus, onAddToCart } = useAddToCart();
  const { onUpdateHeader, status: updateHeaderStatus } = useUpdateHeader({
    miLoc: cartMiLoc,
    orderCtlNo: cartOcn,
    shipToCustNo: '',
    billToCustNo: '',
  });

  useEffect(() => {
    if (
      and(
        !!currentCartCustomer?.orderCtlNo,
        !isCustomerLess(cartCustomerNo),
        or(updateHeaderStatus === 'success', addCartStatus === 'success')
      )
    ) {
      history.push(goToProductSearch(cartMiLoc, cartCustomerNo));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateHeaderStatus, addCartStatus, currentCartCustomer?.orderCtlNo]);

  const { doOcnCustomerUpdate } = useHandleCustomerUpdate();

  const setCustomerInfo = async (o: SelectModalItem) => {
    if (o.key) {
      const selectedMiLoc = o.key.substring(0, 4);
      const selectedCustNo = o.key.substring(4);
      dispatch(
        setProductSearchCustomer({
          productSearchCustomer: {
            miLoc: selectedMiLoc,
            customerId: selectedCustNo,
            group1: selectedOptions.group1,
            group2: selectedOptions.group2,
            searchQuery: searchQueryValue,
            searchMode: searchMode?.id,
            advancedSearchFields,
          },
        })
      );
      await doOcnCustomerUpdate({
        orderCtlNo: cartOcn,
        cartMiLoc,
        miLoc: selectedMiLoc,
        shipToCustNo: selectedCustNo,
        customerName: o?.title,
        href: goToProductSearch(selectedMiLoc, selectedCustNo),
        onUpdateHeader,
        onAddToCart,
      });
    }
  };

  return (
    <>
      {isCustorEligibleSearch && (
        <div
          className={classNames(classes.filterWrapper, {
            [classes.customerLessFilter]: productSearchView,
          })}
        >
          <div className={classes.checkboxWrapper}>
            {map(productSelectCheckboxOptions, (selectItem, index) => (
              <CheckBox
                key={`${selectItem}-${index}`}
                testid={`${selectItem}-${index}-checkbox`}
                name={selectItem}
                label={selectItem}
                disabled={and(isCustomerLessSearch && selectItem === 'cust')}
                checked={
                  includes(['branch', 'corp'], selectItem)
                    ? selectedOptions.group1 === selectItem
                    : selectedOptions.group2 === selectItem
                }
                onChange={() => {
                  handleCheckboxChange(selectItem);
                }}
                labelTextVariant="mipro-body-copy"
                className={classes.checkbox}
              />
            ))}
          </div>
          <div className={classes.advancedSearchWrapper}>
            <Button
              testid="product-advanced-search"
              text={
                advancedSearchFields
                  ? toString(size(advancedSearchFields))
                  : t('common:filter')
              }
              leftIcon={['far', 'sliders']}
              className={classNames(classes.filterButton, {
                [classes.hasFilters]: !isNil(advancedSearchFields),
              })}
              variant="clear"
              onClick={() => {
                setIsOpenAdvancedSearch(true);
              }}
            />
          </div>
        </div>
      )}
      <Refresher
        slot="fixed"
        onRefresh={refetch}
        hidden
        testid="product-search-refresher"
        disabled={isLoading}
        lastUpdatedAt={dataUpdatedAt}
      />
      <SelectCustomerBar
        testid="product-search-result"
        isCustomerLessSearch={isCustomerLessSearch}
        setCustomerInfo={setCustomerInfo}
      />
      {isCustorEligibleSearch && (
        <List
          className={classes.list}
          title={title}
          data={products}
          itemContent={renderProductItem}
          scrollParent={contentNode}
          isLoading={{
            isLoading,
            className: classes.loader,
            text: t(`${namespace}:loading`),
          }}
          isEmptyList={{
            isEmptyList: isEmptyResponse,
            title: toString(
              choose(
                or(searchQueryValue, advancedSearchFields?.mfrCtlNo?.key),
                t('product:noProductsFound'),
                t(`${namespace}:noResults`)
              )
            ),
            body: t(`${namespace}:noResultsMessage`),
            children: !isCustomerLessSearch ? (
              <div className={classes.zCodeBtn}>
                <Button
                  text={t(`${zCodedNameSpace}:addZCodedItem`)}
                  testid="add-zcoded-item"
                  variant="mipro-action"
                  onClick={addZCodedItem}
                />
              </div>
            ) : undefined,
          }}
          isError={{
            isError: !!error,
            title: getErrorMessage(error, t(`${namespace}:noResults`)),
            body: t(`${namespace}:noResultsMessage`),
          }}
          isEndOfList={noMoreData}
          endReached={fetchNextPage}
          testid="products-list"
        />
      )}

      <AdvancedSearchModal
        isOpen={isOpenAdvancedSearch}
        setIsOpen={setIsOpenAdvancedSearch}
        testid="advanced-search-filter"
        setAdvancedSearch={(fields) => {
          setAdvancedSearchFields(fields);
        }}
        advancedSearchFields={advancedSearchFields}
      />
      <DiscardModal
        title={t(`${productSearchNameSpace}:differentOcnCustomerTitle`, {
          customerName: customerData?.name,
        })}
        isOpen={diffCartCustomerIsOpen}
        setIsOpen={setDiffCartCustomerIsOpen}
        withRightCloseButton
        testid="different-cart-customer-modal"
        onGoBackClick={() => {
          dispatch(clearCurrentCartCustomer());
          setDiffCartCustomerIsOpen(false);
          history.push(
            goToAddZCodedItem({
              miLoc,
              shipToCustNo: id,
              reset: true,
            })
          );
        }}
        goBackButtonTitle={t(`${zCodedNameSpace}:createNewOCN`)}
        discardButtonTitle={t(`${namespace}:cancel`)}
        discardMsg={t(`${zCodedNameSpace}:differentCustomer`, {
          ocn: currentCartCustomer?.orderCtlNo,
          customerName: currentCartCustomer?.customerName,
          newCustomerName: customerData?.name,
        })}
      />
      <DiscardModal
        title={t('ProductSearchApp-Search:abandonOcn')}
        discardMsg={t('ProductSearchApp-Search:abandonOcnMessage', {
          ocn: removeLeadingZeros(currentCartCustomer?.orderCtlNo),
        })}
        initialBreakpoint={0.35}
        withRightCloseButton
        setIsOpen={setAbandonCartModalIsOpen}
        isOpen={abandonCartModalIsOpen}
        testid="abandon-ocn-modal"
        discardButtonTitle={t('cancel')}
        goBackButtonTitle={t('ProductSearchApp-Search:abandonOcn')}
        onGoBackClick={() => {
          dispatch(clearCurrentCartCustomer());
          history.push(
            goToAddZCodedItem({
              miLoc,
              shipToCustNo: id,
              reset: true,
            })
          );
        }}
      />
    </>
  );
};

export default ProductSearchResult;
