import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';
import type { Dictionary } from 'lodash';
import {
  map,
  size,
  head,
  concat,
  toString,
  filter,
  flatten,
  uniq,
  includes,
  reduce,
  toNumber,
  find,
  orderBy,
} from 'lodash';
import { IonCol, IonContent, IonPage, IonRow } from '@ionic/react';
import type { AccordionSection } from 'common/components/Accordion/Accordion';
import Accordion from 'common/components/Accordion/Accordion';
import type { BaseComponentProps } from 'common/components/utils/renderHelpers';
import { useIonContentRef } from 'common/components/utils/renderHelpers';
import { and, choose, ifRender, or } from 'common/utils/logicHelpers';
import i18next from 'i18next';
import type { Territory, TerritoryLocation } from 'models/Territory';
import type { RootState } from 'store/reducers';
import { setAvpView } from 'store/user';
import { parseRollupLocation } from 'utils/territories';
import { useTerritoryUtils } from 'pages/Menu/Territories/Territories';
import Badge from 'components/Badge/Badge';
import Button from 'components/Button/Button';
import Header from 'components/Header/Header';
import Searchbar from 'components/Searchbar/Searchbar';
import Text from 'components/Text/Text';
import TitleLine from 'components/TitleLine/TitleLine';
import WarningMessage from 'components/WarningMessage/WarningMessage';
import classes from './TerritoriesTree.module.scss';

const getLocationName = (node: TerritoryLocation) => {
  const nodeName = `${toString(
    choose(and(node.locationType !== 'T'), `${node.miLoc} - `)
  )}${node.name}`;
  return toString(
    choose(node.avpView, i18next.t('common:myAvpView'), nodeName)
  );
};

interface AccordionHeaderProps extends BaseComponentProps {
  node: TerritoryLocation;
  query?: string;
}

const AccordionHeader = ({
  node,
  query,
  className,
  style,
}: AccordionHeaderProps) => {
  const { miLoc = '', isAvpView } = useSelector(
    (state: RootState) => state.user
  );
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const nodeMiLoc = node.miLoc;
  const selected = and(
    nodeMiLoc === miLoc,
    choose(node.avpView, isAvpView, !isAvpView)
  );

  const { setTerritory } = useTerritoryUtils();

  return (
    <div
      className={classNames(
        classes.ionItemAccordion,
        classes.ionItem,
        className
      )}
      style={style}
    >
      <Text
        className={classes.locName}
        textQuery={query}
        text={getLocationName(node)}
      />
      {ifRender(
        node.hasAccess,
        <Button
          text={choose(!selected, 'Set')}
          icon={choose(selected, ['fas', 'circle-check'])}
          variant="link"
          debounceClick={false}
          testid={`location-action-${nodeMiLoc}-button`}
          className={classNames(classes.button)}
          onClick={() => {
            setTerritory(
              nodeMiLoc,
              choose(node.avpView, t('common:myAvpView'), nodeMiLoc)
            );
            dispatch(setAvpView({ isAvpView: node.avpView }));
          }}
        />
      )}
    </div>
  );
};

interface RecursiveAccordionProps {
  node: TerritoryLocation;
  depth?: number;
  scrollParent?: HTMLElement;
  expandedLoc?: string[];
  setExpanded?: (d: number) => (i: string) => void;
}

const RecursiveAccordion = ({
  node,
  depth = 1,
  scrollParent,
  expandedLoc,
  setExpanded,
}: RecursiveAccordionProps) => {
  const nodeMiLoc = node.miLoc;

  return (
    <Accordion
      className={classes.accordion}
      value={expandedLoc}
      onChange={setExpanded?.(depth)}
      sections={map(orderBy(node.items, ['mi_loc']), (section) => {
        const hasChildren = size(section.items) > 0;
        const item = parseRollupLocation(section, true);
        const childMiLoc = item.miLoc;

        return {
          value: childMiLoc,
          title: {
            wrapperProps: {
              className: classNames(classes.accordionHeader, {
                [classes.leafNode]: !hasChildren,
              }),
              style: {
                top: 50 * depth,
                zIndex: 10 - depth,
                marginInlineStart: `${16 * depth}px`,
                paddingLeft: toString(choose(!hasChildren, '4px')),
              },
            },
            customContent: (
              <AccordionHeader
                className={classNames({
                  [classes.leafNode]: !hasChildren,
                  [classes.branchItem]: !includes(
                    ['BRCH', 'TEAM'],
                    item.userRole
                  ),
                })}
                node={item}
                style={choose(!hasChildren, {
                  marginInlineStart: `32px`,
                })}
              />
            ),
          },
          className: classes.accordionSection,
          content: ifRender(
            and(hasChildren, includes(expandedLoc, nodeMiLoc)),
            <RecursiveAccordion
              node={item}
              expandedLoc={expandedLoc}
              setExpanded={setExpanded}
              depth={depth + 1}
            />
          ),
        };
      })}
      scrollParent={scrollParent}
      testid={`location-tree-${nodeMiLoc}`}
    />
  );
};

const TerritoriesTree = (): JSX.Element => {
  const {
    miLoc = '',
    locationTree = {},
    rollupTree,
    fallbackMiLoc = '',
    isAvpUser,
    isAvpView,
  } = useSelector((state: RootState) => state.user);
  const { t } = useTranslation();
  const selectedMiLoc = locationTree[miLoc];

  const {
    searchQuery,
    searchQueryValue,
    setSearchQuery,
    goToLastVisitedURL,
    filteredTerritories,
    onSearch,
  } = useTerritoryUtils();

  const ExecMiLoc = head(rollupTree) as Territory;
  const { nodeRef, node } = useIonContentRef();

  const ExecLoc = parseRollupLocation(ExecMiLoc);
  const ExecView = {
    value: ExecLoc.miLoc,
    title: {
      customContent: (
        <AccordionHeader className={classes.rootNode} node={ExecLoc} />
      ),
      wrapperProps: {
        className: classNames(classes.accordionHeader, classes.leafNode),
        style: { zIndex: 10 },
      },
    },
  };

  const AvpView = {
    value: ExecLoc.miLoc,
    title: {
      customContent: (
        <AccordionHeader
          className={classes.rootNode}
          node={{
            ...parseRollupLocation(ExecMiLoc),
            avpView: true,
          }}
        />
      ),
      wrapperProps: {
        className: classNames(classes.accordionHeader, classes.leafNode),
        style: { zIndex: 10 },
      },
    },
  };
  const fallbackLoc = locationTree[fallbackMiLoc];
  const virtualTeamLoc = filter(ExecMiLoc.items, (i) => i.mi_loc === 'VT');

  const [expanded, setExpanded] = useState<Dictionary<string>>({});
  const expandedLoc = uniq(flatten(map(expanded)));

  useEffect(() => {
    setExpanded(
      reduce(
        selectedMiLoc.parents,
        (prev, i) => ({
          ...prev,
          [toNumber(locationTree[i].depth) - 1]: i,
        }),
        {}
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMiLoc.parents]);

  const setExpandedLoc = (depth: number) => (v: string) => {
    setExpanded((prev) => ({
      ...prev,
      [depth]: toString(choose(prev[depth] !== v, v)),
    }));
  };

  const locations = useMemo(() => {
    const fallbackParents = [...or(fallbackLoc?.parents, [])];
    let response = ExecMiLoc.items;
    while (size(fallbackParents) > 0) {
      let parent = fallbackParents?.shift();
      if (parent === 'EXEC') {
        parent = fallbackParents?.shift();
      }
      response = find(or(response, []), { mi_loc: parent })?.items;
    }
    return choose(size(response) > 0, response, ExecMiLoc.items) as Territory[];
  }, [ExecMiLoc.items, fallbackLoc?.parents]);

  const renderLocTree = (i: Territory) => {
    const item = parseRollupLocation(i, true);
    return {
      value: item.miLoc,
      title: {
        customContent: <AccordionHeader node={item} />,
        wrapperProps: {
          className: classNames(classes.accordionHeader, {
            [classes.leafNode]: !item.hasChildren,
          }),
          style: { zIndex: 10 },
        },
      },
      content: ifRender(
        and(item.hasChildren),
        <RecursiveAccordion
          node={item}
          expandedLoc={expandedLoc}
          setExpanded={setExpandedLoc}
        />
      ),
    };
  };

  return (
    <IonPage className={classes.Territories} data-testid="page-Territories">
      <Header
        testid="territoriesHead"
        title="Set Sales Territory"
        className={classes.header}
        onDismiss={() => goToLastVisitedURL()}
        hideHomeMenu
        theme="light"
        showCartIcon={false}
      >
        <Searchbar
          className={classes.input}
          value={searchQuery}
          placeholder="Search"
          setValue={setSearchQuery}
          onSearch={onSearch}
          testid="search-input"
        />
      </Header>
      <IonContent ref={nodeRef} className={classes.content}>
        <IonRow className={classes.locationBadgeRow}>
          <IonCol>
            <Badge
              type="info"
              text={getLocationName({ ...selectedMiLoc, avpView: isAvpView })}
              testid="selected-territory"
              className={classes.badge}
              textClassName={classes.badgeText}
            />
          </IonCol>
        </IonRow>
        <div className={classes.titleRow}>
          <Text text={t('common:myViews')} variant="mipro-h3-headline" />
          <TitleLine />
        </div>
        {ifRender(
          !searchQueryValue,
          <Accordion
            className={classes.accordion}
            value={expandedLoc}
            onChange={setExpandedLoc(0)}
            sections={concat(
              choose(isAvpUser, [AvpView], []) as AccordionSection[],
              choose(
                size(virtualTeamLoc) > 0,
                map(virtualTeamLoc, renderLocTree),
                []
              ) as AccordionSection[],
              choose(
                fallbackMiLoc === 'EXEC',
                [ExecView],
                []
              ) as AccordionSection[],
              map(locations, renderLocTree)
            )}
            scrollParent={node}
            testid="location-tree"
          />
        )}
        {ifRender(
          !!searchQueryValue,
          <Accordion
            className={classes.accordion}
            sections={concat(
              map(filteredTerritories, (s) => ({
                value: s.miLoc,
                title: {
                  customContent: (
                    <AccordionHeader
                      className={classes.leafNode}
                      node={s}
                      query={searchQueryValue}
                    />
                  ),
                  wrapperProps: {
                    className: classNames(
                      classes.accordionHeader,
                      classes.leafNode
                    ),
                  },
                },
                className: classes.accordionSection,
              }))
            )}
            scrollParent={node}
            testid="search-tree"
          />
        )}
        {ifRender(
          and(!!searchQueryValue, size(filteredTerritories) === 0),
          <WarningMessage
            title={t('common:noResults')}
            className={classes.warning}
          />
        )}
      </IonContent>
    </IonPage>
  );
};

export default TerritoriesTree;
