import type { AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';
import useAPIUrl from 'api';
import { useAxios } from 'providers/AxiosProvider';
import { doGetIsLoading, doPromiseAPI, useKeyUserId } from 'api/helpers';
import type { Employee } from 'models/Employee';
import type { QueryFlags } from 'models/Search';

const getTeamToShareWithQueryKey = 'team-to-share-with';

interface UseGetTeamToShareWithProps {
  miLoc: string;
  id: string;
}

interface UseGetTeamToShareWithResponse {
  data: Employee[] | undefined;
}

const useGetTeamToShareWith = ({
  miLoc,
  id,
}: UseGetTeamToShareWithProps): UseGetTeamToShareWithResponse & QueryFlags => {
  const { axios } = useAxios();
  const { getTeamToShareForCustomerAPI } = useAPIUrl();
  const { createQueryKey } = useKeyUserId();

  const doGetTeamToShareForCustomer = () => {
    return doPromiseAPI<Employee[]>(async (cancelToken) => {
      const { data } = await axios.get<Employee[]>(
        getTeamToShareForCustomerAPI(miLoc, id),
        { cancelToken }
      );
      return data;
    });
  };

  const response = useQuery<Employee[], AxiosError>(
    createQueryKey(getTeamToShareWithQueryKey, { miLoc, id }),
    doGetTeamToShareForCustomer
  );

  const { data: teamOfEmpsList, error: teamError } = response;

  return {
    error: teamError,
    data: teamOfEmpsList,
    isLoading: doGetIsLoading(response),
  };
};

export default useGetTeamToShareWith;
