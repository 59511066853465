import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import type { Dictionary } from 'lodash';
import { isNil, toString, toNumber, isEmpty, filter, first } from 'lodash';
import {
  IonCol,
  IonContent,
  IonFooter,
  IonPage,
  IonRow,
  IonToolbar,
} from '@ionic/react';
import Alert, { AlertVariantEnum } from 'common/components/Alert/Alert';
import { FormikInput } from 'common/components/Forms/Input/Input';
import CustomerName from 'common/components/Header/CustomerName';
import Header from 'common/components/Header/Header';
import { and, choose, or } from 'common/utils/logicHelpers';
import { FormikProvider, useFormik } from 'formik';
import useUpdateHeader from 'ProductSearchApp/api/checkout/useUpdateHeader';
import useAddToCart from 'ProductSearchApp/api/useAddToCart';
import useFindProductSources from 'ProductSearchApp/api/useFindProductSources';
import useGetOrder from 'ProductSearchApp/api/useGetOrder';
import ProductDetailTab from 'ProductSearchApp/components/ProductDetailTab/ProductDetailTab';
import ProductHeader from 'ProductSearchApp/components/ProductHeader/ProductHeader';
import ProductSSSTab from 'ProductSearchApp/components/ProductSSSTab/ProductSSSTab';
import ProductStockTab from 'ProductSearchApp/components/ProductStockTab/ProductStockTab';
import SelectCustomerBar from 'ProductSearchApp/components/SelectCustomerBar/SelectCustomerBar';
import useGetMtqOrMoqMessage from 'ProductSearchApp/hooks/useGetMtqOrMoqMessage';
import type { Order } from 'ProductSearchApp/models/Order';
import type { ProductDetailURLParams } from 'ProductSearchApp/models/Products';
import { canEditOCN, isZcodedItem } from 'ProductSearchApp/util/ocnHelpers';
import {
  isCustomerLess,
  isSalesBranch,
  useHandleCustomerUpdate,
} from 'ProductSearchApp/util/productSearchUtil';
import useGetProductDetail from 'api/customer/useGetProductDetailV2';
import useMiComConnection from 'api/external/useMiComConnection';
import {
  AccessControlType,
  useHasAccessControls,
} from 'hooks/useAccessControls';
import useChangeLocation from 'hooks/useChangeLocation';
import useGoBack from 'hooks/useGoBack';
import useShowCostDetailsDispatcher from 'hooks/useToggleCostDetailsDispatcher';
import type { SelectModalItem } from 'models/Search';
import type { RootState } from 'store/reducers';
import { clearCurrentCartCustomer } from 'store/user';
import { getErrorMessage } from 'utils/helpers';
import { findIcon } from 'utils/icons';
import { removeLeadingZeros } from 'utils/number';
import {
  goToOrderCart,
  goToProductDetail,
  goToProductHistory,
  goToProductSearch,
} from 'navigation/navigationHelpers';
import Button from 'components/Button/Button';
import Loader from 'components/Loader/Loader';
import DiscardModal from 'components/Modals/DiscardModal/DiscardModal';
import SendEmailModal from 'components/Modals/SendEmailModal/SendEmailModal';
import SegmentTabs from 'components/SegmentTabs/SegmentTabs';
import WarningMessage from 'components/WarningMessage/WarningMessage';
import { qtyScaleProps } from './EditOrderLine/EditOrderLineSchema';
import classes from './ProductSearchDetail.module.scss';

enum ProductTabEnum {
  detail = 'detail',
  sss = 'sss',
  stock = 'stock',
  interchange = 'interchange',
}

enum ProductDetailEnum {
  details = 'details',
  attributes = 'attributes',
  features = 'features',
}

enum ProductStockEnum {
  primary = 'primary',
  all = 'all',
}

interface AddQtyToOcnForm {
  quantity: number;
}

const ProductSearchDetail = (): JSX.Element => {
  const { locatorURL, isLocatorAvailable } = useChangeLocation();
  const { miLoc, id, productId, depth } = useParams<ProductDetailURLParams>();
  const history = useHistory();
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const fromCart = params.get('fromCart');
  const { t } = useTranslation('ProductSearchApp-Search');
  const { goBack } = useGoBack();
  const { toggleCostDetailsOption } = useShowCostDetailsDispatcher();
  const [tabValue, setTabValue] = useState<Dictionary<ProductTabEnum>>({});
  const [selectBranchModalOpen, setSelectBranchModalOpen] = useState(false);
  const [triggerCustomerSelect, setTriggerCustomerSelect] = useState(0);
  const [selectCustomerModalOpen, setSelectCustomerModalOpen] = useState(false);
  const [itemInCartModalIsOpen, setItemInCartModalIsOpen] = useState(false);
  const [diffCartCustomerModalIsOpen, setDiffCartCustomerModalIsOpen] =
    useState(false);
  const [createNewOCN, setCreateNewOCN] = useState(false);
  const [isAddingItem, setIsAddingItem] = useState(false);
  const [detailExpanded, setDetailExpanded] = useState<
    Dictionary<ProductDetailEnum>
  >({});
  const [stockExpanded, setStockExpanded] = useState<
    Dictionary<ProductStockEnum>
  >({});
  const [sendEmailModal, setSendEmailModal] = useState(false);

  const { hasAccessControl } = useHasAccessControls();

  const addToCartAccessControls = hasAccessControl(
    AccessControlType.EditOrdersAccessControls
  );
  const {
    currentCartCustomer,
    locationTree,
    loginMiLoc,
    miLoc: currentLoc,
  } = useSelector((state: RootState) => state.user);
  const cartMiLoc = toString(currentCartCustomer?.miLoc);
  const cartOcn = toString(currentCartCustomer?.orderCtlNo);
  const cartCustomerNo = toString(currentCartCustomer?.shipToCustNo);
  const dispatch = useDispatch();

  const {
    productData,
    customerData,
    isLoading: isDetailsLoading,
    error: detailsError,
  } = useGetProductDetail({ miLoc, id, productId });

  const { status, onAddToCart, itemIsAlreadyInCart } = useAddToCart();

  const { getOrder } = useGetOrder({});
  const formik = useFormik<AddQtyToOcnForm>({
    initialValues: {
      quantity: 1,
    },
    onSubmit: () => {},
  });

  const { values, setFieldValue } = formik;

  const isBranchLocation = isSalesBranch(locationTree, currentLoc);
  const isRollupLocBranch = isSalesBranch(locationTree, loginMiLoc);
  const isHeadlessCustomer = isCustomerLess(or(id, customerData?.customerNo));
  const isCartHeadless = isCustomerLess(currentCartCustomer?.shipToCustNo);

  const addItemToCart = () => {
    let addToCartMiLoc = choose(isBranchLocation, currentLoc);
    addToCartMiLoc = or(addToCartMiLoc, loginMiLoc);
    setDiffCartCustomerModalIsOpen(false);
    setItemInCartModalIsOpen(false);
    onAddToCart({
      customerName: customerData?.name,
      mfgPartNo: productData?.mfgPartNumber,
      customerInfo: {
        miLoc: choose(isHeadlessCustomer, addToCartMiLoc, miLoc) as string,
        customerNo: id,
      },
      itemList: [
        {
          itemNo: productData?.itemNumber,
          mino: productData?.mino,
          groupSerial: productData?.groupSerial,
          custStockNo: productData?.customerStockNumber,
          mfgPartNo: productData?.mfgPartNumber,
          quantity: values.quantity,
        },
      ],
    });
    setIsAddingItem(false);
  };

  useEffect(() => {
    if (status === 'success') {
      if (fromCart) {
        history.replace(
          goToOrderCart(
            currentCartCustomer?.miLoc,
            currentCartCustomer?.shipToCustNo,
            currentCartCustomer?.orderCtlNo
          )
        );
      } else if (!isCartHeadless && !isEmpty(currentCartCustomer)) {
        history.replace(
          goToProductSearch(
            currentCartCustomer?.miLoc,
            currentCartCustomer?.shipToCustNo
          )
        );
      } else {
        goBack();
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, currentCartCustomer?.orderCtlNo]);

  useEffect(() => {
    if (!currentCartCustomer && createNewOCN) {
      setCreateNewOCN(false);
      addItemToCart();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createNewOCN, currentCartCustomer]);

  const { getMiComProductURL } = useMiComConnection({
    miLoc,
    id,
    itemNumber: productData?.itemNumber,
  });

  const groupSerial = toString(productData?.groupSerial);
  const hasOpportunities = productData?.hasOpportunities === 'Y';
  const mfrCtlNo = toString(productData?.manufacturerCtlNo);
  const mainContact = customerData?.mainContact;

  const options = [
    { key: ProductTabEnum.stock, text: t('stock') },
    { key: ProductTabEnum.detail, text: t('detail') },
    { key: ProductTabEnum.interchange, text: t('interchange') },
  ];

  if (hasOpportunities) {
    options.push({ key: ProductTabEnum.sss, text: t('sss') });
  }

  const disableAddItem = isAddingItem || status === 'loading';

  const regularCustomerCriteria = or(
    currentCartCustomer?.miLoc !== customerData?.miLoc,
    currentCartCustomer?.shipToCustNo !== customerData?.customerNo
  );

  const isDifferentCartCustomer = choose(
    and(isHeadlessCustomer, isCartHeadless),
    false,
    regularCustomerCriteria
  );

  const verifyAddItemToCart = async (sameCart = false) => {
    let order: Order | undefined;
    const isCartEmpty = isEmpty(currentCartCustomer);
    const hasBranchFallback = isRollupLocBranch;

    setIsAddingItem(true);

    try {
      order = await getOrder?.(
        toString(currentCartCustomer?.miLoc),
        toString(currentCartCustomer?.orderCtlNo)
      );
    } catch (e) {
      setIsAddingItem(false);
    }

    // User can't switch location and does not have a branch
    if (
      and(
        !isLocatorAvailable,
        !isBranchLocation,
        !hasBranchFallback,
        isHeadlessCustomer
      )
    ) {
      setSelectCustomerModalOpen(true);
      setIsAddingItem(false);
      return;
    }

    // User does not have a branch but can switch location
    if (and(!isBranchLocation, !hasBranchFallback, isHeadlessCustomer)) {
      setSelectBranchModalOpen(true);
      setIsAddingItem(false);
      return;
    }

    if (
      and(
        !isDifferentCartCustomer,
        itemIsAlreadyInCart(order, productData?.itemNumber)
      )
    ) {
      setItemInCartModalIsOpen(true);
      setIsAddingItem(false);
      return;
    }

    if (
      or(
        isCartEmpty,
        and(!isCartEmpty, isCartHeadless, !isDifferentCartCustomer)
      )
    ) {
      addItemToCart();
      return;
    }

    if (and(!sameCart, isDifferentCartCustomer, isCartHeadless)) {
      setDiffCartCustomerModalIsOpen(true);
      setIsAddingItem(false);
      return;
    }

    if (and(!sameCart, isDifferentCartCustomer, !isCartHeadless)) {
      setDiffCartCustomerModalIsOpen(true);
      setIsAddingItem(false);
      return;
    }

    if (!canEditOCN(order)) {
      dispatch(clearCurrentCartCustomer());
      setTimeout(() => addItemToCart());
      setIsAddingItem(false);
      return;
    }
    addItemToCart();
  };

  const itemId = `${productId}-${depth}`;
  const selectedTab = tabValue[itemId] || ProductTabEnum.stock;

  const primarySourcesResponse = useFindProductSources({
    miLoc,
    id,
    productId,
    groupSerial,
    mfrCtlNo,
    enabled: !isEmpty(groupSerial) && !isEmpty(mfrCtlNo),
    allLocation: false,
    primarySource: true,
  });

  const firstPrimarySource = first(
    filter(primarySourcesResponse.items, ({ locType }) => locType === 'W')
  );

  const { minOrderQtyMessage } = useGetMtqOrMoqMessage({
    quantity: values.quantity,
    transferMinQty: productData?.transferMinQty,
    minimumOrderQty: firstPrimarySource?.minimumOrderQty,
    brDefaultFlag: firstPrimarySource?.brDefaultFlag,
    dcDefaultFlag: firstPrimarySource?.dcDefaultFlag,
    orderIncrement: firstPrimarySource?.orderIncrement,
  });

  const { onUpdateHeader, status: updateHeaderStatus } = useUpdateHeader({
    miLoc: cartMiLoc,
    orderCtlNo: cartOcn,
    shipToCustNo: '',
    billToCustNo: '',
  });

  useEffect(() => {
    if (
      and(
        !!currentCartCustomer?.orderCtlNo,
        !isCustomerLess(cartCustomerNo),
        updateHeaderStatus === 'success'
      )
    ) {
      history.push(
        goToProductDetail({
          miLoc: cartMiLoc,
          customerNo: cartCustomerNo,
          itemNo: productData?.itemNumber,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateHeaderStatus, currentCartCustomer?.orderCtlNo]);

  const { doOcnCustomerUpdate } = useHandleCustomerUpdate();

  const setCustomerInfo = async (o: SelectModalItem) => {
    if (o.key) {
      const selectedMiLoc = o.key.substring(0, 4);
      const selectedCustNo = o.key.substring(4);
      await doOcnCustomerUpdate({
        orderCtlNo: cartOcn,
        cartMiLoc,
        miLoc: selectedMiLoc,
        shipToCustNo: selectedCustNo,
        customerName: o?.title,
        href: goToProductDetail({
          miLoc: selectedMiLoc,
          customerNo: selectedCustNo,
          itemNo: productData?.itemNumber,
        }),
        onUpdateHeader,
        onAddToCart,
      });
    }
  };

  return (
    <IonPage
      className={classes.productDetail}
      data-testid="ProductDetailNew-page"
    >
      <Header
        testid="product-details-header"
        hideMenuButton
        customTitle={choose(
          !isHeadlessCustomer,
          <CustomerName customerData={customerData} />
        )}
        title={choose(isHeadlessCustomer, productData?.mfgPartNumber)}
        headerActions={{
          title: t('manage'),
          options: [
            ...(!isHeadlessCustomer
              ? [
                  {
                    text: t('emailProduct'),
                    onClick: () => setSendEmailModal(true),
                    disabled: isDetailsLoading,
                    testid: 'email-product',
                  },
                ]
              : []),
            ...(!isZcodedItem(productId) && !isHeadlessCustomer
              ? [
                  {
                    text: t('viewPriceHistory'),
                    href: goToProductHistory({
                      miLoc,
                      customerNo: id,
                      itemNo: productId,
                      depth,
                    }),
                    disabled: isDetailsLoading,
                    testid: 'view-price-history',
                  },
                ]
              : []),
            toggleCostDetailsOption(isDetailsLoading),
          ],
        }}
      />
      <IonContent>
        <div className={classes.container}>
          <div className={classes.productHeaderAndTabsWrapper}>
            <SelectCustomerBar
              testid="product-search-detail"
              isCustomerLessSearch={isHeadlessCustomer}
              setCustomerInfo={setCustomerInfo}
              triggerCustomerSelect={triggerCustomerSelect}
            />
            {productData && (
              <ProductHeader
                productData={productData}
                testid="product-detail-header"
              />
            )}
            <SegmentTabs
              className={classes.tabs}
              variant="miproWhite"
              options={options}
              value={selectedTab}
              setValue={(v: string) =>
                setTabValue((prev) => ({
                  ...prev,
                  [itemId]: v as ProductTabEnum,
                }))
              }
              textVariant="title-action-card"
              testid="segment-tabs"
            />
          </div>
          {!isDetailsLoading && (
            <>
              {selectedTab === ProductTabEnum.detail && (
                <ProductDetailTab
                  product={productData}
                  testid="product-search-detail-tab"
                  isLoading={isDetailsLoading}
                  expanded={detailExpanded[itemId] || ProductDetailEnum.details}
                  onAccordionClick={(v) =>
                    setDetailExpanded((prev) => ({
                      ...prev,
                      [itemId]: v as ProductDetailEnum,
                    }))
                  }
                />
              )}
              {selectedTab === ProductTabEnum.sss && (
                <ProductSSSTab
                  isSSSTab
                  miLoc={miLoc}
                  id={id}
                  productId={productId}
                  groupSerial={groupSerial}
                  testid="product-search-sss-tab"
                />
              )}
              {selectedTab === ProductTabEnum.interchange && (
                <ProductSSSTab
                  miLoc={miLoc}
                  id={id}
                  productId={productId}
                  groupSerial={groupSerial}
                  testid="product-search-ic-tab"
                />
              )}
              {selectedTab === ProductTabEnum.stock && (
                <ProductStockTab
                  expanded={stockExpanded[itemId] || ProductStockEnum.primary}
                  onAccordionClick={(v) =>
                    setStockExpanded((prev) => ({
                      ...prev,
                      [itemId]: v as ProductStockEnum,
                    }))
                  }
                  groupSerial={groupSerial}
                  id={id}
                  productId={productId}
                  miLoc={miLoc}
                  mfrCtlNo={mfrCtlNo}
                  onEmptyBtnClick={() =>
                    setTabValue((prev) => ({
                      ...prev,
                      [itemId]: ProductTabEnum.interchange,
                    }))
                  }
                  testid="product-search-stock-tab"
                  primarySourcesResponse={primarySourcesResponse}
                />
              )}
            </>
          )}
          <Loader
            className={classes.loader}
            text={t('loadingProduct')}
            isOpen={isDetailsLoading}
          />
          {detailsError && (
            <WarningMessage
              className={classes.warningMessage}
              title={t('loadingError')}
              body={getErrorMessage(detailsError)}
            />
          )}
        </div>
        <SendEmailModal
          searchType="customer"
          id={id}
          miLoc={miLoc}
          body={getMiComProductURL()}
          defaultRecipients={isNil(mainContact) ? [] : [mainContact]}
          isOpen={sendEmailModal}
          setIsOpen={setSendEmailModal}
          title={t('sendEmail')}
          testid="send-email-modal"
        />
        <DiscardModal
          title={toString(
            choose(
              isHeadlessCustomer,
              t('differentOcnHeadlessTitle'),
              toString(
                choose(
                  isCartHeadless,
                  t('differentOcnCustomerlessTitle', {
                    customerName: customerData?.name,
                  }),
                  t('differentOcnCustomerTitle', {
                    customerName: customerData?.name,
                  })
                )
              )
            )
          )}
          isOpen={diffCartCustomerModalIsOpen}
          setIsOpen={setDiffCartCustomerModalIsOpen}
          withRightCloseButton
          onGoBackClick={() => {
            dispatch(clearCurrentCartCustomer());
            setCreateNewOCN(true);
          }}
          goBackButtonTitle={t('differentOcnPrimaryButton')}
          discardButtonTitle={t('common:cancel')}
          discardMsg={
            choose(
              isHeadlessCustomer,
              t('differentOcnHeadlessText', {
                ocn: removeLeadingZeros(currentCartCustomer?.orderCtlNo),
                customerName: currentCartCustomer?.customerName,
              }),
              choose(
                isCartHeadless,
                t('differentOcnCustomerlessText', {
                  ocn: removeLeadingZeros(currentCartCustomer?.orderCtlNo),
                  newCustomerName: customerData?.name,
                }),
                t('differentOcnCustomerText', {
                  ocn: removeLeadingZeros(currentCartCustomer?.orderCtlNo),
                  customerName: currentCartCustomer?.customerName,
                  newCustomerName: customerData?.name,
                })
              ) as string
            ) as string
          }
          testid="different-cart-customer-modal-secondary-button"
        />
        <DiscardModal
          title={t('itemIsAlreadyInOcnTitle')}
          initialBreakpoint={0.35}
          withRightCloseButton
          setIsOpen={setItemInCartModalIsOpen}
          discardMsg={t('itemIsAlreadyInOcnText', {
            customerName: currentCartCustomer?.customerName,
          })}
          goBackButtonTitle={t('addToOcn')}
          discardButtonTitle={t('cancel')}
          onGoBackClick={addItemToCart}
          isOpen={itemInCartModalIsOpen}
          testid="item-is-already-in-cart-modal"
        />
        <DiscardModal
          title={t('createNewOCN')}
          discardMsg={t('selectBranchMessage')}
          initialBreakpoint={0.35}
          withRightCloseButton
          setIsOpen={setSelectBranchModalOpen}
          isOpen={selectBranchModalOpen}
          testid="select-branch-modal"
          discardButtonTitle={t('cancel')}
          goBackButtonTitle={t('selectBranch')}
          onGoBackClick={() => {
            setIsAddingItem(false);
            history.push(
              choose(
                isHeadlessCustomer,
                `${locatorURL}?noRedirect=true`,
                locatorURL
              ) as string
            );
          }}
        />
        <DiscardModal
          discardMsg={t('ProductSearchApp-Search:selectCustomerMsg')}
          title={t('ProductSearchApp-Search:selectCustomer')}
          isOpen={selectCustomerModalOpen}
          setIsOpen={setSelectCustomerModalOpen}
          testid="select-customer-modal"
          discardButtonTitle={t('cancel')}
          goBackButtonTitle={t('ProductSearchApp-Search:selectCustomer')}
          onGoBackClick={() => {
            setSelectCustomerModalOpen(false);
            setTriggerCustomerSelect(Date.now());
          }}
          withRightCloseButton
        />
      </IonContent>
      {addToCartAccessControls && (
        <IonFooter className={classes.footer}>
          <IonToolbar>
            <FormikProvider value={formik}>
              {!isEmpty(minOrderQtyMessage) &&
                !isDetailsLoading &&
                !primarySourcesResponse.isLoading && (
                  <Alert
                    text={{
                      text: minOrderQtyMessage,
                      variant: 'content-small',
                    }}
                    variant={AlertVariantEnum.warning}
                    testid="min-order-qty-alert"
                  />
                )}
              <IonRow>
                <IonCol className={classes.inputWrapper}>
                  <Button
                    variant="mipro-text-button"
                    icon={findIcon('minus')}
                    onClick={() => {
                      if (values.quantity > 1) {
                        void setFieldValue(
                          'quantity',
                          toNumber(values.quantity) - 1
                        );
                      }
                    }}
                    disabled={disableAddItem}
                    testid="decrease-qty-button"
                  />
                  <FormikInput
                    name="quantity"
                    disabled={disableAddItem}
                    testid="add-to-cart-input-quantity"
                    numberMask={qtyScaleProps}
                    focusScroll={false}
                    onBlur={async () => {
                      if (!toNumber(values.quantity)) {
                        await setFieldValue('quantity', 1);
                      }
                    }}
                  />
                  <Button
                    variant="mipro-text-button"
                    icon={findIcon('plus')}
                    onClick={() =>
                      setFieldValue('quantity', toNumber(values.quantity) + 1)
                    }
                    disabled={disableAddItem}
                    testid="increase-qty-button"
                  />
                </IonCol>
                <IonCol>
                  <Button
                    className={classes.addToOcn}
                    variant="mipro-action"
                    text={t('addToOcn')}
                    onClick={() => verifyAddItemToCart()}
                    disabled={disableAddItem}
                    testid="add-to-cart-button"
                  />
                </IonCol>
              </IonRow>
            </FormikProvider>
          </IonToolbar>
        </IonFooter>
      )}
    </IonPage>
  );
};

export default ProductSearchDetail;
