import { Capacitor } from '@capacitor/core';
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';
import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import useFeatureFlags, { FeatureFlagType } from 'hooks/useFeatureFlags';
import type { UpdateActivityBody } from 'models/ActivityModels';
import type {
  ButtonVariantType,
  ButtonVariantEnum,
} from 'components/Button/Button';
import type { RadioOptionsProps } from 'components/RadioButton/RadioButton';

export enum FirebaseEventNameEnum {
  'screen_view' = 'screen_view',
  'price_override_action' = 'price_override_action',
  'sourcing_override_action' = 'sourcing_override_action',
  'sales_plays_action' = 'sales_plays_action',
  'button_click' = 'button_click',
  'toggle_click' = 'toggle_click',
  'action_row_click' = 'action_row_click',
  'checkbox_change' = 'checkbox_change',
  'radio_button_change' = 'radio_button_change',
  'navigation_row' = 'navigation_row',
  'ble_scanner_connect' = 'ble_scanner_connect',
}

export interface UserEventAction {
  // TODO: use enum
  event:
    | 'SalesPlay'
    | 'Lead'
    | 'PriceOverride'
    | 'SourcingOverride'
    | 'Scanner';
  action: string;
}

export interface FirebaseUpdatePriceOverrideParams extends UserEventAction {
  userId: string;
  miLoc: string;
}

export interface FirebaseScannerConnectionParams extends UserEventAction {
  userId: string;
  miLoc: string;
  type: string;
}

export type FirebasUpdateActivityParams = UpdateActivityBody;

export interface FirebaseEventParams {
  routeName: string;
  path: string;
  url?: string;
  screen_name: string;
  screen_class: string;
}

export interface FirebaseButtonClickParams {
  href?: string;
  routerDirection?: 'forward' | 'none' | 'back' | 'root' | undefined;
  variant?: ButtonVariantEnum | ButtonVariantType | ButtonVariantType[];
  type: string;
  disabled?: boolean;
  icon?: IconProp;
  leftIcon?: IconProp;
  rightIcon?: IconProp;
  onClick?: (e: React.MouseEvent<HTMLIonButtonElement, MouseEvent>) => void;
  tabIndex?: number;
  isExternalLink?: boolean;
  testid: string;
}

export interface FirebaseCheckBoxChangeParams {
  checked?: boolean;
  name?: string;
  testid: string;
  label?: string;
}

export interface FirebaseRadioButtonChangeParams {
  testid: string;
  options: RadioOptionsProps[];
  disabled?: boolean;
}

const isWeb = Capacitor.getPlatform() === 'web';

const LogFirebaseEvent = (
  // TODO: use enum
  name: keyof typeof FirebaseEventNameEnum,
  params:
    | FirebaseEventParams
    | FirebaseUpdatePriceOverrideParams
    | FirebaseButtonClickParams
    | FirebasUpdateActivityParams
    | UserEventAction
    | FirebaseCheckBoxChangeParams
    | FirebaseRadioButtonChangeParams
): void => {
  const isGALogging = useFeatureFlags(FeatureFlagType.gaLogging);
  if (isGALogging && process.env.APP_ENV !== 'localtest') {
    // eslint-disable-next-line no-console
    console.log(name, params, 'GA');
  }

  if (!isWeb) {
    void FirebaseAnalytics.logEvent({
      name,
      params,
    });
  }
};

export { LogFirebaseEvent as logFirebaseEvent };
