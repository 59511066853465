import { kebabCase, toString } from 'lodash';
import { choose } from 'common/utils/logicHelpers';
import type { CountGroupListTypeEnum } from 'InventoryApp/models/InventoryPlanGroup';
import type { EditOrderLineTabEnum } from 'ProductSearchApp/pages/EditOrderLine/EditOrderLine';
import type { ReportItemType } from 'ReportsApp/models';
import type { SearchItemType } from 'models/Search';

export const loginURL = (): string => '/login';

export const miproURL = (): string => '/mipro';

export const miproServicesURL = (): string => '/services';

export const tabsURL = (): string => '/tabs';

export const NotAvailableURL = (): string => '/404';

export const RateLimitErrorURL = (): string => '/429';

export const TeaPotErrorURL = (): string => '/418';

// #region home

export const homeURL = (): string => '/tabs/home';
export const homeURLDefinition = (): string => '/tabs/:tab(home)';

export const territoriesURL = (id = ':id?'): string =>
  `/territories${id ? `/${id}` : ''}`;

export const feedbackURL = (): string => '/feedback';

export const devToolsURL = (): string => '/devtools';

export const bulletinsURL = (): string => '/bulletins';

export const notificationSettingsURL = (): string => '/notification-settings';

export const settingsURL = (): string => '/settings';

export const bulletinDetailURL = (id = ':id'): string => `/${id}`;

export const announcementsURL = (): string => '/announcements';

export const announcementDetailURL = (url = ':url'): string => `/${url}`;

export const manageFavoritesURL = (): string => '/manage-favorites';

export const currencyURL = (): string => '/currency';
// #endregion

// #region activities
export const activitiesURL = (): string => '/tabs/activities';

export const activitiesURLDefinition = (): string => '/tabs/:tab(activities)';

export const activityDetailURL = (
  miLoc = ':miLoc?',
  id = ':id?',
  userId = ':userId?',
  historyId = ':historyId',
  activityType = ':activityType'
): string =>
  `/${miLoc}/${id}/${userId}/${historyId}${
    activityType ? `/${activityType}` : ''
  }`;

export const shareActivityByEmailURL = (reset = false): string =>
  `/share${reset ? '?reset=true' : ''}`;

export const activityAddEditNoteURL = (
  miLoc = ':miLoc',
  id = ':id?',
  historyId = ':historyId',
  userId = ':userId',
  eventNoteId = ':eventNoteId'
): string =>
  `${activityDetailURL(miLoc, id, userId, historyId, '')}/notes/${eventNoteId}`;

// #endregion

// #region work orders
export const workOrdersURL = (): string => '/tabs/work-orders';
export const workOrdersURLDefinition = (): string => '/tabs/:tab(work-orders)';

export const workOrderDetailsURL = (
  miLoc = ':miLoc',
  woCtlNo = ':woCtlNo'
): string => `/details/${miLoc}/${woCtlNo}`;

// #region documents
export const documentsURL = (): string => '/tabs/documents';
export const documentsURLDefinition = (): string => '/tabs/:tab(documents)';

export const documentDetailsURL = (reportId = ':reportId'): string =>
  `/${reportId}/details`;

export const createDocumentURL = (): string => '/create';
// #endregion

// #region replenishment
export const replenishmentViewURL = (): string => '/tabs/item-replenishment';
export const replenishmentViewURLDefinition = (): string =>
  '/tabs/:tab(item-replenishment)';

export const replenishmentStoreroomSearchURL = (
  storeroom = ':storeroom'
): string => `/storeroom/${storeroom}`;
// #endregion

// #region issue processing
export const issueViewURL = (): string => '/tabs/issue-processing';
export const issueViewURLDefinition = (): string =>
  '/tabs/:tab(issue-processing)';

export const issueStoreroomListURL = (): string => `/storeroom`;

export const newIssueURL = (): string => `/issue/new`;

export const reviewIssuesURL = (): string => `/review`;
// #endregion

// #region search
export const searchURL = (): string => '/tabs/search';
export const searchURLDefinition = (): string => '/tabs/:tab(search)';

export const searchDetailURL = (type: SearchItemType, id = ':id'): string =>
  type === 'customer' ? `/details` : `/${kebabCase(type)}/${id}/details`;

export const searchContactDetailURL = (
  type: 'customer' | 'supplier',
  miLoc = ':miLoc?',
  id = ':id?',
  seqNo = ':seqNo'
): string => {
  return `/${type}${miLoc ? `/${miLoc}` : ''}${id ? `/${id}` : ''}/contacts${
    seqNo ? `/${seqNo}` : ''
  }`;
};

export const searchLocationDetailURL = (id = ':id?'): string => {
  return `/motion-location/${id ? `/${id}` : ''}`;
};
// #endregion

// #region suppliers
export const supplierActionRouteURL = (action: string): string => `/${action}`;

export const supplierInventoryAnalysisURL = (): string => `/inventory-analysis`;
// #endregion

// #region customers
export const searchCustomerURL = (miLoc = ':miLoc', id = ':id'): string =>
  `/customer/${miLoc}/${id}`;

export const searchCustomerOCN = (): string => `/order/search`;

export const searchSupplierURL = (miLoc = ':miLoc', id = ':id'): string =>
  `/supplier/${miLoc}/${id}`;

export const customerActionRouteURL = (action: string): string => `/${action}`;

export const customerExceptionsURL = (): string => `/exceptions`;

export const customerAddEditNoteURL = (
  noteType = ':noteType',
  noteId = ':noteId'
): string => `/${noteType}/${noteId}`;

export const customerWebActivityURL = (): string => `/web-activity`;

export const customerCostSavingsURL = (): string => `/cost-savings`;

export const customerProductDetailURL = (productId = ':productId'): string =>
  `/${productId}`;

export const logAVisitURL = () => '/log-a-visit';

export const customerNotesURL = () => '/notes-and-attachments';

export const shareCustomerNoteByEmailURL = (reset = false): string =>
  `/share${reset ? '?reset=true' : ''}`;
// #endregion

// #region sales plays

export const salesPlayDetailURL = (
  externalSource = ':externalSource',
  externalId = ':externalId'
): string => `/sales-play/${externalSource}/${externalId}/detail`;

export const salesPlayProductURL = (
  miLoc = ':miLoc',
  id = ':id',
  productId = ':productId'
): string => `/product/${miLoc}/${id}/${productId}`;
// #endregion

// #region sales plays
export const salesPlaysURL = (): string => '/tabs/sales-plays';

export const salesPlaysURLDefinition = (): string => '/tabs/:tab(sales-plays)';
// #endregion

// #region reports
export const reportsURL = (): string => '/tabs/reports';
export const reportsURLDefinition = (): string => '/tabs/:tab(reports)';

// TODO: improve like handleSearch
export const reportsDrillDownURL = (
  type: ReportItemType | '',
  orgType = ':orgType?',
  miLoc = ':miLoc?',
  id = ':id?',
  pgc1 = ':pgc1?'
): string => {
  return `${type ? `/${kebabCase(type)}` : ''}${orgType ? `/${orgType}` : ''}${
    miLoc ? `/${miLoc}` : ''
  }${id ? `/${id}` : ''}${pgc1 ? `/${pgc1}` : ''}`;
};
// #endregion

// #region cost savings
export const costSavingsURL = (
  miLoc = ':miLoc?',
  id = ':id?',
  reset = false
): string =>
  `/cost-savings${miLoc ? `/${miLoc}` : ''}${id ? `/${id}` : ''}${
    reset ? '?reset=true' : ''
  }`;

export const costSavingsDetailsURL = (
  mode: 'entry' | 'view' | 'edit',
  vasCtlNo = ':vasCtlNo',
  reset = false
): string => `/${vasCtlNo}/${mode}${reset ? '?reset=true' : ''}`;

// #endregion

// #region inventory
export const inventoryURL = (): string => '/inventory';
export const homeInventoryURL = (): string => `/home`;
export const findStartCustomerURL = (): string => `/find-customers`;

export const shippingCustomersURL = (
  customerNo = ':customerNo?',
  miLoc = ':miLoc?'
): string => `/shipping-customers/${miLoc}/${customerNo}`;

export const countGroupListURL = (type: CountGroupListTypeEnum | ''): string =>
  `/count-plans/${kebabCase(type)}`;

export const countPlanURL = (
  countPlanId = ':countPlanId?',
  groupId = ':groupId?',
  miLoc = ':miLoc?',
  groupUniqueId = ':groupUniqueId?',
  customerNo = ':customerNo?'
): string =>
  `/count-plan/${countPlanId}/${groupId}/${miLoc}/${groupUniqueId}${
    customerNo ? `/${customerNo}` : ''
  }`;
// #endregion

// #region product search
export const productSearchURL = (fromCart = false): string =>
  `/product-search${fromCart ? '?fromCart=true' : ''}`;

export const productSearchDetailURL = (
  productId = ':productId',
  depth = ':depth',
  fromCart = false
): string => `/${productId}/${depth}${fromCart ? '?fromCart=true' : ''}`;

export const viewPriceHistoryURL = (): string => '/price-history';

export const orderListURL = (): string => `/orders`;

export const orderCartURL = (ocn = ':ocn'): string => `/ocn-cart/${ocn}`;

export const emptyCartURL = (): string => `/empty-cart`;

export const orderCartReviewURL = (
  ocnType = ':ocnType',
  ocnMode = ':ocnMode',
  reset = false
): string => `/${ocnType}/${ocnMode}${reset ? '?reset=true' : ''}`;

export const orderLineURL = (
  lineNo = ':lineNo',
  productId = ':productId',
  tab?: EditOrderLineTabEnum
): string => `/line/${lineNo}/${productId}${tab ? `?tab=${tab}` : ''}`;

export const shippingLineURL = (
  lineNo = ':lineNo',
  productId = ':productId'
): string => `/shipping/${lineNo}/${productId}`;

export const orderNotebookURL = (
  mode = ':mode',
  micro = ':micro?',
  date = ':date?',
  time = ':time?'
): string =>
  `/notes/${mode}${micro ? `/${micro}` : ''}${date ? `/${date}` : ''}${
    time ? `/${time}` : ''
  }`;

export const transmissionLogURL = () => `/transmission-log`;

export const addZCodedItemURL = (ocn = ':ocn?', reset = false): string =>
  `/add-zcoded-item${ocn ? `/${ocn}` : ''}${reset ? '?reset=true' : ''}`;

export const governmentShareOcnURL = (koyo = false) =>
  `/government-share${toString(choose(koyo, '?koyo=true'))}`;
// #endregion
