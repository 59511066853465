import type { SortingRule } from 'react-table';
import { debounce, head, isEmpty, toString } from 'lodash';
import type { Dictionary } from 'lodash';
import { useReportsConfig } from 'providers/ReportsProvider';
import { SortDirEnum } from 'models/Sort';

const useGetSortBy = (reportKey: string) => {
  const { updateSortField, updateSortDir, sortDir, sortField } =
    useReportsConfig({
      key: reportKey,
    });

  const onSortBy = debounce(
    (sortOption: SortingRule<Dictionary<unknown>>[]) => {
      if (!isEmpty(sortOption)) {
        updateSortField?.(toString(head(sortOption)?.id));
        updateSortDir?.(
          head(sortOption)?.desc
            ? SortDirEnum.DESCENDING
            : SortDirEnum.ASCENDING
        );
      }
    },
    300
  );

  return {
    onSortBy,
    sortDir,
    sortField,
    updateSortField,
  };
};

export default useGetSortBy;
