import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import { toString } from 'lodash';
import {
  SendEmailFormik,
  SendEmailPage,
} from 'common/components/SendEmail/SendEmail';
import type { SendEmailPageRef } from 'common/components/SendEmail/SendEmail';
import { choose } from 'common/utils/logicHelpers';
import { type ShareGovOcnURLParams } from 'ProductSearchApp/models/Order';
import useSendEmail from 'api/activities/useSendEmail';
import useGetCustomer from 'api/customer/useGetCustomer';
import useGoBack from 'hooks/useGoBack';
import { removeLeadingZeros } from 'utils/number';

const ShareGovernmentOcn = (): JSX.Element => {
  const { goBack } = useGoBack();
  const { t } = useTranslation('ProductSearchApp-Share');
  const pageRef = useRef<SendEmailPageRef>(null);
  const { miLoc, id, ocn } = useParams<ShareGovOcnURLParams>();
  const location = useLocation();
  const pathname = new Set(location.pathname.split('/'));
  const {
    data: customerData,
    isLoading: isCustomerLoading,
    error: hasCustomerError,
  } = useGetCustomer({ searchType: 'customer', miLoc, id });

  const { onSendEmail, status } = useSendEmail();

  useEffect(() => {
    if (status === 'success') {
      goBack();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  const isSubmitting = status === 'loading';
  const subject = t('shareOcnEmailSubject', {
    customerNo: customerData?.customerNo,
    customerName: customerData?.name,
  });

  const message = t('shareOcnEmailMessage', {
    customerNo: customerData?.customerNo,
    customerName: customerData?.name,
    miLoc,
    type: choose(
      pathname.has('order'),
      t('productSearch:ocn:order'),
      t('productSearch:ocn:quote')
    ),
    ocn: removeLeadingZeros(ocn),
  });

  return (
    <SendEmailFormik
      onSubmit={(values) => {
        onSendEmail({
          subject: values.subject,
          recipients: [values.recepients],
          toastText: t('activities:sendEmail:emailNotesSuccess', {
            recipients: values.recepients,
          }),
          body: toString(values.message),
          filesToUpload: values.files as File[],
        });
      }}
      values={{
        subject,
        message,
        recepients: 'govsales@motion.com',
        files: undefined,
      }}
    >
      <SendEmailPage
        ref={pageRef}
        allowRecipients={false}
        customerData={customerData}
        isLoading={isCustomerLoading}
        error={hasCustomerError}
        isSubmitting={isSubmitting}
        title={t('shareOcnTitle')}
      />
    </SendEmailFormik>
  );
};

export default ShareGovernmentOcn;
