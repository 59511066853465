import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  useParams,
  useRouteMatch,
  useHistory,
  useLocation,
} from 'react-router-dom';
import { isNil, map, size, concat } from 'lodash';
import { IonContent, IonPage } from '@ionic/react';
import CustomerName from 'common/components/Header/CustomerName';
import Header from 'common/components/Header/Header';
import { choose, or } from 'common/utils/logicHelpers';
import { namespaces } from 'i18n/i18n.constants';
import {
  customerAddEditNoteURL,
  customerNotesURL,
  logAVisitURL,
  supplierActionRouteURL,
} from 'navigation';
import { useToasts } from 'providers/ToastProvider';
import { goToSalesPerformanceReport } from 'ReportsApp/navigation/navigationHelpers';
import CustomerMenuOption from 'SearchApp/components/CustomerMenuOption/CustomerMenuOption';
import useGetCustomer from 'api/customer/useGetCustomer';
import useAddFavorites from 'api/favorites/useAddFavorites';
import useRemoveFavorites from 'api/favorites/useRemoveFavorites';
import useGetSupplier from 'api/supplier/useGetSupplier';
import {
  AccessControlType,
  useHasAccessControls,
} from 'hooks/useAccessControls';
import useRecentlyViewed from 'hooks/useRecentlyViewed';
import type { Customer } from 'models/Customer';
import type {
  SnapshotsURLParams,
  SearchItemType,
  SearchItem,
} from 'models/Search';
import type { Supplier } from 'models/Supplier';
import { getErrorMessage } from 'utils/helpers';
import { findIcon } from 'utils/icons';
import { concatRoutes } from 'utils/navigations';
import type { SnapshotRoutingState } from 'utils/search';
import { getSnapshotProp } from 'utils/search';
import {
  corporateAccountActionRoutes,
  customerActionRoutes,
  supplierActionRoutes,
} from 'navigation/TabNavigator/TabRouterConfig';
import ActionRow from 'components/ActionRow/ActionRow';
import Loader from 'components/Loader/Loader';
import WarningMessage from 'components/WarningMessage/WarningMessage';
import AccountsReceivableCard from './AccountsReceivableCard';
import CustomerDetails from './CustomerDetails';
import SalesProductGroupCard from './SalesProductGroupCard';
import SnapshotDetails from './SnapshotDetails';
import classes from './Snapshots.module.scss';

interface SnapshotsProps {
  searchType: SearchItemType;
}

const Snapshots = ({ searchType }: SnapshotsProps): JSX.Element => {
  const { url } = useRouteMatch();
  const history = useHistory();
  const location = useLocation<SnapshotRoutingState>();
  const { t } = useTranslation(namespaces.snapshot);
  // Customers have miLoc as part of their unique identifier
  // Suppliers don't have miLoc, but their endpoints need a miLoc
  // if the item has a miLoc (it's a customer) use it,
  // if not default to the redux store miLoc
  const { miLoc, id } = useParams<SnapshotsURLParams>();
  const getCustomerResponse = useGetCustomer({
    searchType,
    miLoc,
    id,
    invalidateQuery: true,
  });
  const getSupplierResponse = useGetSupplier({
    searchType,
    id,
    invalidateQuery: true,
  });

  const data =
    searchType === 'customer'
      ? getCustomerResponse.data
      : getSupplierResponse.data;
  const error =
    searchType === 'customer'
      ? getCustomerResponse.error
      : getSupplierResponse.error;
  const isLoading =
    searchType === 'customer'
      ? getCustomerResponse.isLoading
      : getSupplierResponse.isLoading;

  const name = getSnapshotProp(searchType, 'name', 'supLocName', data);

  const isFavorite =
    searchType === 'customer'
      ? (data as Customer)?.isBookmarked
      : (data as Supplier)?.bookmarked === 'Y';

  const customerPick12 =
    searchType === 'customer' && (data as Customer)?.customerPick12;

  const natlAcctNo = (data as Customer)?.natlAcctNo;

  const corpInfo = (data as Customer)?.corpAcctInfo;
  const { isCorp } = getCustomerResponse;
  const isCorpAccount = or(location?.state?.isCorpAccount, isCorp);
  const isCorpAccountorInfo = or(isCorpAccount, !isNil(corpInfo));
  const isCustomerSearchType = searchType === 'customer';

  const headerText = choose(
    isCustomerSearchType && !isCorpAccount,
    t('snapshot:customer'),
    choose(
      isCustomerSearchType && isCorpAccount,
      t('snapshot:corpAccount'),
      t('snapshot:supplier')
    )
  ) as string;

  const headerActionTitle = choose(
    isCustomerSearchType && !isCorpAccount,
    t('snapshot:manageSnapshot', { searchType }),
    choose(
      isCustomerSearchType && isCorpAccount,
      t('snapshot:manageSnapshot', {
        searchType: t('snapshot:corpAccount'),
      }),
      t('snapshot:manageSnapshot', { searchType })
    )
  ) as string;

  const { onAddFavorites } = useAddFavorites({});
  const { onRemoveFavorites } = useRemoveFavorites({});

  const onToggleFavorite = () => {
    const items = [{ miLoc, id, type: searchType } as SearchItem];
    if (isFavorite) {
      onRemoveFavorites({ items });
    } else {
      onAddFavorites({ items });
    }
  };

  const { hasAccessControl } = useHasAccessControls();

  const viewReports = hasAccessControl(AccessControlType.viewReports);

  const salesProductGroupHandler = () =>
    history.push(
      goToSalesPerformanceReport({
        baseUrl: `${url}/reports`,
        orgType: 'CUST',
        miLoc,
        rowId: id,
      })
    );

  const onPGC1Click = (pgc1Id: string) =>
    goToSalesPerformanceReport({
      baseUrl: `${url}/reports`,
      orgType: 'PRD_GRP_01',
      miLoc,
      rowId: id,
      pgc1: pgc1Id,
    });

  useRecentlyViewed({
    type: searchType,
    miLoc,
    id,
    text: name,
    customerPick12,
    natlAcctNo,
    isCorpAccountorInfo,
  });

  const { addToast } = useToasts();

  const headerActionOption = () => {
    const options = [
      {
        text: isFavorite
          ? t('snapshot:removeFavorite', { name })
          : `${t('common:favorite')} ${name}`,
        icon: findIcon('star', isFavorite ? 'fas' : 'far'),
        onClick: () => {
          onToggleFavorite();
          addToast({
            text: isFavorite
              ? t('snapshot:removedFromFavorite', { name })
              : t('snapshot:addToFavorite', { name }),
            testid: 'snapshot-favorite-toast',
          });
        },
        testid: 'favorite',
      },
    ];

    return searchType === 'supplier'
      ? options
      : concat(options, [
          {
            text: t('logVisit'),
            icon: findIcon('map-marker-check'),
            onClick: () => {
              history.push(concatRoutes(url, logAVisitURL()));
            },
            testid: 'log-a-visit',
          },
          {
            text: t('notes:addNote'),
            icon: findIcon('sticky-note'),
            onClick: () => {
              history.push(
                concatRoutes(
                  url,
                  customerNotesURL(),
                  customerAddEditNoteURL('CM', 'add')
                )
              );
            },
            testid: 'add-notebook',
          },
        ]);
  };

  return (
    <IonPage className={classes.container} data-testid="search-detail-page">
      <Header
        testid="search-detail"
        customTitle={
          <CustomerName
            customerData={data}
            type={searchType}
            isCorpAccount={isCorpAccount}
            name={location?.state?.headerTitle}
          />
        }
      />
      <IonContent className={classes.content}>
        <Header
          testid="condensed-header"
          pageTitle={`${headerText} ${t('snapshot:snapshot')}`}
          collapse="condense"
          customTitle={
            <CustomerName
              customerData={data}
              showFavorite
              type={searchType}
              showAccountOnHold
              isCorpAccount={isCorpAccount}
              name={location?.state?.headerTitle}
            />
          }
          headerActions={{
            title: headerActionTitle,
            initialBreakpoint: searchType === 'customer' ? 0.4 : 0.3,
            options: headerActionOption(),
          }}
          className={classes.header}
        />
        <Loader
          className={classes.loader}
          text={t('loadingSnapshot', { type: t(`common:${searchType}`) })}
          isOpen={isLoading}
          testid="loadingIndicator"
        />
        {!isLoading && !error && (
          <>
            {searchType === 'customer' && (
              <>
                <CustomerDetails
                  miLoc={miLoc}
                  id={id}
                  isCorpAccount={isCorpAccount}
                />
                <AccountsReceivableCard miLoc={miLoc} id={id} />
              </>
            )}

            <SnapshotDetails
              searchType={searchType}
              miLoc={miLoc}
              id={id}
              data={data}
              isCorpAccount={isCorpAccount}
            />
            {searchType === 'customer' && viewReports && !isCorpAccount && (
              <SalesProductGroupCard
                miLoc={miLoc}
                id={id}
                onProductGroupClick={salesProductGroupHandler}
                onPGC1Click={onPGC1Click}
                t={t}
              />
            )}
            <div className={classes.actionRowWrapper}>
              {searchType === 'supplier' &&
                map(
                  supplierActionRoutes,
                  ({ action, component, routes, disabled, translate }) => (
                    <ActionRow
                      key={action}
                      className={classes.actionRow}
                      text={t(translate)}
                      href={concatRoutes(url, supplierActionRouteURL(action))}
                      testid={`${action}-details`}
                      disabled={
                        !isNil(disabled)
                          ? disabled
                          : !component && size(routes) === 0
                      }
                    />
                  )
                )}

              {searchType === 'customer' &&
                !isCorpAccount &&
                map(customerActionRoutes, (menuOption) => {
                  if (menuOption.hideInSnapshot) {
                    return null;
                  }
                  return (
                    <CustomerMenuOption
                      key={menuOption.action}
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...menuOption}
                    />
                  );
                })}

              {searchType === 'customer' &&
                isCorpAccount &&
                map(corporateAccountActionRoutes, (menuOption) => {
                  return (
                    <CustomerMenuOption
                      key={menuOption.action}
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...menuOption}
                      isCorpAccount
                      natlAcctNo={natlAcctNo}
                    />
                  );
                })}
            </div>
          </>
        )}
        {error && (
          <WarningMessage
            className={classes.warningMessage}
            title="Error loading snapshot"
            body={getErrorMessage(error)}
          />
        )}
      </IonContent>
    </IonPage>
  );
};

export default Snapshots;
