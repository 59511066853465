import React from 'react';
import { useTranslation } from 'react-i18next';
import { toString } from 'lodash';
import { IonRow, IonToolbar } from '@ionic/react';
import { choose, or } from 'common/utils/logicHelpers';
import { namespaces } from 'i18n/i18n.constants';
import {
  NetworkStatusEnum,
  useNetworkStatus,
} from 'providers/NetworkStatusProvider';
import Text from 'components/Text/Text';
import classes from './OfflineInfo.module.scss';

interface OfflineInfoProps {
  message?: string;
}

const OfflineInfo = ({ message }: OfflineInfoProps): JSX.Element => {
  const { networkStatus } = useNetworkStatus();
  const { t } = useTranslation(namespaces.common);

  return (
    <IonToolbar className={classes.offlineBanner}>
      <IonRow className={classes.wrapper}>
        <Text
          variant="label-micro"
          text={or(
            message,
            `${
              // eslint-disable-next-line no-nested-ternary
              toString(
                choose(
                  networkStatus === NetworkStatusEnum.ServerUnreachable,
                  'Unable to reach Mi Pro server',
                  toString(
                    choose(
                      networkStatus === NetworkStatusEnum.RateLimitError,
                      t('rateLimitExceeded'),
                      choose(
                        networkStatus === NetworkStatusEnum.TeaPotError,
                        t('teaPotError'),
                        'You are in offline mode'
                      )
                    )
                  )
                )
              )
            }, all the information displayed is from the local database`
          )}
          testid="offline-mode-text"
        />
      </IonRow>
    </IonToolbar>
  );
};

export default OfflineInfo;
