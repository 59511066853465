import type React from 'react';
import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import type { ViewAsRoleType } from './Reports';

export enum SortContactFieldEnum {
  name = 'name',
}

export enum SortFieldEnum {
  avgDaily = 'avgDaily',
  avgDailyChange = 'avgDailyChange',
  gpAmount = 'gpAmount',
  gpChange = 'gpChange',
  gpPercentAmount = 'gpPercentAmount',
  gpPercentChange = 'gpPercentChange',
  miLoc = 'miLoc',
  name = 'name',
  sales = 'sales',
  salesChange = 'salesChange',
  transactions = 'transactions',
  transactionsChange = 'transactionsChange',
  unbilled = 'unbilled',
  overage = 'overage',
  customerName = 'customerName',
  corporateAcctName = 'corporateAcctName',
  totalPossibleAmt = 'totalPossibleAmt',
  currentSales = 'currentSales',
  camEmployeeName = 'camEmployeeName',
  camName = 'camName',
}

export enum SortDirEnum {
  ASCENDING = 'ASCENDING',
  DESCENDING = 'DESCENDING',
}

export interface SortOption {
  key: string | ViewAsRoleType;
  name: string;
  field?: SortFieldEnum | SortContactFieldEnum;
  dir?: SortDirEnum;
  icon?: IconProp;
  nameSuffix?: string;
  customContent?: React.ReactNode;
  onClick?: () => void;
  disabled?: boolean;
  track?: boolean;
  showCalendar?: boolean;
  hidden?: boolean;
}
