import { isEmpty, toString } from 'lodash';
import {
  emptyCartURL,
  orderNotebookURL,
  orderCartReviewURL,
  orderCartURL,
  orderLineURL,
  productSearchDetailURL,
  productSearchURL,
  searchCustomerURL,
  searchURL,
  viewPriceHistoryURL,
  transmissionLogURL,
  addZCodedItemURL,
  customerAddEditNoteURL,
  shareCustomerNoteByEmailURL,
  customerNotesURL,
  searchCustomerOCN,
  shippingLineURL,
  governmentShareOcnURL,
} from 'navigation';
import { concatRoutes } from 'utils/navigations';

export const goToProductSearch = (
  miLoc?: string,
  customerNo?: string,
  fromCart = false
) =>
  concatRoutes(
    searchURL(),
    searchCustomerURL(miLoc, customerNo),
    productSearchURL(fromCart)
  );

export const goToOCNSearch = (miLoc?: string, customerNo?: string) =>
  concatRoutes(
    searchURL(),
    searchCustomerURL(miLoc, customerNo),
    searchCustomerOCN()
  );

export const goToProductDetail = ({
  miLoc = '',
  customerNo = '',
  itemNo = '',
  depth = '1',
  fromCart = false,
}) =>
  concatRoutes(
    searchURL(),
    searchCustomerURL(miLoc, customerNo),
    productSearchURL(),
    productSearchDetailURL(itemNo, depth, fromCart)
  );

export const goToProductHistory = ({
  miLoc = '',
  customerNo = '',
  itemNo = '',
  depth = '1',
}) =>
  concatRoutes(
    searchURL(),
    searchCustomerURL(miLoc, customerNo),
    productSearchURL(),
    productSearchDetailURL(itemNo, depth),
    viewPriceHistoryURL()
  );

export const goToOrderCart = (
  miLoc?: string,
  customerNo?: string,
  ocn?: string
) =>
  !isEmpty(ocn)
    ? concatRoutes(
        searchURL(),
        searchCustomerURL(miLoc, customerNo),
        orderCartURL(ocn)
      )
    : concatRoutes(searchURL(), emptyCartURL());

export const goToNotebook = ({
  miLoc = '',
  shipToCustNo = '',
  orderCtlNo = '',
  micro = '',
  date = '',
  time = '',
  lineNo = '',
  productId = '',
  ocnType = '',
  ocnMode = '',
  headerNotes = false,
  mode = 'add',
}) =>
  concatRoutes(
    searchURL(),
    searchCustomerURL(miLoc, shipToCustNo),
    orderCartURL(orderCtlNo),
    headerNotes
      ? orderCartReviewURL(ocnType, ocnMode)
      : orderLineURL(lineNo, productId),
    orderNotebookURL(mode, micro, encodeURIComponent(toString(date)), time)
  );

export const goToTransmissionLog = ({
  miLoc = '',
  shipToCustNo = '',
  orderCtlNo = '',
  ocnType = '',
  ocnMode = '',
}) =>
  concatRoutes(
    searchURL(),
    searchCustomerURL(miLoc, shipToCustNo),
    orderCartURL(orderCtlNo),
    orderCartReviewURL(ocnType, ocnMode),
    transmissionLogURL()
  );

export const goToReviewCart = ({
  miLoc = '',
  shipToCustNo = '',
  orderCtlNo = '',
  ocnType = '',
  ocnMode = '',
  reset = false,
}) =>
  concatRoutes(
    searchURL(),
    searchCustomerURL(miLoc, shipToCustNo),
    orderCartURL(orderCtlNo),
    orderCartReviewURL(ocnType, ocnMode, reset)
  );

export const goToLineShippingDetail = ({
  miLoc = '',
  shipToCustNo = '',
  orderCtlNo = '',
  lineNo = '',
  productId = '',
  ocnType = '',
  ocnMode = '',
  reset = false,
}) =>
  concatRoutes(
    searchURL(),
    searchCustomerURL(miLoc, shipToCustNo),
    orderCartURL(orderCtlNo),
    orderCartReviewURL(ocnType, ocnMode, reset),
    shippingLineURL(lineNo, productId)
  );

export const goToAddZCodedItem = ({
  miLoc = '',
  shipToCustNo = '',
  orderCtlNo = '',
  reset = false,
}) =>
  concatRoutes(
    searchURL(),
    searchCustomerURL(miLoc, shipToCustNo),
    addZCodedItemURL(orderCtlNo, reset)
  );

export const goToShareCustomerNote = ({
  miLoc = '',
  id = '',
  noteType = '',
  noteId = '',
  reset = false,
}) =>
  concatRoutes(
    searchURL(),
    searchCustomerURL(miLoc, id),
    customerNotesURL(),
    customerAddEditNoteURL(noteType, noteId),
    shareCustomerNoteByEmailURL(reset)
  );

export const goToShareGovernmentKoyoOcn = ({
  miLoc = '',
  shipToCustNo = '',
  orderCtlNo = '',
  ocnType = '',
  ocnMode = '',
  koyo = false,
}) =>
  concatRoutes(
    searchURL(),
    searchCustomerURL(miLoc, shipToCustNo),
    orderCartURL(orderCtlNo),
    orderCartReviewURL(ocnType, ocnMode),
    governmentShareOcnURL(koyo)
  );
