import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { includes } from 'lodash';
import { IonCol, IonContent, IonPage, IonRow } from '@ionic/react';
import { namespaces } from 'i18n/i18n.constants';
import { RateLimitErrorURL } from 'navigation';
import useGetAppLogo from 'hooks/useGetAppLogo';
import siren from 'assets/siren.gif';
import Button from 'components/Button/Button';
import PhoneNumber from 'components/Contacts/PhoneNumber/PhoneNumber';
import Text from 'components/Text/Text';
import WarningMessage from 'components/WarningMessage/WarningMessage';
import classes from './CloudFlareError.module.scss';

const CloudFlareError = (): JSX.Element => {
  const { isServicesLogo, logo } = useGetAppLogo();

  const { t } = useTranslation(namespaces.common);
  const { pathname } = useLocation();
  const isRateLimitPath = includes(pathname, RateLimitErrorURL());

  return (
    <IonPage className={classes.error} data-testid="Error-page">
      <IonContent>
        <Button
          variant="clear"
          className={classNames(classes.logo, {
            [classes.servicesLogo]: isServicesLogo,
          })}
          testid="mipro-logo-wrapper"
        >
          <img
            id="mipro-logo"
            data-testid="mipro-logo"
            src={logo}
            alt="Mi Pro"
          />
        </Button>

        <WarningMessage
          className={classes.warningMessage}
          hideIcon
          title=""
          body=""
          testid="error-msg"
        >
          <IonCol className={classes.column}>
            <IonRow className={classes.row}>
              <img
                id="siren-animated"
                data-testid="siren-animated"
                src={siren}
                alt="Siren"
              />
            </IonRow>
            <IonRow className={classes.row}>
              <Text
                className={classes.gapEnd}
                variant="title-info-card"
                text={t('lockedOutTitle')}
                testid="warning-message-title"
              />
            </IonRow>
            <IonRow className={classes.row}>
              <Text
                className={classNames(classes.errorMsg, classes.gap)}
                variant="mipro-body-copy"
                text={t('lockedOutMsg')}
                testid="error-message"
              />
            </IonRow>
            <IonRow className={classes.row}>
              <Text
                className={classes.gap}
                variant="list-item-secondaryText"
                text={t('lockedOutCode', {
                  code: isRateLimitPath ? 429 : 418,
                })}
                testid="error-code"
              />
            </IonRow>
            <IonRow className={classNames(classes.phoneRow, classes.row)}>
              <PhoneNumber
                phoneNumber={t('supportPhoneNumber')}
                testid="call-button"
                className={classes.phoneNumber}
              />
              <Text
                className={classes.supportId}
                variant="list-item-secondaryText"
                text={t('supportJobTitle')}
                testid="support-id"
              />
            </IonRow>
          </IonCol>
        </WarningMessage>
      </IonContent>
    </IonPage>
  );
};

export default CloudFlareError;
